import { LocationOn } from '@mui/icons-material';
import { Card } from '@mui/material';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NoDepotFound } from '../../components/errors/NoDepotFound';
import Wrapper from '../../components/wrapper/Wrapper';
import { buildRandomKey } from '../../core/Helpers/helper';
import { useAppSelector } from '../../hooks';
import { useLanguage } from '../../store/LanguageProvider';
import { usePeriod } from '../../store/PeriodProvider';
import { ProductType } from '../../types/product';
import {
  getAllProducts,
  getPath,
  GetProductsRequestProps
} from '../../webServices/productService';
import { selectCartProductsCount } from '../cart/cartSlice';
import { selectActiveDepot, selectDepotsWereLoaded } from '../depot/depotSlice';
import { InfoButton } from '../info-button/InfoButton';
import { SelectPeriod } from './period/SelectPeriod';
import ProductCard from './ProductCard';
import { selectOrganization } from '../organization/organizationSlice';
import { isDepotManager, selectActiveCustomer } from '../account/customerSlice';

const TravelScreen = () => {
  const { t } = useTranslation('translation');
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState<ProductType[]>([]);

  const cartProductsLength = useAppSelector(selectCartProductsCount);
  const activeDepot = useAppSelector(selectActiveDepot);
  const organization = useAppSelector(selectOrganization);
  const isDM = useAppSelector(isDepotManager);
  const activeCustomer = useAppSelector(selectActiveCustomer);

  const depotsWereLoaded = useAppSelector(selectDepotsWereLoaded);
  const activeDepotCode = activeDepot?.code;
  const { handlePeriod } = usePeriod();
  const { language } = useLanguage();

  const [productsRequest, setProductsRequest] =
    useState<GetProductsRequestProps>({
      language: language ?? null,
      store: activeDepotCode ?? null,
      startDate: null,
      endDate: null,
      selectedEmail: null
    });

  const productsRequestPath = getPath(productsRequest);
  useEffect(() => {
    if (
      productsRequest.store &&
      productsRequest.language &&
      productsRequest.startDate &&
      productsRequest.endDate
    ) {
      loadAllProducts(productsRequest);
    }
  }, [cartProductsLength, productsRequestPath]);

  useEffect(() => {
    if (language) {
      setProductsRequest((current) => ({
        ...current,
        language
      }));
    }
  }, [language]);

  useEffect(() => {
    if (activeDepotCode) {
      setProductsRequest((current) => ({
        ...current,
        store: activeDepotCode
      }));
    }
  }, [activeDepotCode]);

  const loadAllProducts = async (productsRequest: GetProductsRequestProps) => {
    setIsLoading(true);
    try {
      handlePeriod(productsRequest.startDate, productsRequest.endDate);
      const products = await getAllProducts(productsRequest);
      setProducts(products);
    } catch (e) {
      setProducts([]);
      // console.log('error getting products with:', productsRequest, e);
    } finally {
      setIsLoading(false);
    }
  };

  function displayContent() {
    if (isLoading) {
      return <Card style={{ padding: 20 }}>{t('translation:loading')}</Card>;
    }
    // if there is only a null product, there was a validation error
    if (products.length === 1 && products[0] == null) {
      return <Card style={{ padding: 20 }}>{t('translation:noProductsFoundError')}</Card>;
    }
    if (products.length === 0) {
      if (activeDepot.parent.fixedStartPeriod) {
        return (
          <Card style={{ padding: 20 }}>{t('translation:noProductsFoundFixed')}</Card>
        );
      }
      return (
        <Card style={{ padding: 20 }}>{t('translation:noProductsFound')}</Card>
      );
    }
    return (
      <Grid container spacing={4} sx={{}}>
        {products.map((product, index) => (
          <Grid item xs={12} sm={6} md={4} lg={4} key={buildRandomKey()}>
            <ProductCard product={product} />
          </Grid>
        ))}
      </Grid>
    );
  }

  if (!activeDepot) {
    if (depotsWereLoaded) return <NoDepotFound />;
    return <></>;
  }
  if (!
      (organization.anonymousBrowsing 
      || (activeCustomer && organization.supportReservations)
      || ( isDM && organization.supportAdminReservations))
     ) {
    return <></>;
  }

  return (
    <Wrapper>
      <Grid className={classes.root}>
        <main className={classes.content}>
          <Grid
            container
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Grid item>
              <h1>
                <LocationOn /> {activeDepot.name}
              </h1>
            </Grid>
            <Grid item>
              <InfoButton id={'MOBILITY_BOOKING_INFO'} />
            </Grid>
          </Grid>
          <Grid></Grid>
          <Card
            style={{
              padding: 20,
              overflow: 'initial',
              margin: '20px 0 30px 0'
            }}
          >
            <SelectPeriod
              onChange={setProductsRequest}
              productsRequest={productsRequest}
            />
          </Card>
          {displayContent()}
        </main>
      </Grid>
    </Wrapper>
  );
};

export default TravelScreen;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    position: 'relative',
    display: 'flex'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    minWidth: 0
  }
}));
