import { useEffect } from 'react';
import { fetchCustomerProfile } from '../feature/account/customerSlice';
import { fetchDepotsForOrganisation } from '../feature/depot/depotSlice';
import { selectOrganization } from '../feature/organization/organizationSlice';
import { useAppDispatch, useAppSelector } from '../hooks';

export function InitRedux({ children }) {
  const dispatch = useAppDispatch();
  const organization = useAppSelector(selectOrganization);

  useEffect(() => {
    dispatch(fetchCustomerProfile());
  }, [dispatch]);

  const organisationCode = organization?.code;

  useEffect(() => {
    if (!organisationCode) return;

    dispatch(
      fetchDepotsForOrganisation({ organisationCode: organisationCode })
    );
  }, [dispatch, organisationCode]);

  return children;
}
