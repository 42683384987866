import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

type StatCardProps = {
  type: 'fill' | null;
  title: string;
  value: number | string;
  icon: JSX.Element;
  color: string;
  textColor?: string;
  onClick?: () => void;
  isLoading?: boolean;
};

export const StatCard = (props: StatCardProps) => {
  const { type, title, value, icon, onClick } = props;

  const isLoading = props.isLoading ?? false;
  const color = isLoading ? props.color + 'DD' : props.color;
  const textColor = props.textColor ?? '#ffffff';

  const classes = useStyles();
  let before = null;
  let after = null;

  const cardIcon = (
    <Grid item className={classes.iconFloat}>
      <IconButton
        className={classes.icon}
        aria-label={title}
        style={
          type === 'fill' && color.startsWith('#fff')
            ? {
                backgroundColor: textColor
              }
            : type === 'fill'
            ? {}
            : {
                backgroundColor: color
              }
        }
        size="large"
      >
        {icon}
      </IconButton>
    </Grid>
  );

  if (icon) {
    type === 'fill' ? (after = cardIcon) : (before = cardIcon);
  }

  return (
    <Card
      style={{
        backgroundColor: type === 'fill' ? color : null,
        color: textColor,
        height: '100%',
        cursor: !!onClick ? 'pointer' : 'auto'
      }}
      onClick={onClick}
    >
      <LinearProgress
        sx={{
          backgroundColor: color,
          '& .MuiLinearProgress-bar': {
            backgroundColor: isLoading ? '#FFFFFF66' : color
          }
        }}
      />

      <CardContent className={classes.content}>
        <Grid
          container
          minHeight="80%"
          // alignItems={'center'}
          alignItems="center"
          direction={'row'}
          justifyContent="space-between"
          spacing={0}
          wrap={'nowrap'}
        >
          {before}
          <Grid item>
            <div className={type === 'fill' ? '' : 'px-1'}>
              <Typography
                className={`${classes.title}  ${type === 'fill' ? '' : ''} `}
              >
                {value}
                {/*{isLoading && (*/}
                {/*  <Loop className={classes.rotateIcon} fontSize={'small'} />*/}
                {/*)}*/}
              </Typography>
              <Typography variant="caption">{title}</Typography>
            </div>
          </Grid>
          {after}
        </Grid>
      </CardContent>
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  content: {
    position: 'relative',
    height: '100%',
    padding: theme.spacing(1),
    '&:last-child': {
      paddingBottom: theme.spacing(1)
    }
  },
  icon: {
    boxShadow: 'none',
    color: 'white',
    '& svg': {
      fontSize: 45,
      [theme.breakpoints.down('md')]: {
        fontSize: 25
      }
    }
  },
  iconFloat: {
    opacity: '0.7'
  },
  lightText: {
    color: 'white'
  },
  title: {
    overflowWrap: 'anywhere',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 800,
    fontSize: '1.25rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem'
    }
  },
  rotateIcon: {
    marginLeft: theme.spacing(1),
    animation: '$spin 1s linear infinite'
  },
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(360deg)'
    },
    '100%': {
      transform: 'rotate(0deg)'
    }
  }
}));
