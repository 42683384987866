import { ButtonTypeMap, Icon } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Buffer } from 'buffer';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { useShopizerApiClient } from '../../apis';
import { useDialog } from '../../components/dialog/useDialog';
import { useAppSelector } from '../../hooks';
import { useLanguage } from '../../store/LanguageProvider';
import { useCacheSelect } from '../cache/UseCacheSelect';
import { selectActiveDepotCode } from '../depot/depotSlice';
import { selectOrganization } from '../organization/organizationSlice';

interface InfoButtonProps {
  id: string;
  color?: 'inherit' | 'primary';
}

export const InfoButton = (props: InfoButtonProps) => {
  const { t } = useTranslation();
  const { open, handleClose, handleOpen } = useDialog();
  const { language } = useLanguage();
  const shopizerApi = useShopizerApiClient();
  const organization = useAppSelector(selectOrganization);
  const activeDepotCode = useAppSelector(selectActiveDepotCode);
  const color = props.color ?? 'primary';

  const code = activeDepotCode ?? organization.code;

  const fetchData = async () => {
    return await shopizerApi.storeApi.getInfoContentUsingGET({
      key: props.id,
      code
    });
  };

  const data = useCacheSelect(props.id + '-' + code, fetchData);

  if (!data) return <></>;

  const content = decodeBase64(data.content[language] ?? null);
  const contentParsed = !!content ? parse(content) : content;

  return (
    <>
      <Button
        color={color}
        variant={
          (data.buttonType as ButtonTypeMap['props']['variant']) ?? 'text'
        }
        onClick={handleOpen}
        sx={{
          paddingX: 1,
          minWidth: '1px'
        }}
      >
        {!!data.icon && (
          <Icon sx={{ marginRight: !!data.buttonText ? 1 / 2 : 0 }}>
            {data.icon}
          </Icon>
        )}

        {decodeBase64(data.buttonText[language] ?? null)}
      </Button>

      <Dialog
        maxWidth={'md'}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        sx={{
          '& .MuiPaper-root': {
            m: 0
          },
          '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.9)'
          }
        }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}
        >
          {decodeBase64(data.title[language] ?? null)}
        </DialogTitle>
        <DialogContent
          dividers
          style={content === contentParsed ? { whiteSpace: 'pre-wrap' } : {}}
        >
          <DialogContentText>{contentParsed}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            {t('translation:button.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const decodeBase64 = (string?: string) => {
  if (!string) return null;

  const decodedBuffer = Buffer.from(string, 'base64');
  return decodedBuffer.toString('latin1');
};
