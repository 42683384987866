// form
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import { TextField, TextFieldProps } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Moment } from 'moment';

// ----------------------------------------------------------------------

type Props = TextFieldProps & {
  name: string;
};

export default function RHFDate({ name, label, helperText, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <DatePicker
          label={label}
          value={new Date(field.value)}
          onChange={(date: Moment) => {
            if (!date.isValid()) return;
            field.onChange(date.toDate());
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                error={!!error}
                helperText={error ? error?.message : helperText}
                sx={{ width: '100%' }}
              />
            );
          }}
        />
      )}
    />
  );
}
