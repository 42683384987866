import { Alarm, AlarmOff } from '@mui/icons-material';
import { Stack, Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import moment from 'moment';
import { useState } from 'react';
import { useAppSelector } from '../../hooks';
import { isDepotManager } from '../account/customerSlice';
import { selectActiveDepot } from '../depot/depotSlice';
import { selectOrganization } from '../organization/organizationSlice';

export const DisplayDates = ({
  product,
  displayTime
}: {
  product: {
    startDate: Date;
    endDate: Date;
  };
  displayTime?: boolean;
}) => {
  const startDateM = moment(product.startDate);
  const startDate = startDateM.format('L');
  const startTime = startDateM.format('LT');

  const endDateM = moment(product.endDate);
  const endDate = endDateM.format('L');
  const endTime = endDateM.format('LT');

  const sameDay = startDateM.isSame(endDateM, 'day');

  if (sameDay) {
    return (
      <>
        {startDate}
        {!!displayTime && (
          <>
            <br />
            <b>
              {startTime} - {endTime}
            </b>
          </>
        )}
      </>
    );
  }

  return (
    <>
      {startDate} {!!displayTime && <b> {startTime}</b>}
      {!!displayTime ? <br /> : ' - '}
      {endDate} {!!displayTime && <b> {endTime}</b>}
    </>
  );
};

export const useDisplayTime = () => {
  const organization = useAppSelector(selectOrganization);
  const activeDepot = useAppSelector(selectActiveDepot);
  const isDM = useAppSelector(isDepotManager);

  const bookingUnit = activeDepot?.bookingUnit ?? organization.bookingUnit;

  const allowToggle: boolean = isDM || Number(bookingUnit) % (60 * 24) > 0;
  const [displayTime, setDisplayTime] = useState<boolean>(false);
  const toggleDisplayTime = () => {
    setDisplayTime((c) => !c);
  };

  return {
    allowToggle,
    toggleDisplayTime,
    displayTime
  };
};

export const HeaderDisplayDate = ({
  allowToggle,
  displayTime,
  toggleDisplayTime,
  label
}: {
  allowToggle: boolean;
  displayTime: boolean;
  toggleDisplayTime?: () => void;
  label: string;
}) => {
  return (
    <Stack
      aria-orientation={'horizontal'}
      justifyContent={'center'}
      alignItems={'center'}
      flex={1}
      flexDirection={'row'}
      display={'inline-flex'}
    >
      {label}{' '}
      {!!allowToggle && (
        <Tooltip
          title={!displayTime ? 'Show time' : 'Hide time'}
          placement={'top'}
          arrow
        >
          <Button
            variant={'text'}
            color={'inherit'}
            sx={{ ml: 1, p: 0, px: 1, py: 1 / 2 }}
            startIcon={!displayTime ? <AlarmOff /> : <Alarm />}
            onClick={(e) => {
              if (e.stopPropagation) e.stopPropagation();
              toggleDisplayTime();
            }}
          />
        </Tooltip>
      )}
    </Stack>
  );
};
