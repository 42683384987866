import { Box, BoxProps } from '@mui/material';
import { NAV } from '../../config-global';

export default function Main({
  children,
  opened
}: BoxProps & { opened: boolean }) {
  return (
    <Box
      component="main"
      sx={(theme) => ({
        minWidth: 0,
        width: '100%',
        position: 'relative',
        display: 'block',
        [theme.breakpoints.up('sm')]: {
          overflowY: 'auto',
          overflowX: 'auto'
        },
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        }),
        '-webkit-overflow-scrolling': 'touch',
        ...(opened && {
          transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
          }),
          marginLeft: '0px !important'
        }),
        [theme.breakpoints.up('md')]: {
          marginLeft: -(NAV.W_BASE / 8 + 1)
        }
      })}
    >
      {children}
    </Box>
  );
}
