import { UnauthorizedError } from '../../components/errors/UnauthorizedError';
import { useAppSelector } from '../../hooks';
import { isDepotManager } from '../account/customerSlice';
import { Feedback } from './Feedback';

export const FeedbackScreen = () => {
  if (!useAppSelector(isDepotManager)) return <UnauthorizedError />;

  return <Feedback />;
};
