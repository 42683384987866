import { ShoppingCart } from '@mui/icons-material';
import { AppBar, Badge, IconButton, Toolbar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { selectCartProductsCount } from '../../../../feature/cart/cartSlice';
import { useAppSelector } from '../../../../hooks';
import { Account } from './Account';
import { DepotSelector } from './DepotSelector';
import { LanguageSelector } from './LanguageSelector';
import { Logo } from './Logo';

export const HeaderGuest = ({ toggleCart }) => {
  const classes = useStyles();
  const productsCount = useAppSelector(selectCartProductsCount);

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar className={classes.toolBar}>
        <Logo />

        <span className="flexSpacer" />

        <DepotSelector />

        <IconButton color="inherit" onClick={toggleCart} size="large">
          <Badge badgeContent={productsCount ?? null} color="primary">
            <ShoppingCart />
          </Badge>
        </IconButton>

        <LanguageSelector />

        <Account />
      </Toolbar>
    </AppBar>
  );
};

const useStyles = makeStyles((theme) => ({
  appBar: {
    boxShadow: '0 1px 8px rgba(0,0,0,.3)',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    zIndex: theme.zIndex.drawer + 100,
    position: 'fixed'
  },
  toolBar: {
    paddingLeft: theme.spacing(1 / 2),
    paddingRight: theme.spacing(1 / 2)
  }
}));
