import { Box, Button } from '@mui/material';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CustomLoadingButton } from '../../components/custom-loading-button/CustomLoadingButton';
import UserService from '../../core/auth/userService';
import { buildRandomKey, formatPrice } from '../../core/Helpers/helper';
import { PATH_AUTH } from '../../core/routes/paths';
import { notificationCenterWidth } from '../../core/styleVariables';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useLanguage } from '../../store/LanguageProvider';
import { navigateToBackendCheckout } from '../../webServices/cartService';
import { DepotInfo } from '../depot/DepotInfo';
import { selectActiveDepot } from '../depot/depotSlice';
import { InfoButton } from '../info-button/InfoButton';
import { selectOrganization } from '../organization/organizationSlice';
import { ProductImage } from '../products/ProductImage';
import { ProductInfo } from '../products/ProductInfo';
import {
  fetchCart,
  removeProductFromCart,
  resetCart,
  selectCart,
  selectCartProductsCount,
  selectCartTotal,
  selectFlattedCartProducts,
  selectGuestShoppingCart
} from './cartSlice';

export const CartDrawer = ({
  cartOpen,
  toggleCart
}: {
  cartOpen: boolean;
  toggleCart: () => void;
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { t } = useTranslation('translation');
  const navigate = useNavigate();
  const { language } = useLanguage();
  const products = useAppSelector(selectFlattedCartProducts);
  const cart = useAppSelector(selectCart);
  const cartIsEmpty = useAppSelector(selectCartProductsCount) === 0;
  const cartTotal = useAppSelector(selectCartTotal);
  const organization = useAppSelector(selectOrganization);
  const activeDepot = useAppSelector(selectActiveDepot);
  const allowRegister = organization?.selfRegister ?? false;

  const [cartWasOpened, setCartWasOpened] = useState(false);

  useEffect(() => {
    if (
      window.location.pathname !== '/checkout/payment' &&
      UserService.isLoggedIn()
    ) {
      dispatch(fetchCart({ lang: language }));
    }
    if (window.location.search.includes('afterRegister=true') && cartIsEmpty === false && cartWasOpened === false && UserService.isLoggedIn()) {
      setCartWasOpened(true);
      toggleCart();
    }
  }, []);

  const removeFromCart = async (product) => {
    await dispatch(
      removeProductFromCart({
        optionId: product.externalId,
        sku: product.sku,
        lang: language
      })
    );
  };

  const displayProduct = (product) => {
    return (
      <div key={`${product.id}.${product.sku}.div`} style={{ marginTop: 15 }}>
        <Grid container spacing={2}>
          <Grid item>
            <ProductImage product={product} className={classes.figureImg} />
          </Grid>
          <Grid item flex={1}>
            <ProductInfo product={product} />
          </Grid>
          <Grid item>
            <CustomLoadingButton
              variant={'small'}
              icon={product.count === 1 ? 'delete' : 'remove'}
              onClick={() => removeFromCart(product)}
            />
          </Grid>
        </Grid>
        <Divider key={buildRandomKey()} />
      </div>
    );
  };

  const renderActions = () => {
    if (cartIsEmpty) return <></>;

    if (!UserService.isLoggedIn()) {
      return (
        <Grid container mt={2} spacing={1} alignItems={'center'}>
          <Grid item>
            <InfoButton id={'ANONYMOUS_INFO'} />
          </Grid>
          <Grid item flex={1}>
            <Grid container spacing={2}>
              {allowRegister && (
                <Grid item xs={7}>
                  <Button
                    style={{
                      width: '100%',
                      borderRadius: 0,
                      fontWeight: 600
                    }}
                    variant="contained"
                    color="primary"
                    disableElevation={true}
                    onClick={() => {
                      navigate(
                        PATH_AUTH.register.replace(
                          ':organizationCode',
                          activeDepot?.code ?? organization?.code
                        )
                      );
                    }}
                  >
                    {t('register.register')}
                  </Button>
                </Grid>
              )}

              <Grid item xs={allowRegister ? 5 : 12}>
                <Button
                  style={{
                    width: '100%',
                    borderRadius: 0,
                    fontWeight: 600
                  }}
                  variant="outlined"
                  color="primary"
                  disableElevation={true}
                  onClick={() => {
                    UserService.doLogin();
                  }}
                >
                  {t('register.signIn')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    }

    if (!!cartTotal && !organization.styling?.registration?.registerSimpleFixed) {
      return (
        <Button
          style={{
            marginTop: 20,
            width: '100%',
            borderRadius: 0,
            fontWeight: 600
          }}
          variant="contained"
          color="primary"
          disableElevation={true}
          disabled={cartIsEmpty}
          onClick={() => {
            toggleCart();
            navigate('/checkout');
          }}
        >
          {t('proceedToCheckout')}
        </Button>
      );
    }

    return (
      <Button
        disabled={cartIsEmpty}
        sx={{ my: 3, width: '100%' }}
        variant="contained"
        color="primary"
        onClick={() => {
          navigateToBackendCheckout(cart);
        }}
      >
        {t('confirmReservation')}
      </Button>
    );
  };

  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={cartOpen}
      ModalProps={{
        keepMounted: false,
        className: classes.modal,
        BackdropProps: {
          className: classes.backdrop
        },
        onBackdropClick: toggleCart
      }}
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <Box
        className={classes.container}
        sx={{ height: '100%', position: 'relative' }}
      >
        <Grid
          sx={{ maxHeight: '100%', flex: 1 }}
          item
          xs={12}
          sm={12}
          md={12}
          className="pa-1"
        >
          {cartIsEmpty && (
            <Typography className="pa-1">{t('cartIsEmpty')}</Typography>
          )}

          {!cartIsEmpty && <DepotInfo />}

          <List
            style={{
              width: '100%',
              maxWidth: 360
            }}
            key={buildRandomKey()}
          >
            {products.map(displayProduct)}
          </List>

          {!cartIsEmpty && !!cartTotal && (
            <div className="flexSpacer ql-align-right">
              <Typography
                variant="h6"
                className="ql-align-right"
                style={{ textAlign: 'right', paddingRight: 20 }}
              >
                {t('translation:total')}:&nbsp;&nbsp;&nbsp;
                {formatPrice(cartTotal ?? 0)}
              </Typography>
            </div>
          )}

          {!cartIsEmpty && (
            <Box sx={{ mb: 10 }}>
              {renderActions()}
              <div style={{ textAlign: 'right' }}>
                <Button
                  style={{
                    marginTop: 4,
                    textTransform: 'none'
                  }}
                  color="primary"
                  disableElevation={true}
                  disabled={cartIsEmpty}
                  onClick={async () => {
                    await dispatch(resetCart());
                    setTimeout(() => {
                      toggleCart();
                    }, 500);
                  }}
                >
                  {t('translation:clearCart')}
                </Button>
              </div>
            </Box>
          )}
        </Grid>
      </Box>
    </Drawer>
  );
};

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: notificationCenterWidth,
    maxWidth: notificationCenterWidth,
    [theme.breakpoints.down('md')]: {
      top: '56px!important',
      height: 'calc(100vh - 56px)'
    },
    [theme.breakpoints.up('sm')]: {
      top: '64px!important',
      height: 'calc(100vh - 64px)'
    },
    zIndex: theme.zIndex.drawer + 99
  },
  modal: {
    [theme.breakpoints.down('md')]: {
      top: '56px'
    },
    [theme.breakpoints.up('sm')]: {
      top: '64px'
    },
    zIndex: theme.zIndex.drawer + 99
  },
  backdrop: {},
  container: {
    position: 'relative',
    overflowX: 'hidden',
    overflowY: 'auto',
    zIndex: 1,
    flexGrow: 1
  },
  cardTitle: {
    alignContent: 'center',
    textAlign: 'center',
    marginTop: '10px',
    marginBottom: '10px'
  },
  figureImg: {
    display: 'block',
    width: 40,
    height: 40,
    objectFit: 'contain',
    border: '1px solid #000',
    borderRadius: `50px`
  }
}));
