import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { createApiThunk } from '../../apis';
import { ReadableCustomer } from '../../apis/shopizer';
import UserService from '../../core/auth/userService';
import { RootState } from '../../redux';

export const customerAdapter = createEntityAdapter<ReadableCustomer>();

const initialState = customerAdapter.getInitialState<{
  active: number | undefined;
}>({
  active: undefined
});
export type CustomerState = typeof initialState;

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCustomerProfile.fulfilled, (state, action) => {
      customerAdapter.setOne(state, action.payload);
      state.active = action.payload.id;
    });

    builder.addCase(fetchCustomerProfile.rejected, (state, action) => {
      customerAdapter.removeAll(state);
      state.active = undefined;
    });
  }
});

const customerSelectors = customerAdapter.getSelectors<RootState>(
  (state) => state.customer
);
export const { selectAll: selectAllCustomer, selectById: selectCustomerById } =
  customerSelectors;

export const selectActiveCustomer = (state: RootState) =>
  state.customer.active
    ? selectCustomerById(state, state.customer.active)
    : undefined;

export const isDepotManager = (state: RootState): boolean => {
  const customer = selectActiveCustomer(state);

  return (
    customer &&
    customer.secondaryRoles &&
    // @ts-ignore
    customer.secondaryRoles.includes('DEPOT_BEHEER')
  );
};

export const fetchCustomerProfile = createApiThunk(
  'customer/profile',
  async (_, { shopizerApi }) => {
    UserService.throwErrorNotLoggedIn();
    return await shopizerApi.customerApi.getAuthUserUsingGET();
  }
);

export const customerReducer = customerSlice.reducer;
