import { isLoadedInMoovlr } from '../components/UtilityService';
import config from '../config';
import UserService from '../core/auth/userService';

export const navigateToBackendCheckout = async (cart) => {
  if (!cart || !cart.id) return;

  const moovlrFlag =
    '?isLoadedInMoovlr=' + (isLoadedInMoovlr() ? 'true' : 'false');

  await UserService.updateToken(() => {
    const url =
      `${config.shopizerApiUrl}/checkout/cart/${cart.id}?token=` +
      UserService.getToken();
    window.open(url, '_self');
  });
};

export const navigateToRetryPayment = async (orderId) => {

  const moovlrFlag =
    '?isLoadedInMoovlr=' + (isLoadedInMoovlr() ? 'true' : 'false');

  await UserService.updateToken(() => {
    const url =
      `${config.shopizerApiUrl}/checkout/cart/retryPayment/${orderId}?token=` +
      UserService.getToken();
    window.open(url, '_self');
  });
};
