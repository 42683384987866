import { useAppSelector } from '../../hooks';
import { ProductType } from '../../types/product';
import { selectOrganization } from '../organization/organizationSlice';

export function ProductImage(props: {
  product: ProductType;
  className: string;
}) {
  const { product, className } = props;
  const organization = useAppSelector(selectOrganization);

  const backupImage =
    product?.assetType === 'ebike'
      ? organization.productIconEBike
      : organization.productIconStandardBike;

  return (
    <img
      src={product.imageUrl}
      className={className}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src = backupImage;
      }}
    />
  );
}
