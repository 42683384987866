import { CssBaseline } from '@mui/material';
import {
  createTheme,
  StyledEngineProvider,
  ThemeOptions,
  ThemeProvider as MUIThemeProvider
} from '@mui/material/styles';
import { useMemo } from 'react';
import { StyledChart } from '../../components/chart';
import customShadows from './customShadows';
import GlobalStyles from './globalStyles';
import componentsOverride from './overrides';
import palette from './palette';
import shadows from './shadows';
import typography from './typography';
import { useBackendStyling } from './useBackendStyling';

type Props = {
  children: React.ReactNode;
};

export default function ThemeProvider({ children }: Props) {
  const backendStyling = useBackendStyling();
  const themeMode = 'light';
  const themeDirection = 'ltr';

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: palette(themeMode, backendStyling),
      typography,
      shape: { borderRadius: 4 },
      direction: themeDirection,
      shadows: shadows(themeMode),
      customShadows: customShadows(themeMode)
    }),
    [themeDirection, themeMode, backendStyling]
  );

  const theme = createTheme(themeOptions);

  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        <StyledChart />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
