// @mui
import { Stack, Typography } from '@mui/material';
// components

//
import { Image } from '../../../components/image/Image';
import {
  StyledContent,
  StyledContentExtended,
  StyledRoot,
  StyledSection,
  StyledSectionBg
} from './styles';

// ----------------------------------------------------------------------

type Props = {
  title?: string;
  illustration?: string;
  children: React.ReactNode;
  bgColor?: string
  fullImage?: boolean
  extended?: boolean
};

export function LoginLayout({ children, illustration, title, bgColor, fullImage, extended }: Props) {
  return (
    <StyledRoot>
      {!!illustration && (
        <StyledSection sx={{ backgroundColor: bgColor }}>
          {(fullImage ?? false) === false && (
          <Typography
            variant="h3"
            sx={{ mb: 10, maxWidth: 480, textAlign: 'center' }}
          >
            {title}
          </Typography>
          )}
          {(fullImage ?? false) === false  && (
          <Image
            disabledEffect
            visibleByDefault
            alt="auth"
            src={illustration}
            sx={{ maxWidth: 720 }}
          />
          )}
          {fullImage === true && (
          <Image
            disabledEffect
            visibleByDefault
            alt="auth"
            src={illustration}
            sx={{ width: '100%', height: '100%', objectFit: 'fill' }}
          />
          )}
          <StyledSectionBg />
        </StyledSection>
      )}
      {!extended && 
      <StyledContent>
        <Stack sx={{ width: 1 }}> {children} </Stack>
      </StyledContent>
      }
      {extended && 
      <StyledContentExtended>
        <Stack sx={{ width: 1 }}> {children} </Stack>
      </StyledContentExtended>
      }
    </StyledRoot>
  );
}
