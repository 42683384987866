/* tslint:disable */
/* eslint-disable */
/**
 * Shopizer REST API
 * API for Shopizer e-commerce. Contains public end points as well as private end points requiring basic authentication and remote authentication based on jwt bearer token. URL patterns containing /private/_** use bearer token; those are authorized customer and administrators administration actions.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
  AnonymousShoppingCart,
  PersistableShoppingCartItem,
  ReadableShoppingCart
} from '../models';
import * as runtime from '../runtime';

export interface AddToCartUsingPOSTRequest {
  shoppingCartItem: PersistableShoppingCartItem;
  lang?: string;
  store?: string;
}

export interface AddToCartUsingPOST1Request {
  id: number;
  shoppingCartItem: PersistableShoppingCartItem;
  lang?: string;
  store?: string;
}

export interface AddToWebshopCartUsingPOSTRequest {
  shoppingCartItem: PersistableShoppingCartItem;
  accountNonExpired?: boolean;
  accountNonLocked?: boolean;
  authorities0Authority?: string;
  credentialsNonExpired?: boolean;
  email?: string;
  enabled?: boolean;
  firstName?: string;
  id?: number;
  lang?: string;
  lastName?: string;
  password?: string;
  store?: string;
  username?: string;
}

export interface DeleteCartItemUsingDELETERequest {
  code: string;
  sku: string;
  body?: boolean;
  lang?: string;
  store?: string;
}

export interface DeleteCartUsingDELETERequest {
  code: string;
}

export interface DeleteNazzaCartItemUsingDELETERequest {
  code: string;
  optionId: string;
  sku: string;
  accountNonExpired?: boolean;
  accountNonLocked?: boolean;
  authorities0Authority?: string;
  body?: boolean;
  credentialsNonExpired?: boolean;
  email?: string;
  enabled?: boolean;
  firstName?: string;
  id?: number;
  lang?: string;
  lastName?: string;
  password?: string;
  store?: string;
  username?: string;
}

export interface GetByCodeUsingGETRequest {
  code: string;
  lang?: string;
  store?: string;
}

export interface GetByCustomerUsingGETRequest {
  cart: string;
  id: number;
  lang?: string;
  store?: string;
}

export interface GetByCustomerUsingGET1Request {
  cart: string;
  lang?: string;
  store?: string;
}

export interface ModifyCartUsingPOSTRequest {
  code: string;
  shoppingCartItems: Array<PersistableShoppingCartItem>;
  lang?: string;
  store?: string;
}

export interface ModifyCartUsingPOST1Request {
  code: string;
  promo: string;
  lang?: string;
  store?: string;
}

export interface ModifyCartUsingPUTRequest {
  code: string;
  shoppingCartItem: PersistableShoppingCartItem;
  lang?: string;
  store?: string;
}

export interface ModifyNazzaCartUsingPUTRequest {
  code: string;
  shoppingCartItem: PersistableShoppingCartItem;
  accountNonExpired?: boolean;
  accountNonLocked?: boolean;
  authorities0Authority?: string;
  credentialsNonExpired?: boolean;
  email?: string;
  enabled?: boolean;
  firstName?: string;
  id?: number;
  lang?: string;
  lastName?: string;
  password?: string;
  store?: string;
  username?: string;
}

export interface ShoppingCartMappedUsingPOSTRequest {
  anonymousShoppingCart: AnonymousShoppingCart;
  accountNonExpired?: boolean;
  accountNonLocked?: boolean;
  authorities0Authority?: string;
  credentialsNonExpired?: boolean;
  email?: string;
  enabled?: boolean;
  firstName?: string;
  id?: number;
  lang?: string;
  lastName?: string;
  password?: string;
  store?: string;
  username?: string;
}

/**
 *
 */
export class ShoppingCartApiApi extends runtime.BaseAPI {
  /**
   * No customer ID in scope. Add to cart for non authenticated users, as simple as {\"product\":1232,\"quantity\":1}
   * Add product to shopping cart when no cart exists, this will create a new cart id
   */
  async addToCartUsingPOSTRaw(
    requestParameters: AddToCartUsingPOSTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ReadableShoppingCart>> {
    if (
      requestParameters.shoppingCartItem === null ||
      requestParameters.shoppingCartItem === undefined
    ) {
      throw new runtime.RequiredError(
        'shoppingCartItem',
        'Required parameter requestParameters.shoppingCartItem was null or undefined when calling addToCartUsingPOST.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    if (requestParameters.store !== undefined) {
      queryParameters['store'] = requestParameters.store;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/cart`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.shoppingCartItem
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * No customer ID in scope. Add to cart for non authenticated users, as simple as {\"product\":1232,\"quantity\":1}
   * Add product to shopping cart when no cart exists, this will create a new cart id
   */
  async addToCartUsingPOST(
    requestParameters: AddToCartUsingPOSTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ReadableShoppingCart> {
    const response = await this.addToCartUsingPOSTRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Add product to a specific customer shopping cart
   */
  async addToCartUsingPOST1Raw(
    requestParameters: AddToCartUsingPOST1Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ReadableShoppingCart>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling addToCartUsingPOST1.'
      );
    }

    if (
      requestParameters.shoppingCartItem === null ||
      requestParameters.shoppingCartItem === undefined
    ) {
      throw new runtime.RequiredError(
        'shoppingCartItem',
        'Required parameter requestParameters.shoppingCartItem was null or undefined when calling addToCartUsingPOST1.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    if (requestParameters.store !== undefined) {
      queryParameters['store'] = requestParameters.store;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/customers/{id}/cart`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.shoppingCartItem
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Add product to a specific customer shopping cart
   */
  async addToCartUsingPOST1(
    requestParameters: AddToCartUsingPOST1Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ReadableShoppingCart> {
    const response = await this.addToCartUsingPOST1Raw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * No customer ID in scope. Add to cart for non authenticated users, as simple as {\"product\":1232,\"quantity\":1}
   * Add product to shopping cart when no cart exists, this will create a new cart id
   */
  async addToWebshopCartUsingPOSTRaw(
    requestParameters: AddToWebshopCartUsingPOSTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ReadableShoppingCart>> {
    if (
      requestParameters.shoppingCartItem === null ||
      requestParameters.shoppingCartItem === undefined
    ) {
      throw new runtime.RequiredError(
        'shoppingCartItem',
        'Required parameter requestParameters.shoppingCartItem was null or undefined when calling addToWebshopCartUsingPOST.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.accountNonExpired !== undefined) {
      queryParameters['accountNonExpired'] =
        requestParameters.accountNonExpired;
    }

    if (requestParameters.accountNonLocked !== undefined) {
      queryParameters['accountNonLocked'] = requestParameters.accountNonLocked;
    }

    if (requestParameters.authorities0Authority !== undefined) {
      queryParameters['authorities[0].authority'] =
        requestParameters.authorities0Authority;
    }

    if (requestParameters.credentialsNonExpired !== undefined) {
      queryParameters['credentialsNonExpired'] =
        requestParameters.credentialsNonExpired;
    }

    if (requestParameters.email !== undefined) {
      queryParameters['email'] = requestParameters.email;
    }

    if (requestParameters.enabled !== undefined) {
      queryParameters['enabled'] = requestParameters.enabled;
    }

    if (requestParameters.firstName !== undefined) {
      queryParameters['firstName'] = requestParameters.firstName;
    }

    if (requestParameters.id !== undefined) {
      queryParameters['id'] = requestParameters.id;
    }

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    if (requestParameters.lastName !== undefined) {
      queryParameters['lastName'] = requestParameters.lastName;
    }

    if (requestParameters.password !== undefined) {
      queryParameters['password'] = requestParameters.password;
    }

    if (requestParameters.store !== undefined) {
      queryParameters['store'] = requestParameters.store;
    }

    if (requestParameters.username !== undefined) {
      queryParameters['username'] = requestParameters.username;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/nazza-cart`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.shoppingCartItem
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * No customer ID in scope. Add to cart for non authenticated users, as simple as {\"product\":1232,\"quantity\":1}
   * Add product to shopping cart when no cart exists, this will create a new cart id
   */
  async addToWebshopCartUsingPOST(
    requestParameters: AddToWebshopCartUsingPOSTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ReadableShoppingCart> {
    const response = await this.addToWebshopCartUsingPOSTRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * If body set to true returns remaining cart in body, empty cart gives empty body. If body set to false no body
   * Remove a product from a specific cart
   */
  async deleteCartItemUsingDELETERaw(
    requestParameters: DeleteCartItemUsingDELETERequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ReadableShoppingCart>> {
    if (
      requestParameters.code === null ||
      requestParameters.code === undefined
    ) {
      throw new runtime.RequiredError(
        'code',
        'Required parameter requestParameters.code was null or undefined when calling deleteCartItemUsingDELETE.'
      );
    }

    if (requestParameters.sku === null || requestParameters.sku === undefined) {
      throw new runtime.RequiredError(
        'sku',
        'Required parameter requestParameters.sku was null or undefined when calling deleteCartItemUsingDELETE.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.body !== undefined) {
      queryParameters['body'] = requestParameters.body;
    }

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    if (requestParameters.store !== undefined) {
      queryParameters['store'] = requestParameters.store;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/cart/{code}/product/{sku}`
          .replace(
            `{${'code'}}`,
            encodeURIComponent(String(requestParameters.code))
          )
          .replace(
            `{${'sku'}}`,
            encodeURIComponent(String(requestParameters.sku))
          ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * If body set to true returns remaining cart in body, empty cart gives empty body. If body set to false no body
   * Remove a product from a specific cart
   */
  async deleteCartItemUsingDELETE(
    requestParameters: DeleteCartItemUsingDELETERequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ReadableShoppingCart> {
    const response = await this.deleteCartItemUsingDELETERaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Deletes a shopping cart
   */
  async deleteCartUsingDELETERaw(
    requestParameters: DeleteCartUsingDELETERequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ReadableShoppingCart>> {
    if (
      requestParameters.code === null ||
      requestParameters.code === undefined
    ) {
      throw new runtime.RequiredError(
        'code',
        'Required parameter requestParameters.code was null or undefined when calling deleteCartUsingDELETE.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/nazza-cart/{code}/delete`.replace(
          `{${'code'}}`,
          encodeURIComponent(String(requestParameters.code))
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Deletes a shopping cart
   */
  async deleteCartUsingDELETE(
    requestParameters: DeleteCartUsingDELETERequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ReadableShoppingCart> {
    const response = await this.deleteCartUsingDELETERaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * If body set to true returns remaining cart in body, empty cart gives empty body. If body set to false no body
   * Remove a product from a specific cart
   */
  async deleteNazzaCartItemUsingDELETERaw(
    requestParameters: DeleteNazzaCartItemUsingDELETERequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ReadableShoppingCart>> {
    if (
      requestParameters.code === null ||
      requestParameters.code === undefined
    ) {
      throw new runtime.RequiredError(
        'code',
        'Required parameter requestParameters.code was null or undefined when calling deleteNazzaCartItemUsingDELETE.'
      );
    }

    if (
      requestParameters.optionId === null ||
      requestParameters.optionId === undefined
    ) {
      throw new runtime.RequiredError(
        'optionId',
        'Required parameter requestParameters.optionId was null or undefined when calling deleteNazzaCartItemUsingDELETE.'
      );
    }

    if (requestParameters.sku === null || requestParameters.sku === undefined) {
      throw new runtime.RequiredError(
        'sku',
        'Required parameter requestParameters.sku was null or undefined when calling deleteNazzaCartItemUsingDELETE.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.accountNonExpired !== undefined) {
      queryParameters['accountNonExpired'] =
        requestParameters.accountNonExpired;
    }

    if (requestParameters.accountNonLocked !== undefined) {
      queryParameters['accountNonLocked'] = requestParameters.accountNonLocked;
    }

    if (requestParameters.authorities0Authority !== undefined) {
      queryParameters['authorities[0].authority'] =
        requestParameters.authorities0Authority;
    }

    if (requestParameters.body !== undefined) {
      queryParameters['body'] = requestParameters.body;
    }

    if (requestParameters.credentialsNonExpired !== undefined) {
      queryParameters['credentialsNonExpired'] =
        requestParameters.credentialsNonExpired;
    }

    if (requestParameters.email !== undefined) {
      queryParameters['email'] = requestParameters.email;
    }

    if (requestParameters.enabled !== undefined) {
      queryParameters['enabled'] = requestParameters.enabled;
    }

    if (requestParameters.firstName !== undefined) {
      queryParameters['firstName'] = requestParameters.firstName;
    }

    if (requestParameters.id !== undefined) {
      queryParameters['id'] = requestParameters.id;
    }

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    if (requestParameters.lastName !== undefined) {
      queryParameters['lastName'] = requestParameters.lastName;
    }

    if (requestParameters.password !== undefined) {
      queryParameters['password'] = requestParameters.password;
    }

    if (requestParameters.store !== undefined) {
      queryParameters['store'] = requestParameters.store;
    }

    if (requestParameters.username !== undefined) {
      queryParameters['username'] = requestParameters.username;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/nazza-cart/{code}/product/{sku}/option/{optionId}`
          .replace(
            `{${'code'}}`,
            encodeURIComponent(String(requestParameters.code))
          )
          .replace(
            `{${'optionId'}}`,
            encodeURIComponent(String(requestParameters.optionId))
          )
          .replace(
            `{${'sku'}}`,
            encodeURIComponent(String(requestParameters.sku))
          ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * If body set to true returns remaining cart in body, empty cart gives empty body. If body set to false no body
   * Remove a product from a specific cart
   */
  async deleteNazzaCartItemUsingDELETE(
    requestParameters: DeleteNazzaCartItemUsingDELETERequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ReadableShoppingCart> {
    const response = await this.deleteNazzaCartItemUsingDELETERaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get a chopping cart by code
   */
  async getByCodeUsingGETRaw(
    requestParameters: GetByCodeUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ReadableShoppingCart>> {
    if (
      requestParameters.code === null ||
      requestParameters.code === undefined
    ) {
      throw new runtime.RequiredError(
        'code',
        'Required parameter requestParameters.code was null or undefined when calling getByCodeUsingGET.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    if (requestParameters.store !== undefined) {
      queryParameters['store'] = requestParameters.store;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/cart/{code}`.replace(
          `{${'code'}}`,
          encodeURIComponent(String(requestParameters.code))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Get a chopping cart by code
   */
  async getByCodeUsingGET(
    requestParameters: GetByCodeUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ReadableShoppingCart> {
    const response = await this.getByCodeUsingGETRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get a shopping cart by customer id. Customer must be authenticated
   */
  async getByCustomerUsingGETRaw(
    requestParameters: GetByCustomerUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ReadableShoppingCart>> {
    if (
      requestParameters.cart === null ||
      requestParameters.cart === undefined
    ) {
      throw new runtime.RequiredError(
        'cart',
        'Required parameter requestParameters.cart was null or undefined when calling getByCustomerUsingGET.'
      );
    }

    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getByCustomerUsingGET.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.cart !== undefined) {
      queryParameters['cart'] = requestParameters.cart;
    }

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    if (requestParameters.store !== undefined) {
      queryParameters['store'] = requestParameters.store;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/auth/customer/{id}/cart`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Get a shopping cart by customer id. Customer must be authenticated
   */
  async getByCustomerUsingGET(
    requestParameters: GetByCustomerUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ReadableShoppingCart> {
    const response = await this.getByCustomerUsingGETRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get a shopping cart by authenticated customer
   */
  async getByCustomerUsingGET1Raw(
    requestParameters: GetByCustomerUsingGET1Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ReadableShoppingCart>> {
    if (
      requestParameters.cart === null ||
      requestParameters.cart === undefined
    ) {
      throw new runtime.RequiredError(
        'cart',
        'Required parameter requestParameters.cart was null or undefined when calling getByCustomerUsingGET1.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.cart !== undefined) {
      queryParameters['cart'] = requestParameters.cart;
    }

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    if (requestParameters.store !== undefined) {
      queryParameters['store'] = requestParameters.store;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/auth/customer/cart`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Get a shopping cart by authenticated customer
   */
  async getByCustomerUsingGET1(
    requestParameters: GetByCustomerUsingGET1Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ReadableShoppingCart> {
    const response = await this.getByCustomerUsingGET1Raw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * No customer ID in scope. Modify cart for non authenticated users, as simple as {\"product\":1232,\"quantity\":0} for instance will remove item 1234 from cart
   * Add to an existing shopping cart or modify an item quantity
   */
  async modifyCartUsingPOSTRaw(
    requestParameters: ModifyCartUsingPOSTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ReadableShoppingCart>> {
    if (
      requestParameters.code === null ||
      requestParameters.code === undefined
    ) {
      throw new runtime.RequiredError(
        'code',
        'Required parameter requestParameters.code was null or undefined when calling modifyCartUsingPOST.'
      );
    }

    if (
      requestParameters.shoppingCartItems === null ||
      requestParameters.shoppingCartItems === undefined
    ) {
      throw new runtime.RequiredError(
        'shoppingCartItems',
        'Required parameter requestParameters.shoppingCartItems was null or undefined when calling modifyCartUsingPOST.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    if (requestParameters.store !== undefined) {
      queryParameters['store'] = requestParameters.store;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/cart/{code}/multi`.replace(
          `{${'code'}}`,
          encodeURIComponent(String(requestParameters.code))
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.shoppingCartItems
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * No customer ID in scope. Modify cart for non authenticated users, as simple as {\"product\":1232,\"quantity\":0} for instance will remove item 1234 from cart
   * Add to an existing shopping cart or modify an item quantity
   */
  async modifyCartUsingPOST(
    requestParameters: ModifyCartUsingPOSTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ReadableShoppingCart> {
    const response = await this.modifyCartUsingPOSTRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Add promo / coupon to an existing cart
   */
  async modifyCartUsingPOST1Raw(
    requestParameters: ModifyCartUsingPOST1Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ReadableShoppingCart>> {
    if (
      requestParameters.code === null ||
      requestParameters.code === undefined
    ) {
      throw new runtime.RequiredError(
        'code',
        'Required parameter requestParameters.code was null or undefined when calling modifyCartUsingPOST1.'
      );
    }

    if (
      requestParameters.promo === null ||
      requestParameters.promo === undefined
    ) {
      throw new runtime.RequiredError(
        'promo',
        'Required parameter requestParameters.promo was null or undefined when calling modifyCartUsingPOST1.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    if (requestParameters.store !== undefined) {
      queryParameters['store'] = requestParameters.store;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/cart/{code}/promo/{promo}`
          .replace(
            `{${'code'}}`,
            encodeURIComponent(String(requestParameters.code))
          )
          .replace(
            `{${'promo'}}`,
            encodeURIComponent(String(requestParameters.promo))
          ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Add promo / coupon to an existing cart
   */
  async modifyCartUsingPOST1(
    requestParameters: ModifyCartUsingPOST1Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ReadableShoppingCart> {
    const response = await this.modifyCartUsingPOST1Raw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * No customer ID in scope. Modify cart for non authenticated users, as simple as {\"product\":1232,\"quantity\":0} for instance will remove item 1234 from cart
   * Add to an existing shopping cart or modify an item quantity
   */
  async modifyCartUsingPUTRaw(
    requestParameters: ModifyCartUsingPUTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ReadableShoppingCart>> {
    if (
      requestParameters.code === null ||
      requestParameters.code === undefined
    ) {
      throw new runtime.RequiredError(
        'code',
        'Required parameter requestParameters.code was null or undefined when calling modifyCartUsingPUT.'
      );
    }

    if (
      requestParameters.shoppingCartItem === null ||
      requestParameters.shoppingCartItem === undefined
    ) {
      throw new runtime.RequiredError(
        'shoppingCartItem',
        'Required parameter requestParameters.shoppingCartItem was null or undefined when calling modifyCartUsingPUT.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    if (requestParameters.store !== undefined) {
      queryParameters['store'] = requestParameters.store;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/cart/{code}`.replace(
          `{${'code'}}`,
          encodeURIComponent(String(requestParameters.code))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.shoppingCartItem
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * No customer ID in scope. Modify cart for non authenticated users, as simple as {\"product\":1232,\"quantity\":0} for instance will remove item 1234 from cart
   * Add to an existing shopping cart or modify an item quantity
   */
  async modifyCartUsingPUT(
    requestParameters: ModifyCartUsingPUTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ReadableShoppingCart> {
    const response = await this.modifyCartUsingPUTRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * No customer ID in scope. Modify cart for non authenticated users, as simple as {\"product\":1232,\"quantity\":0} for instance will remove item 1234 from cart
   * Add to an existing shopping cart or modify an item quantity
   */
  async modifyNazzaCartUsingPUTRaw(
    requestParameters: ModifyNazzaCartUsingPUTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ReadableShoppingCart>> {
    if (
      requestParameters.code === null ||
      requestParameters.code === undefined
    ) {
      throw new runtime.RequiredError(
        'code',
        'Required parameter requestParameters.code was null or undefined when calling modifyNazzaCartUsingPUT.'
      );
    }

    if (
      requestParameters.shoppingCartItem === null ||
      requestParameters.shoppingCartItem === undefined
    ) {
      throw new runtime.RequiredError(
        'shoppingCartItem',
        'Required parameter requestParameters.shoppingCartItem was null or undefined when calling modifyNazzaCartUsingPUT.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.accountNonExpired !== undefined) {
      queryParameters['accountNonExpired'] =
        requestParameters.accountNonExpired;
    }

    if (requestParameters.accountNonLocked !== undefined) {
      queryParameters['accountNonLocked'] = requestParameters.accountNonLocked;
    }

    if (requestParameters.authorities0Authority !== undefined) {
      queryParameters['authorities[0].authority'] =
        requestParameters.authorities0Authority;
    }

    if (requestParameters.credentialsNonExpired !== undefined) {
      queryParameters['credentialsNonExpired'] =
        requestParameters.credentialsNonExpired;
    }

    if (requestParameters.email !== undefined) {
      queryParameters['email'] = requestParameters.email;
    }

    if (requestParameters.enabled !== undefined) {
      queryParameters['enabled'] = requestParameters.enabled;
    }

    if (requestParameters.firstName !== undefined) {
      queryParameters['firstName'] = requestParameters.firstName;
    }

    if (requestParameters.id !== undefined) {
      queryParameters['id'] = requestParameters.id;
    }

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    if (requestParameters.lastName !== undefined) {
      queryParameters['lastName'] = requestParameters.lastName;
    }

    if (requestParameters.password !== undefined) {
      queryParameters['password'] = requestParameters.password;
    }

    if (requestParameters.store !== undefined) {
      queryParameters['store'] = requestParameters.store;
    }

    if (requestParameters.username !== undefined) {
      queryParameters['username'] = requestParameters.username;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/nazza-cart/{code}`.replace(
          `{${'code'}}`,
          encodeURIComponent(String(requestParameters.code))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.shoppingCartItem
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * No customer ID in scope. Modify cart for non authenticated users, as simple as {\"product\":1232,\"quantity\":0} for instance will remove item 1234 from cart
   * Add to an existing shopping cart or modify an item quantity
   */
  async modifyNazzaCartUsingPUT(
    requestParameters: ModifyNazzaCartUsingPUTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ReadableShoppingCart> {
    const response = await this.modifyNazzaCartUsingPUTRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Associate anonymous shoppingCart to given customer
   */
  async shoppingCartMappedUsingPOSTRaw(
    requestParameters: ShoppingCartMappedUsingPOSTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ReadableShoppingCart>> {
    if (
      requestParameters.anonymousShoppingCart === null ||
      requestParameters.anonymousShoppingCart === undefined
    ) {
      throw new runtime.RequiredError(
        'anonymousShoppingCart',
        'Required parameter requestParameters.anonymousShoppingCart was null or undefined when calling shoppingCartMappedUsingPOST.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.accountNonExpired !== undefined) {
      queryParameters['accountNonExpired'] =
        requestParameters.accountNonExpired;
    }

    if (requestParameters.accountNonLocked !== undefined) {
      queryParameters['accountNonLocked'] = requestParameters.accountNonLocked;
    }

    if (requestParameters.authorities0Authority !== undefined) {
      queryParameters['authorities[0].authority'] =
        requestParameters.authorities0Authority;
    }

    if (requestParameters.credentialsNonExpired !== undefined) {
      queryParameters['credentialsNonExpired'] =
        requestParameters.credentialsNonExpired;
    }

    if (requestParameters.email !== undefined) {
      queryParameters['email'] = requestParameters.email;
    }

    if (requestParameters.enabled !== undefined) {
      queryParameters['enabled'] = requestParameters.enabled;
    }

    if (requestParameters.firstName !== undefined) {
      queryParameters['firstName'] = requestParameters.firstName;
    }

    if (requestParameters.id !== undefined) {
      queryParameters['id'] = requestParameters.id;
    }

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    if (requestParameters.lastName !== undefined) {
      queryParameters['lastName'] = requestParameters.lastName;
    }

    if (requestParameters.password !== undefined) {
      queryParameters['password'] = requestParameters.password;
    }

    if (requestParameters.store !== undefined) {
      queryParameters['store'] = requestParameters.store;
    }

    if (requestParameters.username !== undefined) {
      queryParameters['username'] = requestParameters.username;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/nazza-cart/validate`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.anonymousShoppingCart
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Associate anonymous shoppingCart to given customer
   */
  async shoppingCartMappedUsingPOST(
    requestParameters: ShoppingCartMappedUsingPOSTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ReadableShoppingCart> {
    const response = await this.shoppingCartMappedUsingPOSTRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
