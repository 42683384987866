import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import moment from 'moment';
import { createApiThunk } from '../../apis';
import { ReadableShoppingCart } from '../../apis/shopizer';
import UserService from '../../core/auth/userService';
import { RootState } from '../../redux';
import { ProductType } from '../../types/product';
import { selectActiveDepotCode } from '../depot/depotSlice';
import {
  combineProducts,
  parseCartProductsForFrontend
} from '../products/productService';

export type CartState = {
  readableShoppingCart: ReadableShoppingCart;
  guestShoppingCart?: {
    products: ProductType[];
    depotCode: string;
  };
};

const initialState: CartState = {
  readableShoppingCart: { code: '22975104c68046fdb668d5fcc14d51dc' }
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    overrideGuestCart: (state, action: PayloadAction<any>) => {
      state.readableShoppingCart = null;
      state.guestShoppingCart = {
        products : action.payload.products,
        depotCode: action.payload.depotCode
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCart.fulfilled, (state, action) => {
      state.readableShoppingCart = action.payload;
      state.guestShoppingCart = null;
    });

    builder.addCase(addToCartUser.fulfilled, (state, action) => {
      state.readableShoppingCart = action.payload;
    });

    builder.addCase(addToCartGuest.fulfilled, (state, action) => {
      if (
        !state.guestShoppingCart ||
        state.guestShoppingCart.depotCode !== action.payload.activeDepotCode
      ) {
        state.guestShoppingCart = {
          products: [],
          depotCode: action.payload.activeDepotCode
        };
      }

      state.guestShoppingCart.products.push(action.payload.product);
    });

    builder.addCase(resetCart.fulfilled, (state, action) => {
      state.readableShoppingCart = {};
      state.guestShoppingCart = null;
    });

    builder.addCase(resetCartInFrontend.fulfilled, (state, action) => {
      state.readableShoppingCart = {};
      state.guestShoppingCart = null;
    });

    builder.addCase(removeProductFromCartUser.fulfilled, (state, action) => {
      state.readableShoppingCart = action.payload;
    });

    builder.addCase(removeProductFromCartGuest.fulfilled, (state, action) => {
      if (!state.guestShoppingCart?.products) return;
      state.guestShoppingCart.products =
        state.guestShoppingCart.products.filter(
          (p) => p.externalId !== action.payload.optionId
        );
    });

    builder.addCase(fetchCart.rejected, (state, action) => {
      state.readableShoppingCart = { language: 'fetchCart.rejected' };
    });
  }
});

export const selectCart = (state: RootState) => {
  return state.cart.readableShoppingCart;
};

export const selectCartCode = (state: RootState) => {
  return state.cart.readableShoppingCart?.code;
};

export const selectCartProducts = (state: RootState) => {
  const cart: CartState = state.cart;
  return cart.readableShoppingCart?.products ?? [];
};

export const selectFlattedCartProducts = (state: RootState) => {
  const cart: CartState = state.cart;
  if (UserService.isLoggedIn()) {
    return parseCartProductsForFrontend(selectCartProducts(state));
  }

  if (cart.guestShoppingCart?.products) {
    const products = JSON.parse(
      JSON.stringify(cart.guestShoppingCart.products)
    );
    return combineProducts(products);
  }

  return [];
};

export const selectGuestShoppingCart = (state: RootState) => {
  const cart: CartState = state.cart;
  return cart.guestShoppingCart;
};

export const selectCartTotal = (state: RootState) => {
  const cart: CartState = state.cart;
  if (UserService.isLoggedIn()) {
    return cart.readableShoppingCart?.total ?? 0;
  }

  return _.sumBy(cart.guestShoppingCart?.products ?? [], (p: ProductType) => {
    return p.subTotal;
  });
};

export const selectCartProductsCount = (state: RootState) => {
  const cart: CartState = state.cart;
  if (UserService.isLoggedIn() && selectCartProducts(state).length > 0) {
    return selectCartProducts(state).length;
  }

  if (cart.guestShoppingCart?.products) {
    return cart.guestShoppingCart.products.length;
  }

  return 0;
};

export const fetchCart = createApiThunk(
  'cart/fetch',
  async (request: { lang: string }, { shopizerApi, getState }) => {
    UserService.throwErrorNotLoggedIn();

    const guestCart = selectGuestShoppingCart(getState());
    if (guestCart && guestCart.products.length > 0) {
      return await shopizerApi.shoppingCartApi.shoppingCartMappedUsingPOST({
        lang: request.lang,
        anonymousShoppingCart: {
          depotCode: guestCart.depotCode,
          products: guestCart.products.map((p) => {
            return {
              sku: p.sku,
              count: 1,
              assetType: p.assetType,
              startDate: moment(p.startDate).toISOString(),
              endDate: moment(p.endDate).toISOString()
            };
          })
        }
      });
    }

    const cartCode = selectCartCode(getState());
    if (!cartCode) return;

    return await shopizerApi.shoppingCartApi.getByCodeUsingGET({
      code: cartCode,
      ...request
    });
  }
);

export const addToCart = createApiThunk(
  'cart/addToCart',
  async (
    {
      product,
      language
    }: {
      product: ProductType;
      language: string;
    },
    { dispatch }
  ) => {
    if (UserService.isLoggedIn()) {
      const response = await dispatch(
        addToCartUser({
          sku: product.sku,
          externalId: product.externalId,
          language,
          selectedEmail : product.selectedEmail
        })
      );

      return response;
    }

    await dispatch(addToCartGuest(product));
  }
);

export const addToCartUser = createApiThunk(
  'cart/addToCartUser',
  async (
    {
      externalId,
      sku,
      language,
      selectedEmail
    }: {
      externalId: string;
      sku: string;
      language: string;
      selectedEmail: string;
    },
    { shopizerApi, getState }
  ) => {
    const cartCode = selectCartCode(getState());
    const activeDepotCode = selectActiveDepotCode(getState());

    if (!cartCode) {
      return await shopizerApi.shoppingCartApi.addToWebshopCartUsingPOST({
        lang: language,
        store: activeDepotCode,
        shoppingCartItem: {
          externalId: externalId,
          attributes: [],
          product: sku,
          selectedEmail : selectedEmail
        }
      });
    }

    return await shopizerApi.shoppingCartApi.modifyNazzaCartUsingPUT({
      code: cartCode,
      lang: language,
      store: activeDepotCode,
      shoppingCartItem: {
        externalId: externalId,
        attributes: [],
        product: sku,
        selectedEmail : selectedEmail
      }
    });
  }
);

export const addToCartGuest = createApiThunk(
  'cart/addToCartGuest',
  async (product: ProductType, { getState }) => {
    const activeDepotCode = selectActiveDepotCode(getState());
    return { product, activeDepotCode };
  }
);

export const resetCart = createApiThunk(
  'cart/reset',
  async (_, { shopizerApi, getState }) => {
    if (!UserService.isLoggedIn()) return;

    const cartCode = selectCartCode(getState());
    if (!cartCode) return;

    return await shopizerApi.shoppingCartApi.deleteCartUsingDELETE({
      code: cartCode
    });
  }
);

export const resetCartInFrontend = createApiThunk(
  'cart/resetFrontend',
  async (_) => {}
);

export const removeProductFromCart = createApiThunk(
  'cart/removeProductFromCart',
  async (
    request: {
      optionId: string;
      sku: string;
      lang: string;
    },
    { dispatch }
  ) => {
    if (UserService.isLoggedIn()) {
      await dispatch(removeProductFromCartUser(request));
      return;
    }

    await dispatch(removeProductFromCartGuest(request));
  }
);

export const removeProductFromCartUser = createApiThunk(
  'cart/removeProductFromCartUser',
  async (
    request: {
      optionId: string;
      sku: string;
      lang: string;
    },
    { shopizerApi, getState }
  ) => {
    const cartCode = selectCartCode(getState());

    try {
      return await shopizerApi.shoppingCartApi.deleteNazzaCartItemUsingDELETE({
        code: cartCode,
        body: true,
        ...request
      });
    } catch (e) {
      fetchCart({ lang: request.lang });
    }
  }
);

export const removeProductFromCartGuest = createApiThunk(
  'cart/removeProductFromCartGuest',
  async (request: { optionId: string; sku: string; lang: string }) => {
    return request;
  }
);

export const cartReducer = cartSlice.reducer;
export const { overrideGuestCart } = cartSlice.actions;
