import { Loop } from '@mui/icons-material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { createStyles } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';

export const Refresh = ({
  isLoading,
  onClick
}: {
  isLoading: boolean;
  onClick: () => void;
}) => {
  const classes = useStyles();

  return (
    <Grid item>
      <Button variant={'text'} sx={{ px: 0, minWidth: 1 }} onClick={onClick}>
        <Loop className={isLoading ? classes.rotateIcon : ''} />
      </Button>
    </Grid>
  );
};

export const useStyles = makeStyles(() =>
  createStyles({
    rotateIcon: {
      animation: '$spin 1s linear infinite'
    },
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(360deg)'
      },
      '100%': {
        transform: 'rotate(0deg)'
      }
    }
  })
);
