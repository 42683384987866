import { Box, CircularProgress, Icon } from '@mui/material';
import Fab from '@mui/material/Fab';
import { useState } from 'react';

export function CustomLoadingButton(props: {
  onClick: () => void;
  icon: string;
  label?: string;
  variant?: 'small' | 'large';
}) {
  const [isBusy, setIsBusy] = useState(false);
  const { label, onClick, icon } = props;

  const variant = props.variant ?? 'large';

  const variants = {
    small: {
      fabPx: 1,
      textSize: 10,
      iconSize: '16px !important',
      circularProgressSize: 32
    },
    large: {
      fabPx: 1.5,
      textSize: 16,
      iconSize: '24px !important',
      circularProgressSize: 48
    }
  };

  const style = variants[variant];

  return (
    <Box
      sx={{
        margin: 0,
        display: 'inline-block',
        position: 'relative'
      }}
    >
      <Fab
        variant="extended"
        aria-label="save"
        color="primary"
        size={variant}
        sx={{ px: style.fabPx }}
        disabled={isBusy}
        onClick={async () => {
          if (isBusy) return;
          setIsBusy(true);
          await onClick();
          setIsBusy(false);
        }}
      >
        <Box sx={{ color: 'primary.contrastText', pt: 1 }}>
          <Icon sx={{ fontSize: style.iconSize }}>{icon}</Icon>
        </Box>
        {!!label && (
          <Box
            sx={{
              pl: isBusy ? 2 : 1,
              color: 'primary.contrastText',
              fontSize: style.textSize
            }}
          >
            {label}
          </Box>
        )}
      </Fab>
      {isBusy && (
        <CircularProgress
          size={style.circularProgressSize}
          style={{
            color: 'beige',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 1000
          }}
        />
      )}
    </Box>
  );
}
