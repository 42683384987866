const BASE_URL =
  process.env.NODE_ENV == 'development'
    ? 'http://localhost:8080/api/v1'
    : '/api/v1';

let config = {
  shopizerApiUrl: BASE_URL,
  defaultStore: 'DEFAULT',
  currentLanguageCode: 'en',
  keycloakCheckLoginIframe: true
};

try {
  const localConfig = require('./config.local.ts');
  config = { ...config, ...localConfig.default };
} catch (e) {
  // ignore
}

export default config;
