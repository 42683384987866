import { createSlice } from '@reduxjs/toolkit';
import { createApiThunk } from '../../apis';
import { ReadableMerchantStore } from '../../apis/shopizer';
import { RootState } from '../../redux';
import { fetchCustomerProfile } from '../account/customerSlice';
import { selectActiveDepot } from '../depot/depotSlice';
import {
  getEnhancedOrganization,
  isBookingUnitDays
} from './organizationService';

export type Organization = ReadableMerchantStore & {
  productIconEBike?: string;
  productIconStandardBike?: string;
};

export type OrganizationState = {
  organization?: Organization;
};

const initialState: OrganizationState = {
  organization: null
};

export const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCustomerProfile.fulfilled, (state, action) => {
      state.organization = action.payload.readableMerchantStore;
    });

    builder.addCase(fetchOrganization.fulfilled, (state, action) => {
      if (action.payload.parent) {
        state.organization = action.payload.parent;
        return;
      }

      state.organization = action.payload;
    });

    builder.addCase(fetchCustomerProfile.rejected, (state, action) => {
      state.organization = initialState.organization;
    });

    builder.addCase(fetchOrganization.rejected, (state, action) => {
      state.organization = initialState.organization;
    });
  }
});

export const selectOrganization = (state: RootState): Organization | null => {
  if (!state.organization?.organization?.code) return null;

  return getEnhancedOrganization(state.organization.organization);
};

export const selectIsBookingUnitDays = (state: RootState): boolean => {
  const organization = selectOrganization(state);
  const activeDepot = selectActiveDepot(state);
  return isBookingUnitDays(organization, activeDepot);
};

export const fetchOrganization = createApiThunk(
  'organization/fetch',
  async (
    { organizationCode }: { organizationCode: string },
    { shopizerApi }
  ) => {
    console.log('organization/fetch');
    return await shopizerApi.storeApi.publicStoreUsingGET({
      code: organizationCode
    });
  }
);

export const organizationReducer = organizationSlice.reducer;
// export default organizationSlice.reducer;
