import moment from 'moment';

export const isLoadedInMoovlr = () => {
  // @ts-ignore
  return typeof loadedInMoovlr !== 'undefined' && loadedInMoovlr === true;
};

export const getDateFromAny = (value: any): Date | null => {
  if (!value) return null;

  const date = moment(value);
  if (date.isValid()) return date.toDate();

  const dateFromTimestamp = moment(Number(value));
  if (dateFromTimestamp.isValid()) return dateFromTimestamp.toDate();

  return null;
};
