import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { buildRandomKey, formatPrice } from '../../core/Helpers/helper';
import { useAppSelector } from '../../hooks';
import { ProductType } from '../../types/product';
import { selectIsBookingUnitDays } from '../organization/organizationSlice';
import { DisplayDates, useDisplayTime } from './DisplayDates';
import { showExtraInfoIcon } from './ProductCard';

export function ProductInfo({ product }: { product: ProductType }) {
  const { t } = useTranslation('translation');
  const isBookingUnitDays = useAppSelector(selectIsBookingUnitDays);
  const { allowToggle } = useDisplayTime();

  return (
    <>
      <Grid container>
        <Grid item>
          <Typography variant={'h6'}>{product.name}</Typography>
        </Grid>
        <Grid item sx={{ marginLeft: 1, color: 'gray' }}>
          {showExtraInfoIcon(product)}
        </Grid>
      </Grid>

      <Typography sx={{ fontSize: 'small' }}>
        <DisplayDates product={product} displayTime={allowToggle} />
      </Typography>

      {!!product.subTotal && product.quantity && (
        <Typography
          sx={{ fontSize: 'small' }}
          style={{ display: 'inline' }}
          component="span"
          variant="body2"
          key={buildRandomKey()}
        >
          {product.quantity}{' '}
          {isBookingUnitDays
            ? t('translation:day', { count: product.quantity })
            : ''}
          {` x ${formatPrice(product.price)} = ${formatPrice(
            product.subTotal
          )}`}
        </Typography>
      )}

      <Typography sx={{ fontSize: 'small', color: '#000', fontWeight: '700' }}>
        {product.count} {t('bike', { count: product.count })}
        {!!product.subTotal && (
          <>
            {' '}
            x {formatPrice(product.subTotal)} ={' '}
            {formatPrice(product.count * product.subTotal)}
          </>
        )}
      </Typography>
    </>
  );
}
