import { useEffect, useState } from 'react';

export function useCacheSelect<T>(
  key: string,
  asyncMethod: () => Promise<T>,
  cacheTimeMs: number = 5 * 60 * 1000
): T | null {
  const sessionKey = `.cache-${key}`.toLowerCase();
  const [data, setData] = useState<T | null>(null);

  useEffect(() => {
    const cachedData = localStorage.getItem(sessionKey);
    if (cachedData) {
      const { timestamp, data } = JSON.parse(cachedData);
      if (Date.now() - timestamp < cacheTimeMs) {
        setData(data);
        return;
      }
    }

    asyncMethod().then((result) => {
      setData(result);
      localStorage.setItem(
        sessionKey,
        JSON.stringify({ timestamp: Date.now(), data: result })
      );
    });
  }, [key]);

  return data;
}
