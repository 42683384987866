import { Box, Card, CardHeader, Grid } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Chart, useChart } from '../../components/chart';
import { formatPrice } from '../../core/Helpers/helper';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  generatePeriodLast3Months,
  generatePeriodLastMonth,
  generatePeriodLastWeek,
  generatePeriodNext3Months,
  generatePeriodNextMonth,
  generatePeriodNextWeek,
  generatePeriodThisMonth,
  generatePeriodThisWeek,
  generatePeriodThisYear,
  generatePeriodToday,
  generatePeriodTomorrow,
  generatePeriodYesterday,
  Period
} from './kpiFilters/Period';
import { Refresh } from './kpiFilters/Refresh';
import {
  fetchKpiRevenue,
  IKpiPeriod,
  selectIsLoadingRevenue,
  selectRevenue,
  selectRevenueFilterPeriod,
  setRevenueFilterPeriod
} from './kpiSlice';

export function RevenueChar({ title }: { title: JSX.Element }) {
  const { t } = useTranslation('translation');
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectIsLoadingRevenue);
  useEffect(() => {
    dispatch(fetchKpiRevenue());
  }, [dispatch]);

  const usages = useAppSelector(selectRevenue);
  const selectedPeriod = useAppSelector(selectRevenueFilterPeriod);

  const chartOptions = useChart({
    xaxis: {
      categories: usages.categories,
      tickAmount: 15
    },
    yaxis: {
      min: 0,
      max: usages.max,
      labels: {
        formatter: formatPrice
      }
    }
  });

  const periods: IKpiPeriod[] = [
    generatePeriodLast3Months(),
    generatePeriodLastMonth(),
    generatePeriodLastWeek(),
    generatePeriodYesterday(),
    generatePeriodToday(),
    generatePeriodTomorrow(),
    generatePeriodThisWeek(),
    generatePeriodThisMonth(),
    generatePeriodNextWeek(),
    generatePeriodNextMonth(),
    generatePeriodNext3Months(),
    generatePeriodThisYear()
  ];

  const selectedPeriodCalc = periods.find((p) => p.key === selectedPeriod?.key);

  return (
    <Card>
      <CardHeader
        title={title}
        subheader={selectedPeriodCalc?.label}
        action={
          <Grid
            container
            mb={1}
            spacing={1}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Refresh
              isLoading={isLoading}
              onClick={() => dispatch(fetchKpiRevenue())}
            />
            <Period
              setPeriod={(period) => {
                console.log('period: ', period);
                dispatch(setRevenueFilterPeriod(period));
              }}
              selectedPeriod={selectedPeriod}
              periods={periods}
            />
          </Grid>
        }
      />

      <Box sx={{ mt: 3, mx: 3 }} dir="ltr">
        <Chart
          type="line"
          series={usages.values}
          options={chartOptions}
          height={364}
        />
      </Box>
    </Card>
  );
}
