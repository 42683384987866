import { Drawer, Hidden, SwipeableDrawer } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { drawerWidth } from '../../../styleVariables';
import { ISidebarItem } from './SidebarItem';
import { SidebarItems } from './SidebarItems';

// @ts-ignore
// const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

interface SidebarProps {
  opened: boolean;
  toggleDrawer: () => void;
  config: ISidebarItem[];
}

export const Sidebar = ({ opened, toggleDrawer, config }: SidebarProps) => {
  const classes = useStyles();

  return (
    <>
      <Hidden mdDown>
        <Drawer
          variant="persistent"
          classes={{
            paper: classes.drawerPaper
          }}
          open={opened}
          ModalProps={{
            keepMounted: false,
            className: classes.modal,
            BackdropProps: {
              className: classes.backdrop
            },
            onBackdropClick: toggleDrawer
          }}
        >
          <SidebarItems items={config} closeDrawer={() => {}} />
        </Drawer>
      </Hidden>
      <Hidden mdUp>
        <SwipeableDrawer
          variant="temporary"
          classes={{
            paper: classes.drawerPaper
          }}
          open={opened}
          onClose={toggleDrawer}
          onOpen={toggleDrawer}
          disableBackdropTransition={!iOS}
          ModalProps={{
            keepMounted: false,
            className: classes.modal,
            BackdropProps: {
              className: classes.backdrop
            },
            onBackdropClick: toggleDrawer
          }}
        >
          <SidebarItems items={config} closeDrawer={toggleDrawer} />
        </SwipeableDrawer>
      </Hidden>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    position: 'relative',
    width: drawerWidth,
    maxWidth: drawerWidth,
    height: '100%',
    zIndex: theme.zIndex.drawer + 99
  },
  modal: {
    [theme.breakpoints.down('md')]: {
      top: '56px!important'
    },
    [theme.breakpoints.up('sm')]: {
      top: '64px!important'
    },
    zIndex: 1000
  },
  backdrop: {
    [theme.breakpoints.down('md')]: {
      top: '56px'
    },
    [theme.breakpoints.up('sm')]: {
      top: '64px'
    }
  }
}));
