import { useState }  from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import makeStyles from '@mui/styles/makeStyles';

import countries from "i18n-iso-countries";
// Import the languages you want to use
import enLocale from "i18n-iso-countries/langs/en.json";
import deLocale from "i18n-iso-countries/langs/de.json";
import nlLocale from "i18n-iso-countries/langs/nl.json";

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useLanguage } from '../store/LanguageProvider';

const useStyles = makeStyles((theme) => ({
  card: {
    overflow: 'visible'
  },
  customerInput: {
    width: '100%'
  },
  figureImg: {
    width: '90px',
    marginRight: '30px',
    border: '1px solid #000'
  }
}));

export function CountryDropdownI18n (props:
    {
        id: String;
        className: string;
        onChange: (selectedContryCode: String) => void;
        selectedCountry: String;
    }) {
    const classes = useStyles();
  
    const { t } = useTranslation('translation');
    const { language } = useLanguage();
  
    countries.registerLocale(enLocale);
    countries.registerLocale(deLocale);
    countries.registerLocale(nlLocale);

    // Returns an object not a list
    const countryObj = countries.getNames(language, { select: "official" });

    const countryArr = Object.entries(countryObj).map(([key, value]) => {
        return {
        label: value,
        value: key
        };
    });

    return (
          <FormControl>
            <InputLabel id="demo-simple-select-label">{(t('translation:country'))}</InputLabel>
            <Select
                id="country_select"
                required
                label={(t('translation:country'))}
                labelId='demo-simple-select-label'
                className={props.className}
                style={{ width: "24em" }}
                value={props.selectedCountry}
                onChange={(e) => props.onChange(e.target.value)}
            >
                {!!countryArr?.length &&
                countryArr.map(({ label, value }) => (
                    <MenuItem key={value} value={value}>
                    {label}
                    </MenuItem>
                ))}
            </Select>
          </FormControl>
      );
    }