import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './index';
import { InitRedux } from './InitRedux';

export function ReduxProvider({ children }) {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <InitRedux>{children}</InitRedux>
      </PersistGate>
    </Provider>
  );
}
