import { StyledEngineProvider } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { jssPreset, StylesProvider } from '@mui/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { create } from 'jss';
import rtl from 'jss-rtl';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/nl';
import { createContext, useContext, useEffect, useReducer } from 'react';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { useLanguage } from '../../store/LanguageProvider';
import ThemeProvider from '../theme';

const jss: any = create({ plugins: [...jssPreset().plugins, rtl()] });

const Context = createContext(null);
const { Provider } = Context;

const reducer = (state, action) => {
  switch (action.type) {
    case 'direction':
      const newState = {
        ...state,
        direction: state.direction === 'ltr' ? 'rtl' : 'ltr'
      };
      return newState;
    case 'type':
      return { ...state, type: state.type === 'light' ? 'dark' : 'light' };
    default:
      return state;
  }
};

const AppProvider = ({ children }) => {
  const prefersDarkMode = useMediaQuery('@media (prefers-color-scheme: dark)');
  const [state, dispatch] = useReducer(reducer, {
    type: prefersDarkMode ? 'dark' : 'light',
    direction: 'ltr'
  });
  const { language } = useLanguage();

  moment.locale(language);

  useEffect(() => {
    document.body.dir = state.direction;
  }, [state.direction]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider>
        <NotificationContainer />
        <StylesProvider jss={jss}>
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            adapterLocale={language}
          >
            <Provider value={[state, dispatch]}>{children}</Provider>
          </LocalizationProvider>
        </StylesProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default AppProvider;
export const useAppState = () => useContext(Context);
