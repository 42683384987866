import { Slider } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { displayNoDigits } from '../../../core/Helpers/helper';
import { OptionType } from './SelectPeriod';

export function PeriodDurationSlider(props: {
  onChange: (quantity: number) => void;
  options: OptionType[];
  quantity: number;
}) {
  const { t } = useTranslation('translation');
  const [localQ, setLocalQ] = useState(props.quantity);

  useEffect(() => {
    setLocalQ(props.quantity);
  }, [props.quantity]);

  const display = (o: OptionType) => {
    if (!o) return 'ERR';
    let text = '';

    if (o.d) {
      text += o.d + ' ' + t('day', { count: o.d }) + ' ';
    }

    if (o.h || o.m) {
      text += displayNoDigits(o.h) + ':' + displayNoDigits(o.m);
    }

    return text;
  };

  if (!props.quantity) return <></>;

  let min = null;
  let max = null;

  const marks = props.options.map((o) => {
    if (!min || min > o.quantity) {
      min = o.quantity;
    }

    if (!max || max < o.quantity) {
      max = o.quantity;
    }

    return {
      value: o.quantity,
      label: ''
    };
  });

  function valueLabelFormat(value: number) {
    const o = props.options.find((o) => o.quantity === value);
    return display(o);
  }

  const displayPeriod = () => {
    const o = props.options.find((o) => o.quantity === localQ);
    if (!o) return <></>;

    const items = [];

    if (o.d > 0) items.push(o.d + ' ' + t('day', { count: o.d }));
    if (o.h > 0) items.push(o.h + ' ' + t('hour', { count: o.h }));
    if (o.m > 0) items.push(o.m + ' ' + t('minute', { count: o.m }));

    return <>{items.join(', ')}</>;
  };

  return (
    <>
      <Slider
        key={'key' + props.quantity}
        sx={{ marginTop: 1 }}
        value={localQ}
        valueLabelFormat={valueLabelFormat}
        min={min}
        max={max}
        step={1}
        valueLabelDisplay={props.quantity > max ? 'off' : 'auto'}
        marks={marks}
        onChange={(event, value) => {
          if (value) {
            setLocalQ(Number(value));
          }
        }}
        onChangeCommitted={(event, value) => {
          if (value) {
            props.onChange(Number(value));
          }
        }}
      />
      {displayPeriod()}
    </>
  );
}
