import {
  FactCheck,
  Feedback,
  History,
  List,
  People
} from '@mui/icons-material';
import BikeScooterIcon from '@mui/icons-material/BikeScooter';
import ExploreIcon from '@mui/icons-material/Explore';
import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { isDepotManager } from '../../../feature/account/customerSlice';
import { CartDrawer } from '../../../feature/cart/CartDrawer';
import { selectOrganization } from '../../../feature/organization/organizationSlice';
import { useAppSelector } from '../../../hooks';
import { PATH_DASHBOARD } from '../../routes/paths';
import { Header } from './header/Header';
import Main from './Main';
import { Sidebar } from './nav/Sidebar';
import { ISidebarItem } from './nav/SidebarItem';

export function DashboardLayout() {
  const { t } = useTranslation('translation');
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [opened, setOpened] = useState(true);
  const [cartOpen, setCartOpen] = useState(false);
  const organization = useAppSelector(selectOrganization);
  const handleDrawerToggle = () => setOpened(!opened);
  const handleCartToggle = () => setCartOpen(!cartOpen);
  const isDM = useAppSelector(isDepotManager);

  useEffect(() => {
    setOpened(!isSmallScreen);
  }, [isSmallScreen]);

  if (!organization) {
    return <></>;
  }

  const sidebarItems: ISidebarItem[] = [
    {
      path: PATH_DASHBOARD.root,
      name: 'Home',
      type: 'link',
      icon: ExploreIcon
    }
  ];
  if (organization.supportReservations || (isDM && organization.supportAdminReservations)) {
    sidebarItems.push(    
      {
        path: PATH_DASHBOARD.products.travel,
        name: t('menu.makeReservation'),
        type: 'link',
        icon: BikeScooterIcon
      },
      {
        path: PATH_DASHBOARD.reservations,
        name: t('menu.allReservations'),
        type: 'link',
        icon: List
      }
    )
  }

  if (isDM && (organization.selfRegister || organization.adminRegister)) {
    sidebarItems.push({
      path: PATH_DASHBOARD.users,
      name: t('menu.manageUsers'),
      type: 'submenu',
      icon: People,
      children: [
        {
          path: PATH_DASHBOARD.users,
          name: t('menu.users'),
          type: 'link',
          icon: People
        },
        {
          path: PATH_DASHBOARD.usersWhiteList,
          name: t('menu.whitelist'),
          type: 'link',
          icon: FactCheck
        },
        {
          path: PATH_DASHBOARD.usersHistory,
          name: t('menu.History'),
          type: 'link',
          icon: History
        }
      ]
    });
  }

  if (isDM) {
    sidebarItems.push({
      path: PATH_DASHBOARD.feedback,
      name: t('menu.feedback'),
      type: 'link',
      icon: Feedback
    });
  }

  return (
    <>
      <Header toggleDrawer={handleDrawerToggle} toogleCart={handleCartToggle} />

      <Box sx={{ display: 'flex', height: '100%', pt: 8 }}>
        <Sidebar
          config={sidebarItems}
          opened={opened}
          toggleDrawer={handleDrawerToggle}
        />
        <Main opened={opened}>
          <Outlet />
        </Main>
        <CartDrawer cartOpen={cartOpen} toggleCart={handleCartToggle} />
      </Box>
    </>
  );
}
