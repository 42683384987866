/* tslint:disable */
/* eslint-disable */
/**
 * Shopizer REST API
 * API for Shopizer e-commerce. Contains public end points as well as private end points requiring basic authentication and remote authentication based on jwt bearer token. URL patterns containing /private/_** use bearer token; those are authorized customer and administrators administration actions.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
  Customer,
  Kpi,
  KpiRevenue,
  KpiUsage,
  PersistableOrder,
  ReadableOrder,
  ReadableOrderConfirmation,
  ReadableOrderList,
  ReadableOrderProductListToBeReplaced,
  ReadableReservationList,
  RedirectView,
  ReservationCancelResponse
} from '../models';
import * as runtime from '../runtime';

export interface CancelOrderProductUsingDELETERequest {
  orderProductId: number;
  accountNonExpired?: boolean;
  accountNonLocked?: boolean;
  authorities0Authority?: string;
  credentialsNonExpired?: boolean;
  email?: string;
  enabled?: boolean;
  firstName?: string;
  id?: number;
  lang?: string;
  lastName?: string;
  password?: string;
  username?: string;
}

export interface CheckoutUsingPOSTRequest {
  code: string;
  order: PersistableOrder;
  country?: string;
  displayCountry?: string;
  displayLanguage?: string;
  displayName?: string;
  displayScript?: string;
  displayVariant?: string;
  extensionKeys?: Array<string>;
  iSO3Country?: string;
  iSO3Language?: string;
  lang?: string;
  language?: string;
  script?: string;
  store?: string;
  unicodeLocaleAttributes?: Array<string>;
  unicodeLocaleKeys?: Array<string>;
  variant?: string;
}

export interface DoCheckoutUsingGETRequest {
  cartId: number;
  isLoadedInMoovlr?: boolean;
  token?: string;
}

export interface DoRetryPaymentUsingGETRequest {
  orderId: number;
  isLoadedInMoovlr?: boolean;
  token?: string;
}

export interface FinishOrderProductUsingPOSTRequest {
  orderProductId: number;
  accountNonExpired?: boolean;
  accountNonLocked?: boolean;
  authorities0Authority?: string;
  credentialsNonExpired?: boolean;
  email?: string;
  enabled?: boolean;
  firstName?: string;
  id?: number;
  lastName?: string;
  password?: string;
  username?: string;
}

export interface GetAdminReservationsUsingGETRequest {
  accountNonExpired?: boolean;
  accountNonLocked?: boolean;
  authorities0Authority?: string;
  count?: number;
  credentialsNonExpired?: boolean;
  email?: string;
  enabled?: boolean;
  filter?: string;
  firstName?: string;
  id?: number;
  lang?: string;
  lastName?: string;
  page?: number;
  password?: string;
  sortColumn?: string;
  sortOrder?: string;
  username?: string;
}

export interface GetCustomerShoppingHistoryNewUsingGETRequest {
  accountNonExpired?: boolean;
  accountNonLocked?: boolean;
  authorities0Authority?: string;
  count?: number;
  credentialsNonExpired?: boolean;
  email?: string;
  enabled?: boolean;
  filter?: string;
  firstName?: string;
  id?: number;
  lang?: string;
  lastName?: string;
  page?: number;
  password?: string;
  sortColumn?: string;
  sortOrder?: string;
  username?: string;
}

export interface GetCustomerShoppingHistoryUsingGETRequest {
  accountNonExpired?: boolean;
  accountNonLocked?: boolean;
  authorities0Authority?: string;
  count?: number;
  credentialsNonExpired?: boolean;
  email?: string;
  enabled?: boolean;
  firstName?: string;
  id?: number;
  lang?: string;
  lastName?: string;
  page?: number;
  password?: string;
  username?: string;
}

export interface GetKpisUsingGETRequest {
  accountNonExpired?: boolean;
  accountNonLocked?: boolean;
  asset?: string;
  assetSku?: string;
  authorities0Authority?: string;
  credentialsNonExpired?: boolean;
  email?: string;
  enabled?: boolean;
  firstName?: string;
  from?: string;
  id?: number;
  lang?: string;
  lastName?: string;
  password?: string;
  storeCode?: string;
  to?: string;
  username?: string;
}

export interface GetOrderUsingGETRequest {
  id: number;
  lang?: string;
  store?: string;
}

export interface GetRevenueKpisUsingGETRequest {
  accountNonExpired?: boolean;
  accountNonLocked?: boolean;
  authorities0Authority?: string;
  credentialsNonExpired?: boolean;
  email?: string;
  enabled?: boolean;
  firstName?: string;
  from?: string;
  id?: number;
  lang?: string;
  lastName?: string;
  password?: string;
  to?: string;
  username?: string;
}

export interface GetUsageKpisUsingGETRequest {
  accountNonExpired?: boolean;
  accountNonLocked?: boolean;
  authorities0Authority?: string;
  credentialsNonExpired?: boolean;
  email?: string;
  enabled?: boolean;
  firstName?: string;
  from?: string;
  id?: number;
  lang?: string;
  lastName?: string;
  password?: string;
  to?: string;
  username?: string;
}

export interface GetUsingGET2Request {
  id: number;
  lang?: string;
  store?: string;
}

export interface ListUsingGET3Request {
  count?: number;
  lang?: string;
  page?: number;
  store?: string;
}

export interface ListUsingGET4Request {
  count?: number;
  email?: string;
  id?: number;
  name?: string;
  page?: number;
  phone?: string;
  status?: string;
}

export interface ListUsingGET5Request {
  id: number;
  count?: number;
  lang?: string;
  start?: number;
  store?: string;
}

export interface OrderUsingGETRequest {
  sessionId: string;
  accountNonExpired?: boolean;
  accountNonLocked?: boolean;
  authorities0Authority?: string;
  credentialsNonExpired?: boolean;
  email?: string;
  enabled?: boolean;
  firstName?: string;
  id?: number;
  lang?: string;
  lastName?: string;
  password?: string;
  store?: string;
  username?: string;
}

export interface ReservationDescUsingPOSTRequest {
  description: string;
  lang: string;
  status?: ReservationDescUsingPOSTStatusEnum;
  store?: string;
}

export interface StatusTranslationUsingPOSTRequest {
  inputLanguage: string;
  targetLanguage: string;
  translationType: string;
  store?: string;
  text?: string;
}

export interface UpdateOrderCustomerUsingPATCHRequest {
  id: number;
  orderCustomer: Customer;
  lang?: string;
  store?: string;
}

export interface UpdateOrderStatusUsingPUTRequest {
  id: number;
  status: string;
  lang?: string;
  store?: string;
}

/**
 *
 */
export class OrderingApiOrderFlowApiApi extends runtime.BaseAPI {
  /**
   * cancelOrderProduct
   */
  async cancelOrderProductUsingDELETERaw(
    requestParameters: CancelOrderProductUsingDELETERequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ReservationCancelResponse>> {
    if (
      requestParameters.orderProductId === null ||
      requestParameters.orderProductId === undefined
    ) {
      throw new runtime.RequiredError(
        'orderProductId',
        'Required parameter requestParameters.orderProductId was null or undefined when calling cancelOrderProductUsingDELETE.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.accountNonExpired !== undefined) {
      queryParameters['accountNonExpired'] =
        requestParameters.accountNonExpired;
    }

    if (requestParameters.accountNonLocked !== undefined) {
      queryParameters['accountNonLocked'] = requestParameters.accountNonLocked;
    }

    if (requestParameters.authorities0Authority !== undefined) {
      queryParameters['authorities[0].authority'] =
        requestParameters.authorities0Authority;
    }

    if (requestParameters.credentialsNonExpired !== undefined) {
      queryParameters['credentialsNonExpired'] =
        requestParameters.credentialsNonExpired;
    }

    if (requestParameters.email !== undefined) {
      queryParameters['email'] = requestParameters.email;
    }

    if (requestParameters.enabled !== undefined) {
      queryParameters['enabled'] = requestParameters.enabled;
    }

    if (requestParameters.firstName !== undefined) {
      queryParameters['firstName'] = requestParameters.firstName;
    }

    if (requestParameters.id !== undefined) {
      queryParameters['id'] = requestParameters.id;
    }

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    if (requestParameters.lastName !== undefined) {
      queryParameters['lastName'] = requestParameters.lastName;
    }

    if (requestParameters.password !== undefined) {
      queryParameters['password'] = requestParameters.password;
    }

    if (requestParameters.username !== undefined) {
      queryParameters['username'] = requestParameters.username;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/orderproduct/{orderProductId}/cancel`.replace(
          `{${'orderProductId'}}`,
          encodeURIComponent(String(requestParameters.orderProductId))
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * cancelOrderProduct
   */
  async cancelOrderProductUsingDELETE(
    requestParameters: CancelOrderProductUsingDELETERequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ReservationCancelResponse> {
    const response = await this.cancelOrderProductUsingDELETERaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * checkout
   */
  async checkoutUsingPOSTRaw(
    requestParameters: CheckoutUsingPOSTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ReadableOrderConfirmation>> {
    if (
      requestParameters.code === null ||
      requestParameters.code === undefined
    ) {
      throw new runtime.RequiredError(
        'code',
        'Required parameter requestParameters.code was null or undefined when calling checkoutUsingPOST.'
      );
    }

    if (
      requestParameters.order === null ||
      requestParameters.order === undefined
    ) {
      throw new runtime.RequiredError(
        'order',
        'Required parameter requestParameters.order was null or undefined when calling checkoutUsingPOST.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.country !== undefined) {
      queryParameters['country'] = requestParameters.country;
    }

    if (requestParameters.displayCountry !== undefined) {
      queryParameters['displayCountry'] = requestParameters.displayCountry;
    }

    if (requestParameters.displayLanguage !== undefined) {
      queryParameters['displayLanguage'] = requestParameters.displayLanguage;
    }

    if (requestParameters.displayName !== undefined) {
      queryParameters['displayName'] = requestParameters.displayName;
    }

    if (requestParameters.displayScript !== undefined) {
      queryParameters['displayScript'] = requestParameters.displayScript;
    }

    if (requestParameters.displayVariant !== undefined) {
      queryParameters['displayVariant'] = requestParameters.displayVariant;
    }

    if (requestParameters.extensionKeys) {
      queryParameters['extensionKeys'] = requestParameters.extensionKeys;
    }

    if (requestParameters.iSO3Country !== undefined) {
      queryParameters['ISO3Country'] = requestParameters.iSO3Country;
    }

    if (requestParameters.iSO3Language !== undefined) {
      queryParameters['ISO3Language'] = requestParameters.iSO3Language;
    }

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    if (requestParameters.language !== undefined) {
      queryParameters['language'] = requestParameters.language;
    }

    if (requestParameters.script !== undefined) {
      queryParameters['script'] = requestParameters.script;
    }

    if (requestParameters.store !== undefined) {
      queryParameters['store'] = requestParameters.store;
    }

    if (requestParameters.unicodeLocaleAttributes) {
      queryParameters['unicodeLocaleAttributes'] =
        requestParameters.unicodeLocaleAttributes;
    }

    if (requestParameters.unicodeLocaleKeys) {
      queryParameters['unicodeLocaleKeys'] =
        requestParameters.unicodeLocaleKeys;
    }

    if (requestParameters.variant !== undefined) {
      queryParameters['variant'] = requestParameters.variant;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/auth/cart/{code}/checkout`.replace(
          `{${'code'}}`,
          encodeURIComponent(String(requestParameters.code))
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.order
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * checkout
   */
  async checkoutUsingPOST(
    requestParameters: CheckoutUsingPOSTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ReadableOrderConfirmation> {
    const response = await this.checkoutUsingPOSTRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * doCheckout
   */
  async doCheckoutUsingGETRaw(
    requestParameters: DoCheckoutUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<RedirectView>> {
    if (
      requestParameters.cartId === null ||
      requestParameters.cartId === undefined
    ) {
      throw new runtime.RequiredError(
        'cartId',
        'Required parameter requestParameters.cartId was null or undefined when calling doCheckoutUsingGET.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.isLoadedInMoovlr !== undefined) {
      queryParameters['isLoadedInMoovlr'] = requestParameters.isLoadedInMoovlr;
    }

    if (requestParameters.token !== undefined) {
      queryParameters['token'] = requestParameters.token;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/checkout/cart/{cartId}`.replace(
          `{${'cartId'}}`,
          encodeURIComponent(String(requestParameters.cartId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * doCheckout
   */
  async doCheckoutUsingGET(
    requestParameters: DoCheckoutUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<RedirectView> {
    const response = await this.doCheckoutUsingGETRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * doRetryPayment
   */
  async doRetryPaymentUsingGETRaw(
    requestParameters: DoRetryPaymentUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<RedirectView>> {
    if (
      requestParameters.orderId === null ||
      requestParameters.orderId === undefined
    ) {
      throw new runtime.RequiredError(
        'orderId',
        'Required parameter requestParameters.orderId was null or undefined when calling doRetryPaymentUsingGET.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.isLoadedInMoovlr !== undefined) {
      queryParameters['isLoadedInMoovlr'] = requestParameters.isLoadedInMoovlr;
    }

    if (requestParameters.token !== undefined) {
      queryParameters['token'] = requestParameters.token;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/checkout/cart/retryPayment/{orderId}`.replace(
          `{${'orderId'}}`,
          encodeURIComponent(String(requestParameters.orderId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * doRetryPayment
   */
  async doRetryPaymentUsingGET(
    requestParameters: DoRetryPaymentUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<RedirectView> {
    const response = await this.doRetryPaymentUsingGETRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * finishOrderProduct
   */
  async finishOrderProductUsingPOSTRaw(
    requestParameters: FinishOrderProductUsingPOSTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.orderProductId === null ||
      requestParameters.orderProductId === undefined
    ) {
      throw new runtime.RequiredError(
        'orderProductId',
        'Required parameter requestParameters.orderProductId was null or undefined when calling finishOrderProductUsingPOST.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.accountNonExpired !== undefined) {
      queryParameters['accountNonExpired'] =
        requestParameters.accountNonExpired;
    }

    if (requestParameters.accountNonLocked !== undefined) {
      queryParameters['accountNonLocked'] = requestParameters.accountNonLocked;
    }

    if (requestParameters.authorities0Authority !== undefined) {
      queryParameters['authorities[0].authority'] =
        requestParameters.authorities0Authority;
    }

    if (requestParameters.credentialsNonExpired !== undefined) {
      queryParameters['credentialsNonExpired'] =
        requestParameters.credentialsNonExpired;
    }

    if (requestParameters.email !== undefined) {
      queryParameters['email'] = requestParameters.email;
    }

    if (requestParameters.enabled !== undefined) {
      queryParameters['enabled'] = requestParameters.enabled;
    }

    if (requestParameters.firstName !== undefined) {
      queryParameters['firstName'] = requestParameters.firstName;
    }

    if (requestParameters.id !== undefined) {
      queryParameters['id'] = requestParameters.id;
    }

    if (requestParameters.lastName !== undefined) {
      queryParameters['lastName'] = requestParameters.lastName;
    }

    if (requestParameters.password !== undefined) {
      queryParameters['password'] = requestParameters.password;
    }

    if (requestParameters.username !== undefined) {
      queryParameters['username'] = requestParameters.username;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/orderproduct/{orderProductId}/finish`.replace(
          `{${'orderProductId'}}`,
          encodeURIComponent(String(requestParameters.orderProductId))
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * finishOrderProduct
   */
  async finishOrderProductUsingPOST(
    requestParameters: FinishOrderProductUsingPOSTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.finishOrderProductUsingPOSTRaw(requestParameters, initOverrides);
  }

  /**
   * getAdminReservations
   */
  async getAdminReservationsUsingGETRaw(
    requestParameters: GetAdminReservationsUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ReadableReservationList>> {
    const queryParameters: any = {};

    if (requestParameters.accountNonExpired !== undefined) {
      queryParameters['accountNonExpired'] =
        requestParameters.accountNonExpired;
    }

    if (requestParameters.accountNonLocked !== undefined) {
      queryParameters['accountNonLocked'] = requestParameters.accountNonLocked;
    }

    if (requestParameters.authorities0Authority !== undefined) {
      queryParameters['authorities[0].authority'] =
        requestParameters.authorities0Authority;
    }

    if (requestParameters.count !== undefined) {
      queryParameters['count'] = requestParameters.count;
    }

    if (requestParameters.credentialsNonExpired !== undefined) {
      queryParameters['credentialsNonExpired'] =
        requestParameters.credentialsNonExpired;
    }

    if (requestParameters.email !== undefined) {
      queryParameters['email'] = requestParameters.email;
    }

    if (requestParameters.enabled !== undefined) {
      queryParameters['enabled'] = requestParameters.enabled;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters['filter'] = requestParameters.filter;
    }

    if (requestParameters.firstName !== undefined) {
      queryParameters['firstName'] = requestParameters.firstName;
    }

    if (requestParameters.id !== undefined) {
      queryParameters['id'] = requestParameters.id;
    }

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    if (requestParameters.lastName !== undefined) {
      queryParameters['lastName'] = requestParameters.lastName;
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page;
    }

    if (requestParameters.password !== undefined) {
      queryParameters['password'] = requestParameters.password;
    }

    if (requestParameters.sortColumn !== undefined) {
      queryParameters['sortColumn'] = requestParameters.sortColumn;
    }

    if (requestParameters.sortOrder !== undefined) {
      queryParameters['sortOrder'] = requestParameters.sortOrder;
    }

    if (requestParameters.username !== undefined) {
      queryParameters['username'] = requestParameters.username;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/admin/reservations`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * getAdminReservations
   */
  async getAdminReservationsUsingGET(
    requestParameters: GetAdminReservationsUsingGETRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ReadableReservationList> {
    const response = await this.getAdminReservationsUsingGETRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * getCustomerShoppingHistory_new
   */
  async getCustomerShoppingHistoryNewUsingGETRaw(
    requestParameters: GetCustomerShoppingHistoryNewUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ReadableReservationList>> {
    const queryParameters: any = {};

    if (requestParameters.accountNonExpired !== undefined) {
      queryParameters['accountNonExpired'] =
        requestParameters.accountNonExpired;
    }

    if (requestParameters.accountNonLocked !== undefined) {
      queryParameters['accountNonLocked'] = requestParameters.accountNonLocked;
    }

    if (requestParameters.authorities0Authority !== undefined) {
      queryParameters['authorities[0].authority'] =
        requestParameters.authorities0Authority;
    }

    if (requestParameters.count !== undefined) {
      queryParameters['count'] = requestParameters.count;
    }

    if (requestParameters.credentialsNonExpired !== undefined) {
      queryParameters['credentialsNonExpired'] =
        requestParameters.credentialsNonExpired;
    }

    if (requestParameters.email !== undefined) {
      queryParameters['email'] = requestParameters.email;
    }

    if (requestParameters.enabled !== undefined) {
      queryParameters['enabled'] = requestParameters.enabled;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters['filter'] = requestParameters.filter;
    }

    if (requestParameters.firstName !== undefined) {
      queryParameters['firstName'] = requestParameters.firstName;
    }

    if (requestParameters.id !== undefined) {
      queryParameters['id'] = requestParameters.id;
    }

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    if (requestParameters.lastName !== undefined) {
      queryParameters['lastName'] = requestParameters.lastName;
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page;
    }

    if (requestParameters.password !== undefined) {
      queryParameters['password'] = requestParameters.password;
    }

    if (requestParameters.sortColumn !== undefined) {
      queryParameters['sortColumn'] = requestParameters.sortColumn;
    }

    if (requestParameters.sortOrder !== undefined) {
      queryParameters['sortOrder'] = requestParameters.sortOrder;
    }

    if (requestParameters.username !== undefined) {
      queryParameters['username'] = requestParameters.username;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/order/history/new`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * getCustomerShoppingHistory_new
   */
  async getCustomerShoppingHistoryNewUsingGET(
    requestParameters: GetCustomerShoppingHistoryNewUsingGETRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ReadableReservationList> {
    const response = await this.getCustomerShoppingHistoryNewUsingGETRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * getCustomerShoppingHistory
   */
  async getCustomerShoppingHistoryUsingGETRaw(
    requestParameters: GetCustomerShoppingHistoryUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ReadableOrderProductListToBeReplaced>> {
    const queryParameters: any = {};

    if (requestParameters.accountNonExpired !== undefined) {
      queryParameters['accountNonExpired'] =
        requestParameters.accountNonExpired;
    }

    if (requestParameters.accountNonLocked !== undefined) {
      queryParameters['accountNonLocked'] = requestParameters.accountNonLocked;
    }

    if (requestParameters.authorities0Authority !== undefined) {
      queryParameters['authorities[0].authority'] =
        requestParameters.authorities0Authority;
    }

    if (requestParameters.count !== undefined) {
      queryParameters['count'] = requestParameters.count;
    }

    if (requestParameters.credentialsNonExpired !== undefined) {
      queryParameters['credentialsNonExpired'] =
        requestParameters.credentialsNonExpired;
    }

    if (requestParameters.email !== undefined) {
      queryParameters['email'] = requestParameters.email;
    }

    if (requestParameters.enabled !== undefined) {
      queryParameters['enabled'] = requestParameters.enabled;
    }

    if (requestParameters.firstName !== undefined) {
      queryParameters['firstName'] = requestParameters.firstName;
    }

    if (requestParameters.id !== undefined) {
      queryParameters['id'] = requestParameters.id;
    }

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    if (requestParameters.lastName !== undefined) {
      queryParameters['lastName'] = requestParameters.lastName;
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page;
    }

    if (requestParameters.password !== undefined) {
      queryParameters['password'] = requestParameters.password;
    }

    if (requestParameters.username !== undefined) {
      queryParameters['username'] = requestParameters.username;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/order/history`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * getCustomerShoppingHistory
   */
  async getCustomerShoppingHistoryUsingGET(
    requestParameters: GetCustomerShoppingHistoryUsingGETRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ReadableOrderProductListToBeReplaced> {
    const response = await this.getCustomerShoppingHistoryUsingGETRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * getKpis
   */
  async getKpisUsingGETRaw(
    requestParameters: GetKpisUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Kpi>> {
    const queryParameters: any = {};

    if (requestParameters.accountNonExpired !== undefined) {
      queryParameters['accountNonExpired'] =
        requestParameters.accountNonExpired;
    }

    if (requestParameters.accountNonLocked !== undefined) {
      queryParameters['accountNonLocked'] = requestParameters.accountNonLocked;
    }

    if (requestParameters.asset !== undefined) {
      queryParameters['asset'] = requestParameters.asset;
    }

    if (requestParameters.assetSku !== undefined) {
      queryParameters['assetSku'] = requestParameters.assetSku;
    }

    if (requestParameters.authorities0Authority !== undefined) {
      queryParameters['authorities[0].authority'] =
        requestParameters.authorities0Authority;
    }

    if (requestParameters.credentialsNonExpired !== undefined) {
      queryParameters['credentialsNonExpired'] =
        requestParameters.credentialsNonExpired;
    }

    if (requestParameters.email !== undefined) {
      queryParameters['email'] = requestParameters.email;
    }

    if (requestParameters.enabled !== undefined) {
      queryParameters['enabled'] = requestParameters.enabled;
    }

    if (requestParameters.firstName !== undefined) {
      queryParameters['firstName'] = requestParameters.firstName;
    }

    if (requestParameters.from !== undefined) {
      queryParameters['from'] = requestParameters.from;
    }

    if (requestParameters.id !== undefined) {
      queryParameters['id'] = requestParameters.id;
    }

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    if (requestParameters.lastName !== undefined) {
      queryParameters['lastName'] = requestParameters.lastName;
    }

    if (requestParameters.password !== undefined) {
      queryParameters['password'] = requestParameters.password;
    }

    if (requestParameters.storeCode !== undefined) {
      queryParameters['storeCode'] = requestParameters.storeCode;
    }

    if (requestParameters.to !== undefined) {
      queryParameters['to'] = requestParameters.to;
    }

    if (requestParameters.username !== undefined) {
      queryParameters['username'] = requestParameters.username;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/admin/kpis`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * getKpis
   */
  async getKpisUsingGET(
    requestParameters: GetKpisUsingGETRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Kpi> {
    const response = await this.getKpisUsingGETRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * getOrder
   */
  async getOrderUsingGETRaw(
    requestParameters: GetOrderUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ReadableOrder>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getOrderUsingGET.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    if (requestParameters.store !== undefined) {
      queryParameters['store'] = requestParameters.store;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/auth/orders/{id}`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * getOrder
   */
  async getOrderUsingGET(
    requestParameters: GetOrderUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ReadableOrder> {
    const response = await this.getOrderUsingGETRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * getRevenueKpis
   */
  async getRevenueKpisUsingGETRaw(
    requestParameters: GetRevenueKpisUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<KpiRevenue>>> {
    const queryParameters: any = {};

    if (requestParameters.accountNonExpired !== undefined) {
      queryParameters['accountNonExpired'] =
        requestParameters.accountNonExpired;
    }

    if (requestParameters.accountNonLocked !== undefined) {
      queryParameters['accountNonLocked'] = requestParameters.accountNonLocked;
    }

    if (requestParameters.authorities0Authority !== undefined) {
      queryParameters['authorities[0].authority'] =
        requestParameters.authorities0Authority;
    }

    if (requestParameters.credentialsNonExpired !== undefined) {
      queryParameters['credentialsNonExpired'] =
        requestParameters.credentialsNonExpired;
    }

    if (requestParameters.email !== undefined) {
      queryParameters['email'] = requestParameters.email;
    }

    if (requestParameters.enabled !== undefined) {
      queryParameters['enabled'] = requestParameters.enabled;
    }

    if (requestParameters.firstName !== undefined) {
      queryParameters['firstName'] = requestParameters.firstName;
    }

    if (requestParameters.from !== undefined) {
      queryParameters['from'] = requestParameters.from;
    }

    if (requestParameters.id !== undefined) {
      queryParameters['id'] = requestParameters.id;
    }

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    if (requestParameters.lastName !== undefined) {
      queryParameters['lastName'] = requestParameters.lastName;
    }

    if (requestParameters.password !== undefined) {
      queryParameters['password'] = requestParameters.password;
    }

    if (requestParameters.to !== undefined) {
      queryParameters['to'] = requestParameters.to;
    }

    if (requestParameters.username !== undefined) {
      queryParameters['username'] = requestParameters.username;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/admin/kpis/revenue`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * getRevenueKpis
   */
  async getRevenueKpisUsingGET(
    requestParameters: GetRevenueKpisUsingGETRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<KpiRevenue>> {
    const response = await this.getRevenueKpisUsingGETRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * getUsageKpis
   */
  async getUsageKpisUsingGETRaw(
    requestParameters: GetUsageKpisUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<KpiUsage>>> {
    const queryParameters: any = {};

    if (requestParameters.accountNonExpired !== undefined) {
      queryParameters['accountNonExpired'] =
        requestParameters.accountNonExpired;
    }

    if (requestParameters.accountNonLocked !== undefined) {
      queryParameters['accountNonLocked'] = requestParameters.accountNonLocked;
    }

    if (requestParameters.authorities0Authority !== undefined) {
      queryParameters['authorities[0].authority'] =
        requestParameters.authorities0Authority;
    }

    if (requestParameters.credentialsNonExpired !== undefined) {
      queryParameters['credentialsNonExpired'] =
        requestParameters.credentialsNonExpired;
    }

    if (requestParameters.email !== undefined) {
      queryParameters['email'] = requestParameters.email;
    }

    if (requestParameters.enabled !== undefined) {
      queryParameters['enabled'] = requestParameters.enabled;
    }

    if (requestParameters.firstName !== undefined) {
      queryParameters['firstName'] = requestParameters.firstName;
    }

    if (requestParameters.from !== undefined) {
      queryParameters['from'] = requestParameters.from;
    }

    if (requestParameters.id !== undefined) {
      queryParameters['id'] = requestParameters.id;
    }

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    if (requestParameters.lastName !== undefined) {
      queryParameters['lastName'] = requestParameters.lastName;
    }

    if (requestParameters.password !== undefined) {
      queryParameters['password'] = requestParameters.password;
    }

    if (requestParameters.to !== undefined) {
      queryParameters['to'] = requestParameters.to;
    }

    if (requestParameters.username !== undefined) {
      queryParameters['username'] = requestParameters.username;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/admin/kpis/usage`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * getUsageKpis
   */
  async getUsageKpisUsingGET(
    requestParameters: GetUsageKpisUsingGETRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<KpiUsage>> {
    const response = await this.getUsageKpisUsingGETRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * get
   */
  async getUsingGET2Raw(
    requestParameters: GetUsingGET2Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ReadableOrder>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getUsingGET2.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    if (requestParameters.store !== undefined) {
      queryParameters['store'] = requestParameters.store;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/private/orders/{id}`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * get
   */
  async getUsingGET2(
    requestParameters: GetUsingGET2Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ReadableOrder> {
    const response = await this.getUsingGET2Raw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * list
   */
  async listUsingGET3Raw(
    requestParameters: ListUsingGET3Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ReadableOrderList>> {
    const queryParameters: any = {};

    if (requestParameters.count !== undefined) {
      queryParameters['count'] = requestParameters.count;
    }

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page;
    }

    if (requestParameters.store !== undefined) {
      queryParameters['store'] = requestParameters.store;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/auth/orders`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * list
   */
  async listUsingGET3(
    requestParameters: ListUsingGET3Request = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ReadableOrderList> {
    const response = await this.listUsingGET3Raw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * list
   */
  async listUsingGET4Raw(
    requestParameters: ListUsingGET4Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ReadableOrderList>> {
    const queryParameters: any = {};

    if (requestParameters.count !== undefined) {
      queryParameters['count'] = requestParameters.count;
    }

    if (requestParameters.email !== undefined) {
      queryParameters['email'] = requestParameters.email;
    }

    if (requestParameters.id !== undefined) {
      queryParameters['id'] = requestParameters.id;
    }

    if (requestParameters.name !== undefined) {
      queryParameters['name'] = requestParameters.name;
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page;
    }

    if (requestParameters.phone !== undefined) {
      queryParameters['phone'] = requestParameters.phone;
    }

    if (requestParameters.status !== undefined) {
      queryParameters['status'] = requestParameters.status;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/private/orders`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * list
   */
  async listUsingGET4(
    requestParameters: ListUsingGET4Request = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ReadableOrderList> {
    const response = await this.listUsingGET4Raw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * list
   */
  async listUsingGET5Raw(
    requestParameters: ListUsingGET5Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ReadableOrderList>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling listUsingGET5.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.count !== undefined) {
      queryParameters['count'] = requestParameters.count;
    }

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    if (requestParameters.start !== undefined) {
      queryParameters['start'] = requestParameters.start;
    }

    if (requestParameters.store !== undefined) {
      queryParameters['store'] = requestParameters.store;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/private/orders/customers/{id}`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * list
   */
  async listUsingGET5(
    requestParameters: ListUsingGET5Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ReadableOrderList> {
    const response = await this.listUsingGET5Raw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * order
   */
  async orderUsingGETRaw(
    requestParameters: OrderUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ReadableOrderConfirmation>> {
    if (
      requestParameters.sessionId === null ||
      requestParameters.sessionId === undefined
    ) {
      throw new runtime.RequiredError(
        'sessionId',
        'Required parameter requestParameters.sessionId was null or undefined when calling orderUsingGET.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.accountNonExpired !== undefined) {
      queryParameters['accountNonExpired'] =
        requestParameters.accountNonExpired;
    }

    if (requestParameters.accountNonLocked !== undefined) {
      queryParameters['accountNonLocked'] = requestParameters.accountNonLocked;
    }

    if (requestParameters.authorities0Authority !== undefined) {
      queryParameters['authorities[0].authority'] =
        requestParameters.authorities0Authority;
    }

    if (requestParameters.credentialsNonExpired !== undefined) {
      queryParameters['credentialsNonExpired'] =
        requestParameters.credentialsNonExpired;
    }

    if (requestParameters.email !== undefined) {
      queryParameters['email'] = requestParameters.email;
    }

    if (requestParameters.enabled !== undefined) {
      queryParameters['enabled'] = requestParameters.enabled;
    }

    if (requestParameters.firstName !== undefined) {
      queryParameters['firstName'] = requestParameters.firstName;
    }

    if (requestParameters.id !== undefined) {
      queryParameters['id'] = requestParameters.id;
    }

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    if (requestParameters.lastName !== undefined) {
      queryParameters['lastName'] = requestParameters.lastName;
    }

    if (requestParameters.password !== undefined) {
      queryParameters['password'] = requestParameters.password;
    }

    if (requestParameters.store !== undefined) {
      queryParameters['store'] = requestParameters.store;
    }

    if (requestParameters.username !== undefined) {
      queryParameters['username'] = requestParameters.username;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/order/{sessionId}`.replace(
          `{${'sessionId'}}`,
          encodeURIComponent(String(requestParameters.sessionId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * order
   */
  async orderUsingGET(
    requestParameters: OrderUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ReadableOrderConfirmation> {
    const response = await this.orderUsingGETRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Reservation status description insert
   */
  async reservationDescUsingPOSTRaw(
    requestParameters: ReservationDescUsingPOSTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.description === null ||
      requestParameters.description === undefined
    ) {
      throw new runtime.RequiredError(
        'description',
        'Required parameter requestParameters.description was null or undefined when calling reservationDescUsingPOST.'
      );
    }

    if (
      requestParameters.lang === null ||
      requestParameters.lang === undefined
    ) {
      throw new runtime.RequiredError(
        'lang',
        'Required parameter requestParameters.lang was null or undefined when calling reservationDescUsingPOST.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.description !== undefined) {
      queryParameters['description'] = requestParameters.description;
    }

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    if (requestParameters.status !== undefined) {
      queryParameters['status'] = requestParameters.status;
    }

    if (requestParameters.store !== undefined) {
      queryParameters['store'] = requestParameters.store;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/order/reservationdescription`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Reservation status description insert
   */
  async reservationDescUsingPOST(
    requestParameters: ReservationDescUsingPOSTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.reservationDescUsingPOSTRaw(requestParameters, initOverrides);
  }

  /**
   * Status translation method
   */
  async statusTranslationUsingPOSTRaw(
    requestParameters: StatusTranslationUsingPOSTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<string>> {
    if (
      requestParameters.inputLanguage === null ||
      requestParameters.inputLanguage === undefined
    ) {
      throw new runtime.RequiredError(
        'inputLanguage',
        'Required parameter requestParameters.inputLanguage was null or undefined when calling statusTranslationUsingPOST.'
      );
    }

    if (
      requestParameters.targetLanguage === null ||
      requestParameters.targetLanguage === undefined
    ) {
      throw new runtime.RequiredError(
        'targetLanguage',
        'Required parameter requestParameters.targetLanguage was null or undefined when calling statusTranslationUsingPOST.'
      );
    }

    if (
      requestParameters.translationType === null ||
      requestParameters.translationType === undefined
    ) {
      throw new runtime.RequiredError(
        'translationType',
        'Required parameter requestParameters.translationType was null or undefined when calling statusTranslationUsingPOST.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.inputLanguage !== undefined) {
      queryParameters['inputLanguage'] = requestParameters.inputLanguage;
    }

    if (requestParameters.store !== undefined) {
      queryParameters['store'] = requestParameters.store;
    }

    if (requestParameters.targetLanguage !== undefined) {
      queryParameters['targetLanguage'] = requestParameters.targetLanguage;
    }

    if (requestParameters.text !== undefined) {
      queryParameters['text'] = requestParameters.text;
    }

    if (requestParameters.translationType !== undefined) {
      queryParameters['translationType'] = requestParameters.translationType;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/status/translation`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.TextApiResponse(response) as any;
  }

  /**
   * Status translation method
   */
  async statusTranslationUsingPOST(
    requestParameters: StatusTranslationUsingPOSTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<string> {
    const response = await this.statusTranslationUsingPOSTRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * updateOrderCustomer
   */
  async updateOrderCustomerUsingPATCHRaw(
    requestParameters: UpdateOrderCustomerUsingPATCHRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling updateOrderCustomerUsingPATCH.'
      );
    }

    if (
      requestParameters.orderCustomer === null ||
      requestParameters.orderCustomer === undefined
    ) {
      throw new runtime.RequiredError(
        'orderCustomer',
        'Required parameter requestParameters.orderCustomer was null or undefined when calling updateOrderCustomerUsingPATCH.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    if (requestParameters.store !== undefined) {
      queryParameters['store'] = requestParameters.store;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/private/orders/{id}/customer`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.orderCustomer
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * updateOrderCustomer
   */
  async updateOrderCustomerUsingPATCH(
    requestParameters: UpdateOrderCustomerUsingPATCHRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.updateOrderCustomerUsingPATCHRaw(
      requestParameters,
      initOverrides
    );
  }

  /**
   * updateOrderStatus
   */
  async updateOrderStatusUsingPUTRaw(
    requestParameters: UpdateOrderStatusUsingPUTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling updateOrderStatusUsingPUT.'
      );
    }

    if (
      requestParameters.status === null ||
      requestParameters.status === undefined
    ) {
      throw new runtime.RequiredError(
        'status',
        'Required parameter requestParameters.status was null or undefined when calling updateOrderStatusUsingPUT.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    if (requestParameters.store !== undefined) {
      queryParameters['store'] = requestParameters.store;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/private/orders/{id}/status`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.status as any
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * updateOrderStatus
   */
  async updateOrderStatusUsingPUT(
    requestParameters: UpdateOrderStatusUsingPUTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.updateOrderStatusUsingPUTRaw(requestParameters, initOverrides);
  }
}

/**
 * @export
 */
export const ReservationDescUsingPOSTStatusEnum = {
  Active: 'ACTIVE',
  Canceled: 'CANCELED',
  Finished: 'FINISHED',
  Overdue: 'OVERDUE',
  Overduefinished: 'OVERDUEFINISHED',
  Planned: 'PLANNED',
  Running: 'RUNNING',
  Unused: 'UNUSED'
} as const;
export type ReservationDescUsingPOSTStatusEnum =
  typeof ReservationDescUsingPOSTStatusEnum[keyof typeof ReservationDescUsingPOSTStatusEnum];
