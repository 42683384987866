import { render } from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { ScrollToTop } from './components/scroll-to-top/ScrollToTop';
import AppProvider from './core/appProvider/AppProvider';
import UserService from './core/auth/userService';
import registerServiceWorker from './core/registerServiceWorker';
import { Router } from './core/routes/router';
import ContextProviders from './store/ContextProviders';

const queryClient = new QueryClient();

const renderApp = () =>
  render(
    <ContextProviders>
      <QueryClientProvider client={queryClient}>
        <AppProvider>
          <BrowserRouter>
            <ScrollToTop />
            <Router />
          </BrowserRouter>
        </AppProvider>
      </QueryClientProvider>
    </ContextProviders>,
    document.getElementById('root')
  );

UserService.initKeycloak(renderApp);
registerServiceWorker();
