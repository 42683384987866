import MenuIcon from '@mui/icons-material/Menu';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { AppBar, Badge, IconButton, Toolbar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { selectCartProductsCount } from '../../../../feature/cart/cartSlice';
import { InfoButton } from '../../../../feature/info-button/InfoButton';
import { useAppSelector } from '../../../../hooks';
import { Account } from './Account';
import { DepotSelector } from './DepotSelector';
import { LanguageSelector } from './LanguageSelector';
import { Logo } from './Logo';
import { selectOrganization } from '../../../../feature/organization/organizationSlice';
import { isDepotManager } from '../../../../feature/account/customerSlice';

export const Header = ({ toggleDrawer, toogleCart }) => {
  const classes = useStyles();
  const productsCount = useAppSelector(selectCartProductsCount);
  const organization = useAppSelector(selectOrganization);
  const isDM = useAppSelector(isDepotManager);
  
  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar className={classes.toolBar}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          size="large"
        >
          <MenuIcon />
        </IconButton>

        <Logo />

        <span className="flexSpacer" />

        <InfoButton id={'GENERAL_INFO'} color={'inherit'} />

        <DepotSelector />
        
        { (organization.supportReservations || (isDM && organization.supportAdminReservations)) && (
        <IconButton color="inherit" onClick={toogleCart} size="large">
          <Badge badgeContent={productsCount ?? null} color="primary">
            <ShoppingCartIcon />
          </Badge>
        </IconButton>
        )}

        <LanguageSelector />

        <Account />
      </Toolbar>
    </AppBar>
  );
};

const useStyles = makeStyles((theme) => ({
  appBar: {
    boxShadow: '0 1px 8px rgba(0,0,0,.3)',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    zIndex: theme.zIndex.drawer + 100,
    position: 'fixed'
  },
  toolBar: {
    paddingLeft: theme.spacing(1 / 2),
    paddingRight: theme.spacing(1 / 2)
  }
}));
