import { ArrowCircleLeft } from '@mui/icons-material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Wrapper from '../../components/wrapper/Wrapper';
import { buildRandomKey, formatPrice } from '../../core/Helpers/helper';
import { useAppSelector } from '../../hooks';
import { ProductType } from '../../types/product';
import { navigateToBackendCheckout } from '../../webServices/cartService';
import { updateCurrentCustomer } from '../../webServices/customerService';
import { selectActiveCustomer } from '../account/customerSlice';
import { selectCart, selectFlattedCartProducts } from '../cart/cartSlice';
import { DepotInfo } from '../depot/DepotInfo';
import { selectOrganization } from '../organization/organizationSlice';
import { ProductImage } from '../products/ProductImage';
import { ProductInfo } from '../products/ProductInfo';
import { CountryDropdownI18n } from '../../components/CountryDropdownI18n';
import MuiPhoneNumber from 'material-ui-phone-number';

const Checkout = () => {
  const { t } = useTranslation('translation');
  const navigate = useNavigate();

  const [populated, setPopulated] = useState(false);
  const [customerFirstName, setCustomerFirstName] = useState('');
  const [customerLastName, setCustomerLastName] = useState('');
  const [customerPostalCode, setCustomerPostalCode] = useState('');
  const [customerPhone, setCustomerPhone] = useState('');
  const [customerCountryCode, setCustomerCountryCode] = useState('NL');
  const [customerAcceptConditions, setCustomerAcceptConditions] =
    useState(false);

  const customer = useAppSelector(selectActiveCustomer);
  const organization = useAppSelector(selectOrganization);
  const products = useAppSelector(selectFlattedCartProducts);
  const cart = useAppSelector(selectCart);

  useEffect(() => {
    if (customer && !populated) {
      setPopulated(true);

      if (customer?.billing?.firstName)
        setCustomerFirstName(customer.billing.firstName);
      if (customer?.billing?.lastName)
        setCustomerLastName(customer.billing.lastName);
      if (customer?.billing?.phone) setCustomerPhone(customer.billing.phone);

      if (customer?.billing?.postalCode)
        setCustomerPostalCode(customer.billing.postalCode);

      if (customer?.billing?.country)
        setCustomerCountryCode(customer.billing.country);
    }
  }, [customer]);

  const disable =
    isEmpty(customerFirstName) ||
    isEmpty(customerLastName) ||
    isEmpty(customerCountryCode) ||
    isEmpty(customerPhone) ||
    isEmpty(customerPostalCode) ||
    !customerAcceptConditions;

  const classes = useStyles();

  const onCheckout = async () => {
    const newCustomer = {
      ...customer,
      billing: {
        ...customer.billing,
        postalCode: customerPostalCode,
        countryCode: customerCountryCode,
        country: customerCountryCode,
        firstName: customerFirstName,
        lastName: customerLastName,
        phone: customerPhone
      }
    };

    await updateCurrentCustomer(newCustomer);
    navigateToBackendCheckout(cart);
  };

  const handleFirstName = (event) => {
    setCustomerFirstName(event.target.value);
  };

  const handleLastName = (event) => {
    setCustomerLastName(event.target.value);
  };

  const handlePostalCode = (event) => {
    setCustomerPostalCode(event.target.value);
  };

  if (!products.length) {
    return <h1> Loading </h1>;
  }

  const displayProduct = (product: ProductType) => {
    return (
      <>
        <Grid container>
          <Grid item>
            <ProductImage product={product} className={classes.figureImg} />
          </Grid>

          <Grid item>
            <ProductInfo product={product} />
          </Grid>
        </Grid>
        <Divider component="li" sx={{ my: 2 }} key={buildRandomKey()} />
      </>
    );
  };

  return (
    <Wrapper>
      <>
        <Button
          sx={{ mb: 2 }}
          variant={'contained'}
          startIcon={<ArrowCircleLeft />}
          onClick={() => {
            navigate(-1);
          }}
        >
          {t('button.back')}
        </Button>
        <Card className={classes.card}>
          <Grid container spacing={0} direction={'row'} alignItems={'stretch'}>
            <Grid item xs={12} sm={6} md={6} className="pa-1">
              <Typography variant="h6" key={buildRandomKey()}>
                {t('translation:checkout')}
              </Typography>

              <DepotInfo />

              <List
                style={{
                  width: '100%',
                  maxWidth: 360
                }}
                key={buildRandomKey()}
              >
                {products.map(displayProduct)}
              </List>
              <div className="block my-1" />

              {!!cart.total && (
                <Typography
                  variant="h6"
                  style={{ textAlign: 'right', paddingRight: 20 }}
                >{`${t('translation:total')} ${formatPrice(
                  cart.total
                )}`}</Typography>
              )}
            </Grid>

            <Grid item xs={12} sm={6} md={6} className="pa-1">
              <Typography variant="h6" key={buildRandomKey()}>
                {t('translation:customerData')}
              </Typography>
              <div>
                <div className="block my-1" />
                <TextField
                  required
                  id="outlined-firstName"
                  label={t('translation:firstName')}
                  className={classes.customerInput}
                  value={customerFirstName}
                  onChange={handleFirstName}
                  margin="dense"
                />
                <div className="block my-1" />

                <TextField
                  required
                  id="outlined-lastName"
                  label={t('translation:lastName')}
                  className={classes.customerInput}
                  value={customerLastName}
                  onChange={handleLastName}
                  margin="dense"
                />
                <div className="block my-1" />
                <MuiPhoneNumber 
                  id="outlined-mobile"
                  defaultCountry='nl'
                  value={customerPhone}
                  inputClass={classes.customerInput}
                  label={t('translation:phone')}
                  onChange={(value) => {
                    setCustomerPhone(value);
                  }}
                  margin="dense"
                  variant="outlined"
                />

                <div className="block my-1" />

                <TextField
                  required
                  id="outlined-postalCode"
                  label={t('translation:postalCode')}
                  className={classes.customerInput}
                  value={customerPostalCode}
                  onChange={handlePostalCode}
                  margin="dense"
                />
                <div className="block my-1" />
                <CountryDropdownI18n
                    id="outlined-country"
                    className={classes.customerInput}
                    selectedCountry={customerCountryCode}
                    onChange={(selectedCountry) => {
                      setCustomerCountryCode(selectedCountry.toString());
                    }}
                />
                <div className="block my-1" />
              </div>

              <div className="block my-1" />

              <FormControlLabel
                control={
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon />}
                    checkedIcon={<CheckBoxIcon />}
                    onChange={(event) => {
                      setCustomerAcceptConditions(
                        event?.target?.checked ?? false
                      );
                    }}
                    value={'acceptConditions'}
                  />
                }
                label={
                  <Typography
                    sx={(theme) => ({
                      '& a': {
                        color: theme.palette.primary.main,
                        fontWeight: 700
                      }
                    })}
                  >
                    <Trans t={t} i18nKey="translation:acceptConditions">
                      I accept the terms and conditions by{' '}
                      <a
                        href={organization?.styling?.nazzaTermsUrl ? organization.styling.nazzaTermsUrl : "https://mijn.nazza.nl/terms"}
                        target="_blank"
                      >
                        Nazza
                      </a>
                    </Trans>

                    {!!organization?.styling?.termsUrl && (
                      <>
                        {' ' + t('and') + ' '}
                        <a href={organization.styling.termsUrl} target="_blank">
                          {organization.styling.label}
                        </a>
                      </>
                    )}
                  </Typography>
                }
              />

              <Button
                disabled={disable}
                sx={{ my: 3, width: '100%' }}
                variant="contained"
                color="primary"
                onClick={onCheckout}
              >
                {t(!!cart?.total ? 'proceedToPayment' : 'confirmReservation')}
              </Button>
            </Grid>
          </Grid>
        </Card>
      </>
    </Wrapper>
  );
};

const useStyles = makeStyles((theme) => ({
  card: {
    overflow: 'visible'
  },
  customerInput: {
    width: '100%'
  },
  figureImg: {
    width: '90px',
    marginRight: '30px',
    border: '1px solid #000'
  }
}));

export default Checkout;
