import { useRoutes } from 'react-router-dom';
import { BackendError } from '../../components/errors/BackendError';
import { NotFound } from '../../components/errors/NotFound';
import { AccountScreen } from '../../feature/account/AccountScreen';
import { RegisterScreen } from '../../feature/auth/RegisterScreen';
import Checkout from '../../feature/checkout/Checkout';
import { CheckoutPaymentSuccess } from '../../feature/checkout/CheckoutPaymentSuccess';
import { FeedbackScreen } from '../../feature/feedback/FeedbackScreen';
import { HomeScreen } from '../../feature/home/HomeScreen';
import TravelScreen from '../../feature/products/TravelScreen';
import { ReservationsScreen } from '../../feature/reservations/ReservationsScreen';
import { UsersHistoryScreen } from '../../feature/users/UsersHistoryScreen';
import { UsersScreen } from '../../feature/users/UsersScreen';
import { UserWhiteListsScreen } from '../../feature/users/UserWhiteListsScreen';
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
import OrganisationGuard from '../auth/OrganisationGuard';
import UserService from '../auth/userService';
import { DashboardLayout } from '../layouts/dashboard/DashboardLayout';
import { DashboardLayoutGuest } from '../layouts/dashboard/DashboardLayoutGuest';
import { SimpleLayout } from '../layouts/simple/SimpleLayout';
import { PATH_AUTH, PATH_DASHBOARD, PATH_PAGE } from './paths';

export function Router() {
  return useRoutes([
    //auth
    {
      path: PATH_DASHBOARD.root,
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { index: true, element: <HomeScreen /> },
        { path: PATH_DASHBOARD.products.travel, element: <TravelScreen /> },
        { path: PATH_DASHBOARD.account, element: <AccountScreen /> },
        {
          path: PATH_DASHBOARD.reservations,
          element: <ReservationsScreen />
        },
        { path: PATH_DASHBOARD.checkout.root, element: <Checkout /> },
        {
          path: PATH_DASHBOARD.checkout.payment,
          element: <CheckoutPaymentSuccess />
        },
        {
          path: PATH_DASHBOARD.users,
          element: <UsersScreen />
        },
        {
          path: PATH_DASHBOARD.usersWhiteList,
          element: <UserWhiteListsScreen />
        },
        {
          path: PATH_DASHBOARD.usersHistory,
          element: <UsersHistoryScreen />
        },
        {
          path: PATH_DASHBOARD.feedback,
          element: <FeedbackScreen />
        }
      ]
    },

    //guest
    {
      path: PATH_AUTH.root,
      element: (
        <OrganisationGuard>
          <GuestGuard>
            <SimpleLayout />
          </GuestGuard>
        </OrganisationGuard>
      ),
      children: [
        // { index: true, element: <HomeScreen /> },
        { path: PATH_AUTH.register, element: <RegisterScreen /> }
      ]
    },

    //dynamic
    {
      path: PATH_AUTH.root,
      element: (
        <OrganisationGuard>
          {UserService.isLoggedIn() ? (
            <DashboardLayout />
          ) : (
            <DashboardLayoutGuest />
          )}
        </OrganisationGuard>
      ),
      children: [
        // { index: true, element: <HomeScreen /> },
        {
          path: PATH_DASHBOARD.organisation.products.travel,
          element: <TravelScreen />
        }
      ]
    },

    { path: PATH_PAGE.page404, element: <NotFound /> },
    { path: PATH_PAGE.page500, element: <BackendError /> },
    // { path: '*', element: <Navigate to={PATH_PAGE.page404} replace /> }
    { path: '*', element: <div> no route</div> }
  ]);
}
