import Paper from '@mui/material/Paper';
import { createColumnHelper } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShopizerApiClient } from '../../apis';
import { ReadableReservation } from '../../apis/shopizer';
import {
  CustomColumnDef,
  DataTable,
  RequestParams,
  ReturnData
} from '../../components/tanStackTable/DataTable';
import { getDateFromAny } from '../../components/UtilityService';
import Wrapper from '../../components/wrapper/Wrapper';
import { useAppSelector } from '../../hooks';
import { useLanguage } from '../../store/LanguageProvider';
import { selectOrganization } from '../organization/organizationSlice';
import {
  DisplayDates,
  HeaderDisplayDate,
  useDisplayTime
} from '../products/DisplayDates';
import { ChangeReservation } from './ChangeReservation';
import { PeriodProgress } from './PeriodProgress';

export const ReservationsUser = () => {
  const { t } = useTranslation('translation');
  const { language } = useLanguage();
  const shopizerApiClient = useShopizerApiClient();
  const nonPaying = useAppSelector(selectOrganization).nonPaying ?? true;

  const columnHelper = createColumnHelper<ReadableReservation>();
  const [refreshListCount, setRefreshListCount] = useState(0);
  const { toggleDisplayTime, displayTime, allowToggle } = useDisplayTime();

  useEffect(() => {
    setRefreshListCount((c) => c + 1);
  }, [language]);

  const refreshList = () => {
    setRefreshListCount((current) => current + 1);
  };

  const columns: CustomColumnDef[] = [
    {
      ...columnHelper.accessor('orderId', { header: t('orderNo') }),
      valueType: 'string'
    },
    {
      ...columnHelper.accessor('location', { header: t('location') }),
      valueType: 'string'
    },
    {
      ...columnHelper.accessor('bikeNumber', {
        header: t('field.identification')
      }),
      valueType: 'string'
    },
    {
      ...columnHelper.accessor('asset', { header: t('asset') }),
      valueType: 'string'
    },
    {
      ...columnHelper.accessor('rentalStatus', { header: t('status') }),
      valueType: 'string'
    }
  ];

  if (!nonPaying) {
    columns.push(
      columnHelper.accessor('price', {
        header: t('Total'),
        cell: (accessor) => {
          return (
            <div style={{ whiteSpace: 'nowrap' }}>{accessor.getValue()}</div>
          );
        }
      })
    );
  }

  columns.push({
    ...columnHelper.accessor('startDate', {
      header: () => {
        return (
          <HeaderDisplayDate
            label={t('period')}
            allowToggle={allowToggle}
            toggleDisplayTime={toggleDisplayTime}
            displayTime={displayTime}
          />
        );
      },
      cell: (accessor) => {
        const startDate = getDateFromAny(accessor.row.original.startDate);
        const endDate = getDateFromAny(accessor.row.original.endDate);

        if (!startDate) return <></>;

        return (
          <div>
            <DisplayDates
              product={{ startDate, endDate }}
              displayTime={displayTime}
            />
            <PeriodProgress startDate={startDate} endDate={endDate} />
          </div>
        );
      }
    }),
    valueType: displayTime ? 'datetime' : 'date'
  });
  columns.push(
    columnHelper.accessor('reservationId', {
      header: '',
      enableColumnFilter: false,
      enableSorting: false,
      cell: (accessor) => {
        return (
          <div>
            <ChangeReservation
              reservation={accessor.row.original}
              refreshList={refreshList}
            />
          </div>
        );
      }
    })
  );

  const fetchData = async (params: RequestParams): Promise<ReturnData> => {
    const response =
      await shopizerApiClient.orderApi.getCustomerShoppingHistoryNewUsingGET({
        ...params,
        lang: language,
        page: params.page - 1,
        filter: JSON.stringify(params.filter)
      });

    return {
      recordsTotal: response.totalCount,
      items: response.reservations
    };
  };

  return (
    <Wrapper>
      <Paper sx={{ height: '100%', marginBottom: 2, padding: 0 }}>
        <DataTable
          columns={columns}
          requestType={'fetch'}
          refreshListCount={refreshListCount}
          fetch={fetchData}
        />
      </Paper>
    </Wrapper>
  );
};
