import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReservationProductType } from '../../types/product';
import { navigateToRetryPayment } from '../../webServices/cartService';

export function PendingBadge({ product }: { product: ReservationProductType }) {
  const { t } = useTranslation('translation');

  if (product.paymentStatus !== 'PENDING') return <></>;

  return (
    <div>
      <Box
        sx={{
          color: 'secondary.contrastText',
          backgroundColor: 'secondary.main',
          py: 0.5,
          px: 1,
          mt: 1,
          textAlign: 'center',
          borderRadius: 50,
          display: 'inline-block'
        }}
      >
        {t('paymentStatus.PENDING')}
      </Box>{' '}
      <Button
          sx={{ my: 3, width: '50%' }}
          variant="contained"
          color="primary"
          onClick={() => {
            navigateToRetryPayment(product.id);
          }}
          >{t('proceedToPayment')}
      </Button>
    </div>
  );
}
