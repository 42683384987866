import { ReduxProvider } from '../redux/ReduxProvider';
import { LanguageProvider } from './LanguageProvider';
import { PeriodProvider } from './PeriodProvider';

export default function ContextProviders({ children }) {
  return (
    <ReduxProvider>
      <PeriodProvider>
        <LanguageProvider>{children}</LanguageProvider>
      </PeriodProvider>
    </ReduxProvider>
  );
}
