import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Alert, Stack } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useShopizerApiClient } from '../../apis';
import { DisplayDynamicFields } from '../../components/dynamic-forms/DisplayDynamicFields';
import FormProvider from '../../components/hook-form/FormProvider';
import RHFTextField from '../../components/hook-form/RHFTextField';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useLanguage } from '../../store/LanguageProvider';
import { selectActiveDepot, selectActiveDepotCode } from '../depot/depotSlice';
import { useDynamicFields } from '../dynamic-forms/UseDynamicFields';
import { selectOrganization } from '../organization/organizationSlice';
import { ReadableRegisterTexts } from '../../apis/shopizer';
import { overrideGuestCart } from '../cart/cartSlice';

type FormValuesProps = {
  email: string;
  firstName: string;
  lastName: string;
  afterSubmit?: string;
  nrOfPeriods?: number;
};

export default function AuthRegisterForm(labels: ReadableRegisterTexts) {
  const { t } = useTranslation('translation');
  const api = useShopizerApiClient();
  const dispatch = useAppDispatch();
  const { language } = useLanguage();
  const organization = useAppSelector(selectOrganization);
  const activeDepotCode = useAppSelector(selectActiveDepotCode);
  const activeDepot = useAppSelector(selectActiveDepot);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  
  const { fields, processData, generateConditions } =
    useDynamicFields('REGISTER_FIELDS');

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().required(t('validation.firstName.required')),
    lastName: Yup.string().required(t('validation.lastName.required')),
    nrOfPeriods: Yup.number().transform((value) => (isNaN(value) ? null : value))
      .nullable()
      .required(t('validation.nrOfPeriods.required'))
      .min(0, t('validation.nrOfPeriods.min')),
    email: Yup.string()
      .required(t('validation.email.required'))
      .email(t('validation.email.valid')),
    ...generateConditions()
  });

  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    nrOfPeriods: 1
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(RegisterSchema),
    defaultValues
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful }
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      const response = await api.customerApi.onboardingCheckUsingPOST({
        email: data.email,
        lastName: data.lastName,
        firstName: data.firstName,
        lang: language,
        store: activeDepotCode,
        organization: organization.code,
        nrOfPeriods: !data.nrOfPeriods || data.nrOfPeriods < 1 ? 1 : data.nrOfPeriods,
        dynamicFieldValues: {
          values: processData(data)
        }
      });

      if (response.success) {
        setSuccessMessage(response.message);
        if (response.anonymousShoppingCart) {
          dispatch( overrideGuestCart(response.anonymousShoppingCart))
        }
      } else {
        setError('afterSubmit', { message: response.message });
      }
    } catch (error) {
      reset();
      setError('afterSubmit', {
        ...error,
        message: error.message || error
      });
    }
  };

  const [nrOfPeriodsValue, setNrOfPeriodsValue] = useState(1)

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2.5}>
        {!!errors.afterSubmit && (
          <Alert severity="error">{errors.afterSubmit.message}</Alert>
        )}

        {!!successMessage && <Alert severity="success">{successMessage}</Alert>}

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFTextField name="firstName" label={t('firstName')} />
          <RHFTextField name="lastName" label={t('lastName')} />
        </Stack>

        <RHFTextField name="email" label={ !!labels?.registerEmail ? labels?.registerEmail[language] : t('emailAddress') } />

        <DisplayDynamicFields fields={fields} />

        {!!organization.styling?.registration?.registerSimpleFixed &&
          <RHFTextField name="nrOfPeriods" label={ t('nrOfDays') } type='number' />
        }
        <LoadingButton
          fullWidth
          color="primary"
          size="large"
          type="submit"
          variant="contained"
          disabled={!!successMessage}
          loading={isSubmitting}
        >
          {!!labels?.createAccount ? labels?.createAccount[language] : t('register.createAccount')}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
