import _ from 'lodash';

const path = (root: string, sublink: string) =>
  '/' +
  [root, sublink]
    .map((v) => _.trimStart(v, '/'))
    .filter((v) => v)
    .join('/');

const ROOTS_DASHBOARD = '/';
const ROOTS_DASHBOARD_PRODUCTS = path(ROOTS_DASHBOARD, '/products');
const ROOTS_DASHBOARD_CHECKOUT = path(ROOTS_DASHBOARD, '/checkout');
const ROOTS_DASHBOARD_ORGANISATION = path(
  ROOTS_DASHBOARD,
  '/:organizationCode'
);
const ROOTS_AUTH = '/';

export const PATH_PAGE = {
  page404: '/404',
  page500: '/500'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  reservations: path(ROOTS_DASHBOARD, '/reservations'),
  account: path(ROOTS_DASHBOARD, '/account'),
  users: path(ROOTS_DASHBOARD, '/users'),
  usersWhiteList: path(ROOTS_DASHBOARD, '/usersWhiteList'),
  usersHistory: path(ROOTS_DASHBOARD, '/usersHistory'),
  feedback: path(ROOTS_DASHBOARD, '/feedback'),
  products: {
    root: ROOTS_DASHBOARD_PRODUCTS,
    travel: path(ROOTS_DASHBOARD_PRODUCTS, '/travel')
  },
  organisation: {
    root: ROOTS_DASHBOARD_ORGANISATION,
    products: {
      root: ROOTS_DASHBOARD_ORGANISATION + '/products',
      travel: path(ROOTS_DASHBOARD_ORGANISATION + '/products', '/travel')
    }
  },
  checkout: {
    root: ROOTS_DASHBOARD_CHECKOUT,
    payment: path(ROOTS_DASHBOARD_CHECKOUT, '/payment')
  }
};

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  register: path(ROOTS_AUTH, '/:organizationCode/register'),
  login: PATH_DASHBOARD.root
};
