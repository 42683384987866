import { Button } from '@mui/material';
import { Row, Table } from '@tanstack/react-table';
import { Dropdown } from 'react-bootstrap';
import { ButtonVariant } from 'react-bootstrap/types';
import { NotificationManager } from 'react-notifications';

export type TableAction = {
  label: string;
  icon?: JSX.Element;
} & (
  | {
      type: 'action';
      action: (selectedIds: number[], selectedRows: Row<any>[]) => void;
      confirm?: (selectedRows: Row<any>[]) => string;
      variant?: 'text' | 'outlined' | 'contained';
      color?:
        | 'inherit'
        | 'primary'
        | 'secondary'
        | 'success'
        | 'error'
        | 'info'
        | 'warning';
      mandatoryRecordsSelected?: true;
    }
  | {
      type: 'group';
      variant?: ButtonVariant;
      actions: TableAction[];
    }
);

interface TableActionsProps {
  table: Table<any>;
  actions?: TableAction[];
}

export function TableActions(props: TableActionsProps) {
  if (!props.actions) return <></>;
  const { table, actions } = props;

  const runAction = async (action: TableAction) => {
    if (action.type !== 'action') return;

    const selectedRows = table.getSelectedRowModel().flatRows;
    const selectedIds = selectedRows.map((row) => row.original.id);

    if (action.mandatoryRecordsSelected && selectedRows.length < 1) {
      NotificationManager.error(
        'To use this action you need to select at least one record!',
        action.label
      );
      return;
    }

    if (!action.confirm || window.confirm(action.confirm(selectedRows))) {
      await action.action(selectedIds, selectedRows);
      table.resetRowSelection(true);
    }
  };

  return (
    <>
      {actions.map((action: TableAction) => {
        if (action.type === 'group') {
          return (
            <Dropdown className="d-inline mr-1">
              <Dropdown.Toggle
                variant={action.variant}
                id="dropdown-autoclose-true"
              >
                {action.label}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {action.actions.map((subaction) => {
                  if (subaction.type !== 'action') return '';
                  return (
                    <Dropdown.Item onClick={() => runAction(subaction)}>
                      {subaction.label}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          );
        }

        return (
          <Button
            className="d-inline mr-1"
            color={action.color}
            variant={action.variant}
            onClick={() => runAction(action)}
            startIcon={action.icon}
          >
            {action.label}
          </Button>
        );
      })}
    </>
  );
}
