import { DisplayDynamicField, IDynamicField } from './DisplayDynamicField';

interface DisplayDynamicFieldsProps {
  fields: IDynamicField[];
}

export const DisplayDynamicFields = ({ fields }: DisplayDynamicFieldsProps) => {
  if (!fields || fields.length === 0) return <></>;

  return (
    <>
      {fields.map((field) => {
        return <DisplayDynamicField field={field} />;
      })}
    </>
  );
};
