import {
  createEntityAdapter,
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';
import { createApiThunk } from '../../apis';
import { ReadableMerchantStore } from '../../apis/shopizer';
import UserService from '../../core/auth/userService';
import { RootState } from '../../redux';
import { fetchOrganization } from '../organization/organizationSlice';

export const depotAdapter = createEntityAdapter<ReadableMerchantStore>();

const initialState = depotAdapter.getInitialState<{
  active: number | undefined;
  loaded: boolean;
}>({
  active: undefined,
  loaded: false
});
export type DepotState = typeof initialState;

export const depotSlice = createSlice({
  name: 'depot',
  initialState,
  reducers: {
    setActiveDepot: (state, action: PayloadAction<number>) => {
      if (state.active != action.payload) {
        state.active = state.entities[action.payload]
          ? action.payload
          : undefined;
      }
    }
  },
  extraReducers: (builder) => {
    const fixActive = (state) => {
      if (state.entities[state.active]) return;
      var depotArray = Object.keys(state.entities).map(function(depotNamedIndex){
        let depot = state.entities[depotNamedIndex];
        return depot;
      });
      if (depotArray.find(d => d.preferredStore)) {
        state.active = depotArray.find(d => d.preferredStore).id
      }
      else {
        state.active = state.ids[0] ?? undefined;
      }
    }
    builder.addCase(fetchDepotsForOrganisation.fulfilled, (state, action) => {
      state.loaded = true;
      depotAdapter.setAll(state, action.payload.data);
      fixActive(state);
    });

    builder.addCase(fetchOrganization.fulfilled, (state, action) => {
      state.loaded = true;
      depotAdapter.removeAll(state);
      state.active = undefined;

      if (!action.payload.parent) return;
      depotAdapter.addOne(state, action.payload);
      state.active = action.payload.id;
    });

    builder.addCase(fetchDepotsForOrganisation.rejected, (state, action) => {
      state.loaded = true;
      depotAdapter.removeAll(state);
      state.active = undefined;
    });
  }
});

const depotSelectors = depotAdapter.getSelectors<RootState>(
  (state) => state.depot
);
export const { selectAll: selectAllDepots, selectById: selectDepotById } =
  depotSelectors;

export const selectActiveDepot = (state: RootState) => {
  return state.depot.active ? selectDepotById(state, state.depot.active) : undefined;
}

export const selectActiveDepotCode = (state: RootState): string =>
  selectActiveDepot(state)?.code ?? undefined;

export const selectDepotsWereLoaded = (state: RootState): boolean =>
  state.depot.loaded;

export const fetchDepotsForOrganisation = createApiThunk(
  'depot/profile',
  async (
    { organisationCode }: { organisationCode: string },
    { shopizerApi }
  ) => {
    if (!UserService.isLoggedIn()) return;
    return await shopizerApi.storeApi.getDepotsUsingGET({
      code: organisationCode,
      lang: 'en'
    });
  }
);

export const { setActiveDepot } = depotSlice.actions;
export const depotReducer = depotSlice.reducer;
