import { Link, Stack, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { LanguageSelector } from '../../core/layouts/dashboard/header/LanguageSelector';
import { LoginLayout } from '../../core/layouts/login/LoginLayout';
import { PATH_AUTH } from '../../core/routes/paths';
import { useAppSelector } from '../../hooks';
import { useCacheSelect } from '../cache/UseCacheSelect';
import { useNavigate } from 'react-router-dom';
import { InfoButton } from '../info-button/InfoButton';
import { selectOrganization } from '../organization/organizationSlice';
import { selectActiveDepot } from '../depot/depotSlice';
import AuthRegisterForm from './AuthRegisterForm';
import { Logo } from './Logo';
import { useLanguage } from '../../store/LanguageProvider';
import { useShopizerApiClient } from '../../apis';
import parse from 'html-react-parser';
import { Buffer } from 'buffer';
import { UnauthorizedError } from '../../components/errors/UnauthorizedError';

export const RegisterScreen = () => {
  const { t } = useTranslation('translation');
  const { language } = useLanguage();
  const organization = useAppSelector(selectOrganization);
  const activeDepot = useAppSelector(selectActiveDepot);
  const styling = activeDepot?.styling?.registration ? activeDepot.styling : organization.styling;

  const code = activeDepot?.code ?? organization.code;
  const shopizerApi = useShopizerApiClient();
  const navigate = useNavigate();

  const fetchData = async () => {
    return await shopizerApi.storeApi.getInfoContentUsingGET({
      key: 'REGISTER_INFO',
      code
    });
  };

  const data = useCacheSelect('REGISTER_INFO-' + code, fetchData);
  const content = !!data ? decodeBase64(data.content[language] ?? null) : null;
  const contentParsed = !!content ? parse(content) : content;
    
  const fetchTexts = async () => {
    return await shopizerApi.storeApi.getRegisterTextsUsingGET({
      key: 'REGISTER_TEXTS',
      code
    });
  };

  const texts = useCacheSelect('REGISTER_TEXTS-' + code, fetchTexts);
  const alreadyAccountUrl = !!styling?.registration?.alreadyAccountUrl ? styling?.registration?.alreadyAccountUrl :  styling?.registration.registerReturnUrl;

  if (!organization.selfRegister)
    return <UnauthorizedError />;

  return (
    <LoginLayout
      title={''}
      illustration={styling?.registration?.registrationImage}
      bgColor={styling?.registration?.registrationImageBackgroundColor}
      fullImage={styling?.registration?.fullImage}
      extended={styling?.registration.registerInfoInline}
    >
      <Stack spacing={2} sx={{ mb: 5, position: 'relative', marginBottom: 0 }}>
        {!!styling?.registration?.logoForDarkBackground && (
          <Logo img={styling.registration.logoForDarkBackground} col={styling.registration.logoImageBackgroundColor}/>
        )}

        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Typography variant="h4">{ texts?.getStarted[language] ?? t('register.getStarted')}</Typography>
          <div>
            {!!styling?.registration?.registerInfoInline === false && (
            <InfoButton color={'inherit'} id={'REGISTER_INFO'} />
            )}
            <LanguageSelector />
          </div>
        </Stack>
        {!!styling?.registration?.registerInfoInline && (
        <Typography>
        {contentParsed}
        </Typography>
        )}
        <Stack direction="row" spacing={0.5} >
          <Typography variant="body2">
            {texts?.alreadyAccount[language] ?? t('register.alreadyAccountMessage')}
          </Typography>
          {!!styling?.registration?.registerInfoInline === false && (
          <Link to={PATH_AUTH.login} component={RouterLink} variant="subtitle2">
            {t('register.signIn')}
          </Link>
          )}
          {!!styling?.registration?.registerInfoInline === true && (
          <Link to={alreadyAccountUrl.replace("${parentStore}", organization?.code)
            .replace("${childStore}", activeDepot?.code)} component={RouterLink} variant="subtitle2">
            {texts?.alreadyAccountLinkTitle[language] ?? t('register.instructions')}
          </Link>
          )}
        </Stack>
      </Stack>

      <AuthRegisterForm registerEmail={!!texts ? texts?.registerEmail ?? null : null} createAccount={!!texts ? texts?.createAccount ?? null : null}/> 

      {!!styling?.termsUrl && (
        <Typography
          component="div"
          sx={{
            color: 'text.secondary',
            mt: 3,
            typography: 'caption',
            textAlign: 'center'
          }}
        >
        <Trans t={t} i18nKey="translation:onregisterAcceptConditions">
          I accept the terms and conditions by{' '}
          <Link to={styling?.nazzaTermsUrl ? styling.nazzaTermsUrl : "https://mijn.nazza.nl/terms"} component={RouterLink} target='_blank' variant="subtitle2">Nazza</Link>
        </Trans>

        {!!styling?.termsUrl && (
          <>
            {' ' + t('and') + ' '}
            <Link to={styling?.termsUrl} component={RouterLink} target='_blank' variant="subtitle2">{styling.label}</Link>
          </>
        )}
        </Typography>
      )}
      {!!styling?.registration.registerSimpleFixed && (
        <Stack>
          <div className="block my-1" />
          <Link href='products/travel' align='right'>
            {texts?.flexibleBookingTitle[language] ?? t('register.flexibleBookingTitle')}
          </Link>
        </Stack>
      )}
    </LoginLayout>
  );
};

const decodeBase64 = (string?: string) => {
  if (!string) return null;

  const decodedBuffer = Buffer.from(string, 'base64');
  return decodedBuffer.toString('latin1');
};
