import moment from 'moment';
import * as Yup from 'yup';
import { useShopizerApiClient } from '../../apis';
import { IDynamicField } from '../../components/dynamic-forms/DisplayDynamicField';
import { useAppSelector } from '../../hooks';
import { useLanguage } from '../../store/LanguageProvider';
import { useCacheSelect } from '../cache/UseCacheSelect';
import { selectActiveDepotCode } from '../depot/depotSlice';
import { selectOrganization } from '../organization/organizationSlice';

export const useDynamicFields = (key: string) => {
  const { language } = useLanguage();
  const api = useShopizerApiClient();
  const organization = useAppSelector(selectOrganization);
  const activeDepotCode = useAppSelector(selectActiveDepotCode);
  const code = activeDepotCode ?? organization.code;

  const fetchData = async () => {
    return await api.storeApi.getDynamicFieldsContentUsingGET({
      key,
      code
    });
  };

  const fieldsContent = useCacheSelect('DF-' + key + '-' + code, fetchData);
  const fields = fieldsContent?.fields ?? [];

  const getFieldValue = (data: object, field: IDynamicField) => {
    if (!(field.name in data)) {
      console.log(field.name + ' is missing from flow');
      return undefined;
    }

    const value = data[field.name];

    if (
      value &&
      (field.displayType === 'DATE' || field.displayType === 'DATETIME')
    ) {
      return moment(value).toISOString();
    }

    if (field.displayType === 'AUTOCOMPLETE' && !!value.value) {
      return value.value;
    }

    if (field.displayType === 'SWITCH') {
      return !!value ? 'true' : 'false';
    }

    return value;
  };

  const processData = (data: object) => {
    const dynamicFields = {};

    fields.forEach((field) => {
      dynamicFields[field.name] = getFieldValue(data, field);
    });

    return dynamicFields;
  };

  const generateConditions = () => {
    const conditions = {};

    fields.forEach((field) => {
      if (field.required) {
        conditions[field.name] = Yup.string().required(
          field.requiredValidationMessages[language] ?? 'Required'
        );
      }
    });

    return conditions;
  };

  return { key, fields, processData, generateConditions };
};
