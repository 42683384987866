import makeStyles from '@mui/styles/makeStyles';
import { NavLink } from 'react-router-dom';
import { useBackendStyling } from '../../../theme/useBackendStyling';

export const Logo = () => {
  const classes = useStyles();
  const backendStyling = useBackendStyling();

  return (
    <NavLink to={'/'}>
      <img
        src={backendStyling.logo.path}
        alt={backendStyling.label}
        className={classes.logo}
      />
    </NavLink>
  );
};

const useStyles = makeStyles((theme) => ({
  logo: {
    margin: 'auto',
    maxWidth: '200px',
    height: '50px',
    [theme.breakpoints.down('md')]: {
      maxWidth: '150px'
    }
  }
}));
