import { Card, useTheme } from '@mui/material';

export const Logo = ({ img, col }: { img: string, col?: string }) => {
  const theme = useTheme();

  return (
    <Card sx={{ backgroundColor: col ?? theme.palette.secondary.main }}>
      <img style={{ maxHeight: '60px', padding: 4 }} src={img} />
    </Card>
  );
};
