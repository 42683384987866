import { TextField } from '@mui/material';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { DurationConfigType, OptionType } from './SelectPeriod';
import { Language, useLanguage } from '../../../store/LanguageProvider';

export function PeriodEndDate(props: {
  onChange: (fromDate: Date) => void;
  value: Date;
  startDate: Date;
  options: OptionType[];
  durationConfig: DurationConfigType;
}) {
  const { t } = useTranslation('translation');
  const { language } = useLanguage();

  function shouldDisableDate(date: Moment) {
    let disable = true;

    if (props.durationConfig.disableDays && props.durationConfig.disableDays.includes(date.day())) {
      return disable;
     }

    const checkValue = date.format('L');

    props.options.forEach((o) => {
      const allowDate = moment(props.startDate)
        .add(o.minutes, 'minutes')
        .subtract(1, 'millisecond')
        .format('L');

      if (allowDate === checkValue) {
        disable = false;
        return;
      }
    });

    return disable;
  }

  function shouldDisableHour(h: number) {
    let disable = true;

     if (props.durationConfig.disableEndHours && props.durationConfig.disableEndHours.includes(h)) {
      return disable;
     }

    const checkValue = moment(props.value).set({ h, m: 0 }).format('L LT');

    props.options.forEach((o) => {
      const allowDate = moment(props.startDate)
        .add(o.minutes, 'minutes')
        .subtract(1, 'millisecond')
        .set({ m: 0 })
        .format('L LT');

      if (allowDate === checkValue) {
        disable = false;
        return;
      }
    });

    return disable;
  }

  function shouldDisableMinute(m: number) {
    let disable = true;

    const checkValue = moment(props.value).set({ m });

    props.options.forEach((o) => {
      const allowDate = moment(props.startDate).add(o.minutes, 'minutes');
      const betweenStartDate = moment(allowDate).subtract(3, 'minutes');
      const betweenEndDate = moment(allowDate).add(3, 'minutes');

      if (betweenStartDate < checkValue && checkValue < betweenEndDate) {
        disable = false;
        return;
      }
    });

    return disable;
  }
  const endDateHelper = props.durationConfig.endDateHelperText ? props.durationConfig.endDateHelperText[language] : undefined;      
  if (props.durationConfig.bookingUnit >= 1440) {
    return (
      <DatePicker
        label={t('translation:toDate')}
        shouldDisableDate={(date) => {
          return shouldDisableDate(date);
        }}
        value={props.value}
        disablePast={true}
        onChange={(date: Moment) => {
          if (!date || !date.isValid()) return;
          props.onChange(date.toDate());
        }}
        renderInput={(params) => {
          return <TextField {...params} sx={{ width: '100%' }} helperText={endDateHelper} />;
        }}
      />
    );
  }

  return (
    <DateTimePicker
      label={t('translation:toDate')}
      shouldDisableDate={(date) => {
        return shouldDisableDate(date);
      }}
      shouldDisableTime={(timeValue, clockType) => {
        if (clockType === 'hours') {
          return shouldDisableHour(timeValue);
        }

        if (clockType === 'minutes') {
          return shouldDisableMinute(timeValue);
        }

        return false;
      }}
      renderInput={(params) => {
        return <TextField {...params} sx={{ width: '100%' }} helperText={endDateHelper} />;
      }}
      value={props.value}
      onChange={(date: Moment, keyboardInputValue?: string) => {
        if (!date || !date.isValid()) return;
        props.onChange(date.toDate());
      }}
    />
  );
}
