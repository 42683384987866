import { useAppSelector } from '../../hooks';
import { isDepotManager } from '../account/customerSlice';
import { ReservationsDepotManager } from './ReservationsDepotManager';
import { ReservationsUser } from './ReservationsUser';

export const ReservationsScreen = () => {
  if (useAppSelector(isDepotManager)) return <ReservationsDepotManager />;

  return <ReservationsUser />;
};
