import { PedalBike } from '@mui/icons-material';
import { Box, ListItemText, Menu, MenuItem } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import { ReadableStore } from '../../../apis/shopizer';
import { useAppSelector } from '../../../hooks';
import { selectActiveCustomer } from '../../account/customerSlice';

export const AssetType = ({
  selectedDepot,
  selectedAssetType,
  setAssetType
}: {
  selectedDepot: ReadableStore;
  selectedAssetType: string;
  setAssetType: (assetType: string | null) => void;
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleCloseMenu = () => setAnchorEl(null);

  const managerDepots = useAppSelector(selectActiveCustomer).managerDepots;

  let assetTypes: string[] = !!selectedDepot
    ? selectedDepot.assetTypes
    : managerDepots.reduce((total, d) => [...total, ...d.assetTypes], []);

  assetTypes = assetTypes.filter(
    (value, index, array) => array.indexOf(value) === index
  );

  const setSelectedAssetType = (assetType: string | null) => {
    setAssetType(assetType);
    handleCloseMenu();
  };

  useEffect(() => {
    if (
      selectedDepot &&
      !selectedDepot.assetTypes.includes(selectedAssetType)
    ) {
      setAssetType(null);
    }
  }, [selectedDepot]);

  if (assetTypes.length === 0) return <></>;
  return (
    <Grid item>
      <Button
        sx={{ px: 1, minWidth: 1, textTransform: 'none', fontWeight: 300 }}
        onClick={(event) => setAnchorEl(event.currentTarget)}
        variant={selectedAssetType ? 'contained' : 'text'}
      >
        <PedalBike />
        {selectedAssetType ? <Box ml={1}>{selectedAssetType}</Box> : ''}
      </Button>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem
          onClick={() => {
            setSelectedAssetType(null);
          }}
        >
          <ListItemText primary={'All'} />
        </MenuItem>
        {assetTypes.map((assetType) => {
          return (
            <MenuItem
              key={assetType}
              onClick={() => {
                setSelectedAssetType(assetType);
              }}
            >
              <ListItemText primary={assetType} />
            </MenuItem>
          );
        })}
      </Menu>
    </Grid>
  );
};
