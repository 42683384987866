import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications';
import { ReadableCustomer } from '../../apis/shopizer';
import { WrapperWithToolbar } from '../../components/wrapper/WrapperWithToolbar';
import { isValidValue } from '../../core/Helpers/helper';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useLanguage } from '../../store/LanguageProvider';
import { updateCurrentCustomer } from '../../webServices/customerService';
import { fetchCustomerProfile, selectActiveCustomer } from './customerSlice';
import { selectAllDepots, selectActiveDepot } from '../depot/depotSlice';
import { CountryDropdownI18n } from '../../components/CountryDropdownI18n';
import MuiPhoneNumber from 'material-ui-phone-number';

export const AccountScreen = () => {
  const { t } = useTranslation('translation');
  const dispatch = useAppDispatch();
  const customer = useAppSelector(selectActiveCustomer);
  const { handleLanguage } = useLanguage();
  const classes = useStyles();
  const [payload, setPayload] = useState<ReadableCustomer>(null);
  const [showErrors, setShowErrors] = useState(false);
  const [changedFields, setChangedFields] = useState([]);
  const depots = useAppSelector(selectAllDepots);
  const activeDepot = useAppSelector(selectActiveDepot);

  const valid =
    isValidValue(payload?.billing?.firstName) &&
    isValidValue(payload?.billing?.lastName) &&
    isValidValue(payload?.language) &&
    payload.readableMerchantStore.nonPaying === true || (
      isValidValue(payload?.billing?.phone) &&
      isValidValue(payload?.billing?.address) &&
      isValidValue(payload?.billing?.postalCode) &&
      isValidValue(payload?.billing?.city) &&
      isValidValue(payload?.billing?.country)
    );

  useEffect(() => {
    if (customer) {
      setPayload(customer);
    }
  }, [customer]);

  const onSave = async () => {
    setShowErrors(true);
    if (!valid) {
      NotificationManager.error(t('message.formFillError'));
      return;
    }
    await updateCurrentCustomer(payload);
    dispatch(fetchCustomerProfile());
    setShowErrors(false);
    NotificationManager.success(t('message.accountDataSaved'));
  };

  const fieldChanged = (fieldKey: string) => {
    setChangedFields((fields) => {
      fields.push(fieldKey);
      return fields;
    });
  };

  const fieldHasError = (fieldKey: string, value) => {
    return (
      !isValidValue(value) && (showErrors || changedFields.includes(fieldKey))
    );
  };

  const showContent = () => {
    if (!payload || !payload.id) return;

    return (
      <>
        <Grid container spacing={4} sx={{ p: 2 }}>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'h6'}>{t('customerData')}</Typography>

            <TextField
              required
              disabled={true}
              id="outlined-emailAddress"
              label={t('emailAddress')}
              className={classes.customerInput}
              value={payload.emailAddress}
              margin="normal"
              variant="outlined"
            />

            <TextField
              required
              id="outlined-firstName"
              label={t('firstName')}
              className={classes.customerInput}
              value={payload.billing.firstName}
              error={fieldHasError(
                'billing.firstName',
                payload.billing.firstName
              )}
              onChange={(e) => {
                fieldChanged('billing.firstName');
                setPayload((current) => {
                  return {
                    ...current,
                    billing: {
                      ...current.billing,
                      firstName: e.target.value
                    }
                  };
                });
              }}
              margin="normal"
              variant="outlined"
            />

            <TextField
              required
              id="outlined-lastName"
              label={t('lastName')}
              className={classes.customerInput}
              value={payload.billing.lastName}
              error={fieldHasError(
                'billing.lastName',
                payload.billing.lastName
              )}
              onChange={(e) => {
                fieldChanged('billing.lastName');
                setPayload((current) => {
                  return {
                    ...current,
                    billing: {
                      ...current.billing,
                      lastName: e.target.value
                    }
                  };
                });
              }}
              margin="normal"
              variant="outlined"
            />
          <FormControl margin='normal'>
            <InputLabel id="preferenceLocationLabel">{t('preferredLocation')}</InputLabel>
            <Select 
              id={'preferenceLocation'} 
              className={classes.customerInput}
              label={t('preferredLocation')} 
              variant="outlined"
              value={payload.preferredStoreCode && payload.preferredStoreCode.length > 0 ? payload.preferredStoreCode : '-'}
              onChange={(e) => {
                fieldChanged('preferenceLocation');
                setPayload((current) => {
                  return {
                    ...current,
                    preferredStoreCode: e.target.value == '-' ? '' : e.target.value
                  };
                });
              }}
              >
                <MenuItem value='-'>{t('noPreference')}</MenuItem>
                {depots.map((d) => (
                  <MenuItem value={d.code}>{d.name}</MenuItem>
                ))}
            </Select>
          </FormControl>

          </Grid>
          {!!payload.readableMerchantStore.nonPaying === false && (
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'h6'}>{t('billing')}</Typography>

            <MuiPhoneNumber 
              id="outlined-mobile"
              defaultCountry='nl'
              value={payload.billing.phone}
              inputClass={classes.customerInput}
              label={t('translation:phone')}
              error={fieldHasError('billing.phone', payload.billing.phone)}
              onChange={(value) => {
                fieldChanged('billing.phone');
                setPayload((current) => {
                  return {
                    ...current,
                    billing: {
                      ...current.billing,
                      phone: value
                    }
                  };
                });
              }}
              margin="normal"
              variant="outlined"
            />

            <TextField
              required
              id="outlined-address"
              label={t('address')}
              className={classes.customerInput}
              value={payload.billing.address}
              error={fieldHasError('address', payload.billing.address)}
              onChange={(e) => {
                fieldChanged('address');
                setPayload((current) => {
                  return {
                    ...current,
                    billing: {
                      ...current.billing,
                      address: e.target.value
                    }
                  };
                });
              }}
              margin="normal"
              variant="outlined"
            />

            <TextField
              required
              id="outlined-postalCode"
              label={t('postalCode')}
              className={classes.customerInput}
              value={payload.billing.postalCode}
              error={fieldHasError(
                'billing.postalCode',
                payload.billing.postalCode
              )}
              onChange={(e) => {
                fieldChanged('billing.postalCode');
                setPayload((current) => {
                  return {
                    ...current,
                    billing: {
                      ...current.billing,
                      postalCode: e.target.value
                    }
                  };
                });
              }}
              margin="normal"
              variant="outlined"
            />

            <TextField
              required
              id="outlined-city"
              label={t('city')}
              className={classes.customerInput}
              value={payload.billing.city}
              error={fieldHasError('billing.city', payload.billing.city)}
              onChange={(e) => {
                fieldChanged('billing.city');
                setPayload((current) => {
                  return {
                    ...current,
                    billing: {
                      ...current.billing,
                      city: e.target.value
                    }
                  };
                });
              }}
              margin="normal"
              variant="outlined"
            />
            <div className="block my-1" />
            <CountryDropdownI18n
                id="outlined-country"
                className={classes.customerInput}
                selectedCountry={payload.billing.country}
                onChange={(selectedCountry) => {
                  fieldChanged('billing.country');
                  setPayload((current) => {
                    return {
                      ...current,
                      billing: {
                        ...current.billing,
                        country: selectedCountry.toString()
                      }
                    };
                  });
                }}
            />

          </Grid>
          )}          
        </Grid>
      </>
    );
  };

  return (
    <WrapperWithToolbar
      onSave={onSave}
      headerLeft={
        <>
          <Avatar />
          <Typography ml={2} variant={'h6'}>
            {payload?.billing?.firstName} {payload?.billing?.lastName}
          </Typography>
        </>
      }
    >
      {showContent()}
    </WrapperWithToolbar>
  );
};

const useStyles = makeStyles((theme) => ({
  customerInput: {
    width: '100%'
  }
}));
