import moment from 'moment';
import { createContext, useContext, useState } from 'react';
import { selectOrganization } from '../feature/organization/organizationSlice';
import { useAppSelector } from '../hooks';

type HandlePeriodType = (startDate: Date, endDate: Date) => void;

type PeriodContextType = {
  period: number;
  handlePeriod: HandlePeriodType;
};

const PeriodContext = createContext<PeriodContextType>(null);

export function usePeriod(): PeriodContextType {
  return useContext(PeriodContext);
}

export function PeriodProvider({ children }) {
  const [period, setPeriod] = useState<number>(1);
  const organization = useAppSelector(selectOrganization);

  const handlePeriod: HandlePeriodType = (startDate, endDate) => {
    const start = moment(startDate);
    const end = moment(endDate);

    setPeriod(
      Math.ceil(
        moment.duration(end.diff(start)).asMinutes() /
          Number(organization.bookingUnit)
      )
    );
  };

  return (
    <PeriodContext.Provider value={{ period, handlePeriod }}>
      {children}
    </PeriodContext.Provider>
  );
}
