import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import moment from 'moment';
import { createApiThunk } from '../../apis';
import {
  GetKpisUsingGETRequest,
  GetRevenueKpisUsingGETRequest,
  GetUsageKpisUsingGETRequest,
  KpiRevenue,
  KpiUsage
} from '../../apis/shopizer';
import { RootState } from '../../redux';
import {
  generatePeriodLast7Days,
  generatePeriodThisWeek
} from './kpiFilters/Period';

export type IKpiPeriod = {
  key: string;
  startDate: string;
  endDate: string;
  label: string;
  buttonLabel: string;
};

export type KpiState = {
  overdueCount: number;
  overdueFinishedCount: number;
  unusedCount: number;
  revenueAmount: string;
  usageRatio: number;
  usage: KpiUsage[];
  revenue: KpiRevenue[];
  isLoadingFor: string[];
  filters?: {
    period?: IKpiPeriod;
    depotCode?: string;
    assetType?: string;
  };
  filtersUsage?: {
    period?: IKpiPeriod;
  };
  filtersRevenue?: {
    period?: IKpiPeriod;
  };
};

export const initialState = {
  filters: { period: generatePeriodLast7Days() },
  filtersUsage: {
    period: generatePeriodThisWeek()
  },
  filtersRevenue: {
    period: generatePeriodThisWeek()
  }
} as KpiState;

export const kpiSlice = createSlice({
  name: 'kpi',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // KPIs ------------------------------
    builder.addCase(fetchKpis.pending, (state, action) => {
      if (!state.isLoadingFor) state.isLoadingFor = [];
      state.isLoadingFor.push('fetchKpis');
    });

    builder.addCase(fetchKpis.rejected, (state, action) => {
      state.isLoadingFor = state.isLoadingFor.filter((v) => v !== 'fetchKpis');
    });

    builder.addCase(fetchKpis.fulfilled, (state, action) => {
      state.overdueCount = action.payload.overdueCount;
      state.overdueFinishedCount = action.payload.overdueFinishedCount;
      state.unusedCount = action.payload.unusedCount;
      state.revenueAmount = action.payload.revenueAmount;
      state.usageRatio = Number(action.payload.usageRatio);

      state.isLoadingFor = state.isLoadingFor.filter((v) => v !== 'fetchKpis');
    });

    builder.addCase(setFilterPeriod.pending, (state, action) => {
      if (!state.filters) state.filters = {};
      state.filters.period = action.meta.arg;
    });

    builder.addCase(setFilterDepot.pending, (state, action) => {
      if (!state.filters) state.filters = {};
      state.filters.depotCode = action.meta.arg;
    });

    builder.addCase(setFilterAssetType.pending, (state, action) => {
      if (!state.filters) state.filters = {};
      state.filters.assetType = action.meta.arg;
    });

    builder.addCase(resetFilters.pending, (state, action) => {
      state.filters = initialState.filters;
    });

    // KPI - Usage ------------------------------
    builder.addCase(fetchKpiUsage.pending, (state, action) => {
      if (!state.isLoadingFor) state.isLoadingFor = [];
      state.isLoadingFor.push('fetchKpiUsage');
    });

    builder.addCase(fetchKpiUsage.rejected, (state, action) => {
      state.isLoadingFor = state.isLoadingFor.filter(
        (v) => v !== 'fetchKpiUsage'
      );
    });

    builder.addCase(fetchKpiUsage.fulfilled, (state, action) => {
      state.usage = action.payload;
      state.isLoadingFor = state.isLoadingFor.filter(
        (v) => v !== 'fetchKpiUsage'
      );
    });

    builder.addCase(setUsageFilterPeriod.pending, (state, action) => {
      if (!state.filtersUsage) state.filtersUsage = {};
      state.filtersUsage.period = action.meta.arg;
    });

    // KPI - Revenue ------------------------------
    builder.addCase(fetchKpiRevenue.pending, (state, action) => {
      if (!state.isLoadingFor) state.isLoadingFor = [];
      state.isLoadingFor.push('fetchKpiRevenue');
    });

    builder.addCase(fetchKpiRevenue.rejected, (state, action) => {
      state.isLoadingFor = state.isLoadingFor.filter(
        (v) => v !== 'fetchKpiRevenue'
      );
    });

    builder.addCase(fetchKpiRevenue.fulfilled, (state, action) => {
      state.revenue = action.payload;
      state.isLoadingFor = state.isLoadingFor.filter(
        (v) => v !== 'fetchKpiRevenue'
      );
    });

    builder.addCase(setRevenueFilterPeriod.pending, (state, action) => {
      if (!state.filtersRevenue) state.filtersRevenue = {};
      state.filtersRevenue.period = action.meta.arg;
    });
  }
});

export const selectFilterPeriod = (state: RootState): IKpiPeriod | null => {
  return state.kpi?.filters?.period ?? initialState.filters.period;
};

export const selectFilterDepotCode = (state: RootState): string | null => {
  return state.kpi?.filters?.depotCode;
};

export const selectFilterAssetType = (state: RootState): string | null => {
  return state.kpi?.filters?.assetType;
};

export const selectOverdueCount = (state: RootState): number | null => {
  return state.kpi?.overdueCount;
};

export const selectOverdueFinishedCount = (state: RootState): number | null => {
  return state.kpi?.overdueFinishedCount;
};

export const selectUnusedCount = (state: RootState): number | null => {
  return state.kpi?.unusedCount;
};

export const selectRevenueAmount = (state: RootState): string | null => {
  return state.kpi?.revenueAmount;
};

export const selectUsageRatio = (state: RootState): number | null => {
  return state.kpi?.usageRatio;
};

export const selectIsLoadingKpis = (state: RootState): boolean => {
  return (
    _.isArray(state.kpi?.isLoadingFor) &&
    state.kpi.isLoadingFor.includes('fetchKpis')
  );
};

export const selectHasFilters = (state: RootState): boolean => {
  return !_.isEqual(state.kpi.filters, initialState.filters);
};

export const resetFilters = createAsyncThunk(
  'kpi/resetFilters',
  async (_, { dispatch }) => {
    dispatch(fetchKpis());
  }
);

export const setFilterPeriod = createAsyncThunk(
  'kpi/setFilterPeriod',
  async (period: IKpiPeriod | null, { dispatch }) => {
    dispatch(fetchKpis());
  }
);

export const setFilterDepot = createAsyncThunk(
  'kpi/setFilterDepot',
  async (depotCode: string | null, { dispatch }) => {
    dispatch(fetchKpis());
  }
);

export const setFilterAssetType = createAsyncThunk(
  'kpi/setFilterAssetType',
  async (depotCode: string | null, { dispatch }) => {
    dispatch(fetchKpis());
  }
);

// KPI - Usage ------------------------------
export const selectUsageFilterPeriod = (
  state: RootState
): IKpiPeriod | null => {
  return state.kpi?.filtersUsage?.period ?? initialState.filtersUsage.period;
};

function generateCategoriesFromPeriod(period: IKpiPeriod) {
  const startDate = moment(period.startDate);
  const endDate = moment(period.endDate);
  const categories = [];

  while (startDate < endDate) {
    categories.push(moment(startDate).format('DD.MM'));
    startDate.add(1, 'days');
  }

  return categories;
}

export const selectUsage = (
  state: RootState
): {
  categories: string[];
  values: { name: string; data: number[] }[];
  max: number;
} => {
  const response = {
    categories: [],
    values: [],
    max: 100
  };

  const period = selectUsageFilterPeriod(state);

  if (!period) return response;

  response.categories = generateCategoriesFromPeriod(period);
  if (!state.kpi?.usage) return response;

  response.values = state.kpi?.usage.map((d) => {
    response.max = _.max([...d.data, response.max]);

    return {
      name: d.name,
      data: d.data
    };
  });

  return response;
};

export const selectIsLoadingUsages = (state: RootState): boolean => {
  return (
    _.isArray(state.kpi?.isLoadingFor) &&
    state.kpi.isLoadingFor.includes('fetchKpiUsage')
  );
};

export const setUsageFilterPeriod = createAsyncThunk(
  'kpi/setUsageFilterPeriod',
  async (period: IKpiPeriod | null, { dispatch }) => {
    dispatch(fetchKpiUsage());
  }
);

// KPI - Revenue ------------------------------
export const selectRevenueFilterPeriod = (state: RootState): IKpiPeriod => {
  return (
    state.kpi?.filtersRevenue?.period ?? initialState.filtersRevenue.period
  );
};

export const selectRevenue = (
  state: RootState
): {
  categories: string[];
  values: { name: string; data: number[] }[];
  max: number;
} => {
  const response = {
    categories: [],
    values: [],
    max: 100
  };

  const period: IKpiPeriod = selectRevenueFilterPeriod(state);
  response.categories = generateCategoriesFromPeriod(period);
  if (!state.kpi?.revenue) return response;

  response.values = state.kpi?.revenue.map((d) => {
    response.max = _.max([...d.data, response.max]);

    return {
      name: d.name,
      data: d.data
    };
  });

  return response;
};

export const selectIsLoadingRevenue = (state: RootState): boolean => {
  return (
    _.isArray(state.kpi?.isLoadingFor) &&
    state.kpi.isLoadingFor.includes('fetchKpiRevenue')
  );
};

export const setRevenueFilterPeriod = createAsyncThunk(
  'kpi/setRevenueFilterPeriod',
  async (period: IKpiPeriod | null, { dispatch }) => {
    dispatch(fetchKpiRevenue());
  }
);

export const fetchKpis = createApiThunk(
  'kpi/fetch',
  async (_, { shopizerApi, getState }) => {
    const filters = getState().kpi.filters;
    const period = selectFilterPeriod(getState());
    const request: GetKpisUsingGETRequest = {
      from: moment(period.startDate).toISOString(),
      to: moment(period.endDate).toISOString()
    };

    if (filters?.depotCode) {
      request.storeCode = filters.depotCode;
    }

    if (filters?.assetType) {
      request.asset = filters.assetType;
    }

    const reservationKpis = await shopizerApi.orderApi.getKpisUsingGET(request);

    return reservationKpis;
    return {
      overdueCount: reservationKpis.overdueCount,
      overdueFinishedCount: reservationKpis.overdueFinishedCount,
      unusedCount: reservationKpis.unusedCount,
      revenueAmount: reservationKpis.revenueAmount,
      usageRatio: Number(reservationKpis.usageRatio)
    };
  }
);

export const fetchKpiUsage = createApiThunk(
  'kpi/fetchUsage',
  async (_, { shopizerApi, getState, dispatch }) => {
    const period = selectUsageFilterPeriod(getState());
    const request: GetUsageKpisUsingGETRequest = {
      from: moment(period.startDate).toISOString(),
      to: moment(period.endDate).toISOString()
    };

    return await shopizerApi.orderApi.getUsageKpisUsingGET(request);
  }
);

export const fetchKpiRevenue = createApiThunk(
  'kpi/fetchRevenue',
  async (_, { shopizerApi, getState, dispatch }) => {
    const period = selectRevenueFilterPeriod(getState());

    const request: GetRevenueKpisUsingGETRequest = {
      from: period.startDate,
      to: period.endDate
    };

    return await shopizerApi.orderApi.getRevenueKpisUsingGET(request);
  }
);

export const kpiReducer = kpiSlice.reducer;
