import { Delete, SportsScore } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications';
import { ConfirmModal } from '../../components/confirm-modal/ConfirmModal';
import { useLanguage } from '../../store/LanguageProvider';
import { cancelOrder, finishOrder } from '../../webServices/orderService';

export function ChangeReservation({
  reservation,
  refreshList
}: {
  reservation: {
    reservationId?: string | number;
    allowEnd?: boolean;
    allowCancel?: boolean;
  };
  refreshList?: () => void;
}) {
  const { t } = useTranslation('translation');
  const { language } = useLanguage();

  const endReservation = async () => {
    await finishOrder(Number(reservation.reservationId));
    if (refreshList) refreshList();
  };

  const cancelReservation = async () => {
    const response: { success: boolean; reason: string } = await cancelOrder(
      Number(reservation.reservationId),
      language
    );

    if (response.success) {
      if (refreshList) refreshList();
      NotificationManager.success(response.reason);
    } else {
      NotificationManager.error(response.reason);
    }
  };

  return (
    <>
      {reservation.allowEnd && (
        <ConfirmModal
          callback={endReservation}
          message={t('confirm.end.reservation')}
        >
          <SportsScore sx={{ cursor: 'pointer' }} />
        </ConfirmModal>
      )}

      {reservation.allowCancel && (
        <ConfirmModal
          callback={cancelReservation}
          message={t('confirm.cancel.reservation')}
        >
          <Delete sx={{ cursor: 'pointer' }} />
        </ConfirmModal>
      )}
    </>
  );
}
