import moment from 'moment';
import UserService from '../core/auth/userService';
import { parseProductsForFrontend } from '../feature/products/productService';
import { ApiProductsResponse, ProductType } from '../types/product';
import WebService from './webService';
import { Buffer } from 'buffer';

export type GetProductsRequestProps = {
  store: string;
  startDate: Date;
  endDate: Date;
  language: string;
  selectedEmail: string;
};


export const getPath = (
  request: GetProductsRequestProps,
  log: boolean = false
) => {
  const dateFormat = 'YYYY-MM-DDTHH:mm:ss.SSS';
  const startDate = moment(request.startDate).format(dateFormat);
  const endDate = moment(request.endDate).format(dateFormat);
  
  var selectedEmail = null;
  var encoded = null;
  
  if (UserService.isLoggedIn()) {
	selectedEmail = '' + request.selectedEmail;
	encoded = Buffer.from(selectedEmail, 'utf-8').toString('base64') 
  }

  const path =
    (!UserService.isLoggedIn() ? '/public' : '') +
    `/nazza-products?startDate=${startDate}&endDate=${endDate}&store=${request.store}&selectedEmail=${encoded}&lang=${request.language}&amount=1&page=0&count=50`;

  if (log) {
    console.log('----- get path');
    console.log(startDate);
    console.log(endDate);
    console.log(path);
    console.log('');
    console.log('');
  }
  return path;
};

export const getAllProducts = async (
  request: GetProductsRequestProps
): Promise<ProductType[]> => {
  const productsResponse: ApiProductsResponse = await WebService.get(
    getPath(request, false)
  );
  const products = parseProductsForFrontend(
    productsResponse.products,
    request.startDate,
    request.endDate
  );
  const productsToReturn: ProductType[] = [];
  products.forEach((p) => {
    const found = productsToReturn.find((pr) => pr.assetType === p.assetType);
    if (!found) {
      productsToReturn.push(p);
    }
  });
  if (productsResponse.errorCode) {
	productsToReturn.push(null);
  }
  return productsToReturn;
};
