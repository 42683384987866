import { Cancel } from '@mui/icons-material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

export const Reset = ({ onClick }: { onClick: () => void }) => {
  return (
    <Grid item>
      <Button variant={'text'} sx={{ px: 0, minWidth: 1 }} onClick={onClick}>
        <Cancel />
      </Button>
    </Grid>
  );
};
