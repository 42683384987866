import { Add, Event, LocationOn, Person } from '@mui/icons-material';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/styles';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Wrapper from '../../components/wrapper/Wrapper';
import { formatDate } from '../../core/Helpers/helper';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useLanguage } from '../../store/LanguageProvider';
import { selectActiveCustomer } from '../account/customerSlice';
import {
  fetchReservations,
  selectActiveDepots,
  selectActiveReservationsCount,
  selectNextReservation,
  selectReservationsCount
} from '../reservations/reservationSlice';
import { openGoogleMaps, ShopReservationsList } from './ShopReservationsList';
import { StatCard } from './StatCard';
import { selectOrganization } from '../organization/organizationSlice';

export const HomeUser = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation('translation');
  const theme = useTheme();
  const customer = useAppSelector(selectActiveCustomer);
  const { language } = useLanguage();
  const depots = useAppSelector(selectActiveDepots);
  const nextReservation = useAppSelector(selectNextReservation);
  const reservationsCount = useAppSelector(selectReservationsCount);
  const activeReservationsCount = useAppSelector(selectActiveReservationsCount);
  const organization = useAppSelector(selectOrganization);

  useEffect(() => {
    if (!language) return;
    dispatch(fetchReservations({ language }));
  }, [language]);

  if (organization.supportReservations) {
    return (
      <Wrapper>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <StatCard
              type="fill"
              title={t('welcome')}
              value={customer ? customer.emailAddress : ''}
              icon={<Person />}
              color={theme.palette.secondary.light}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <StatCard
              type="fill"
              title={t('reservations')}
              value={t('reservationsNumber', {
                activeReservationsCount,
                reservationsCount
              })}
              onClick={() => {
                navigate('/products/travel');
              }}
              icon={<Add />}
              color="#9c27b0"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <StatCard
              type="fill"
              title={t('next_reservation_locations')}
              onClick={() => {
                if (!nextReservation) return;

                openGoogleMaps(nextReservation.depot);
              }}
              value={nextReservation ? nextReservation.depot.name : ''}
              icon={<LocationOn />}
              color="#f44336"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <StatCard
              type="fill"
              title={t('next_reservation_start_date')}
              value={nextReservation ? formatDate(nextReservation.startDate) : ''}
              icon={<Event />}
              color="#009688"
            />
          </Grid>

          {depots.map((depot) => (
            <ShopReservationsList depot={depot} />
          ))}
        </Grid>
      </Wrapper>
    );
  }
  else {
    return (
      <Wrapper>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <StatCard
              type="fill"
              title={t('welcome')}
              value={customer ? customer.emailAddress : ''}
              icon={<Person />}
              color={theme.palette.secondary.light}
            />
          </Grid>
      </Grid>
      </Wrapper>
    );
  }
};
