import { Grid } from '@mui/material';
import moment, { duration } from 'moment';
import { useEffect, useState } from 'react';
import { ReadableMerchantStore } from '../../../apis/shopizer';
import {
  getDateTimeFromBackendFormat,
  getDateTimeFromBackendPeriod
} from '../../../core/Helpers/helper';
import { useUrlQuery } from '../../../core/routes/useUrlQuery';
import { useAppSelector } from '../../../hooks';
import { GetProductsRequestProps } from '../../../webServices/productService';
import { selectActiveDepot } from '../../depot/depotSlice';
import {
  Organization,
  selectIsBookingUnitDays,
  selectOrganization
} from '../../organization/organizationSlice';
import { PeriodDurationSlider } from './PeriodDurationSlider';
import { PeriodEndDate } from './PeriodEndDate';
import { PeriodStartDate } from './PeriodStartDate';
import TextField from '@mui/material/TextField';
import UserService from '../../../core/auth/userService';
import { isDepotManager } from '../../account/customerSlice';
import { useTranslation } from 'react-i18next';

type SelectPeriodProps = {
  onChange: (
    value:
      | ((prevState: GetProductsRequestProps) => GetProductsRequestProps)
      | GetProductsRequestProps
  ) => void;
  productsRequest: GetProductsRequestProps;
};

export type OptionType = {
  quantity: number;
  minutes: number;
  display: string;
  m: number;
  d: number;
  h: number;
};

const getQuantityFromDates = (
  startDate: Date,
  endDate: Date,
  durationConfig: DurationConfigType
): number => {
  const calculatedQuantity = Math.round(
    moment.duration(moment(endDate).diff(moment(startDate))).asMinutes() /
      durationConfig.bookingUnit
  );

  if (calculatedQuantity > durationConfig.maximumQuantity) {
    return durationConfig.maximumQuantity;
  }

  if (calculatedQuantity < durationConfig.minimumQuantity) {
    return durationConfig.minimumQuantity;
  }

  return calculatedQuantity;
};

const getEndDateFromStartDateAndQuantity = (
  startDate: Date,
  quantity: number,
  durationConfig: DurationConfigType
): Date => {
  return moment(startDate)
    .add(quantity * durationConfig.bookingUnit, 'minutes')
    .subtract(1, 'millisecond')
    .toDate();
};

export type DurationConfigType = {
  bookingUnit: number;
  maximumQuantity: number;
  minimumQuantity: number;
  startTimeGranularity: number;
  referenceDate: string;
  startDatePeriod: string;
  fixedStartPeriod: boolean;
  endDatePeriod: string;
  activeDepotCode?: string;
  startDatePeriodMax?: number;
  startDateHelperText? : { [key: string]: string };
  endDateHelperText? : { [key: string]: string };
  disableStartHours? : number[];
  disableEndHours? : number[];
  disableDays? : number[];
};

const getDurationConfig = (
  organization: Organization,
  activeDepot?: ReadableMerchantStore
): DurationConfigType => {
  let config: DurationConfigType = {
    bookingUnit: Number(organization.bookingUnit),
    minimumQuantity: organization.minimumQuantity,
    maximumQuantity: organization.maximumQuantity,
    endDatePeriod: organization.endDatePeriod,
    referenceDate: organization.referenceDate,
    startDatePeriod: organization.startDatePeriod,
    startDatePeriodMax: organization.startDatePeriodMax,
    startTimeGranularity: organization.startTimeGranularity,
    fixedStartPeriod: organization.fixedStartPeriod,
    startDateHelperText: organization.startDateHelperText,
    endDateHelperText: organization.endDateHelperText,
    disableStartHours: organization.bookingDatesConfig?.disableStartHours,
    disableEndHours: organization.bookingDatesConfig?.disableEndHours,
    disableDays: organization.bookingDatesConfig?.disableDays
  };

  if (activeDepot) {
    const depotConfig = Object.fromEntries(
      Object.entries({
        bookingUnit: Number(activeDepot.bookingUnit),
        minimumQuantity: activeDepot.minimumQuantity,
        maximumQuantity: activeDepot.maximumQuantity,
        endDatePeriod: activeDepot.endDatePeriod,
        referenceDate: activeDepot.referenceDate,
        startDatePeriod: activeDepot.startDatePeriod,
        startDatePeriodMax: activeDepot.startDatePeriodMax,
        startTimeGranularity: activeDepot.startTimeGranularity,
        fixedStartPeriod: activeDepot.fixedStartPeriod,
        startDateHelperText: activeDepot.startDateHelperText,
        endDateHelperText: activeDepot.endDateHelperText
          }).filter(([_, v]) => v != null)
    );

    config = {
      ...config,
      ...depotConfig
    };
  }

  if (!config.minimumQuantity || config.minimumQuantity < 1) {
    config.minimumQuantity = 1;
  }

  if (!config.maximumQuantity || config.maximumQuantity < 1) {
    config.maximumQuantity = config.bookingUnit > 59 ? 21 : 50;
  }

  return config;
};

export function SelectPeriod(props: SelectPeriodProps) {
  const { productsRequest, onChange: setProductsRequest } = props;

  const organization = useAppSelector(selectOrganization);
  const activeDepot = useAppSelector(selectActiveDepot);
  const isBookingUnitDays = useAppSelector(selectIsBookingUnitDays);
  const [quantity, setQuantity] = useState<number | null>();
  const [options, setOptions] = useState<OptionType[]>([]);
  
  const { t } = useTranslation('translation');
  const isDM = useAppSelector(isDepotManager);
  
  const [lastChanged, setLastChanged] = useState<'period' | 'endDate'>(
    'period'
  );

  const startDateP = useUrlQuery().get('from');
  const endDateP = useUrlQuery().get('to');
  const durationConfig = getDurationConfig(organization, activeDepot);

  useEffect(() => {
    initInitialDates();
  }, [organization.code, activeDepot?.code]);

  const initStartDate = () => {
    const startDateM = moment(startDateP);

    if (startDateM.isValid()) {
      if (isBookingUnitDays) startDateM.startOf('day');
      return startDateM.toDate();
    }

    if (durationConfig?.referenceDate) {
      return getDateTimeFromBackendPeriod(
        getDateTimeFromBackendFormat(durationConfig.referenceDate),
        durationConfig.startDatePeriod
      );
    }

    return moment().startOf('day').toDate();
  };

  const initEndDate = (newStartDate) => {
    const endDateM = moment(endDateP);

    if (endDateM.isValid()) {
      if (isBookingUnitDays) endDateM.endOf('day');
      return endDateM.toDate();
    }

    if (durationConfig?.referenceDate) {
      return getDateTimeFromBackendPeriod(
        newStartDate,
        durationConfig.endDatePeriod
      );
    }

    return moment().endOf('day').toDate();
  };

  const initInitialDates = () => {
    const newStartDate = initStartDate();
    const newEndDate = initEndDate(newStartDate);

    const quantity = getQuantityFromDates(
      newStartDate,
      newEndDate,
      durationConfig
    );

    const calculatedEndDate = getEndDateFromStartDateAndQuantity(
      newStartDate,
      quantity,
      durationConfig
    );
    
    setProductsRequest((current) => {
      return {
        ...current,
        startDate: newStartDate,
        endDate: calculatedEndDate
      };
    });

    setQuantity(quantity);
    setOptions(generateOptions());
  };

  const generateOptions = (): OptionType[] => {
    const options = [];

    for (
      let i = durationConfig.minimumQuantity;
      i <= durationConfig.maximumQuantity;
      i++
    ) {
      const minutes = i * durationConfig.bookingUnit;
      const hours = Math.floor(minutes / 60);

      const m = minutes - hours * 60;
      const d = Math.floor(hours / 24);
      const h = hours - d * 24;

      options.push({
        quantity: i,
        minutes,
        display: `${d ? d + 'days' : ''}  ${h}:${m}`,
        m,
        d,
        h
      });
    }

    return options;
  };
  
  

  const startDateChanged = (startDate: Date) => {
    const q =
      lastChanged === 'period'
        ? quantity
        : getQuantityFromDates(
            startDate,
            productsRequest.endDate,
            durationConfig
          );

    if (q !== quantity) setQuantity(q);
    
    setProductsRequest((current) => {
      return {
        ...current,
        startDate: startDate,
        endDate: getEndDateFromStartDateAndQuantity(
          startDate,
          q,
          durationConfig
        )
      };
    });
  };

  const endDateChanged = (endDate: Date) => {
    // if we have a day granularity and the end date does not have 23 hours,
    // it means the end date was changed by hand and should be corrected
    if (durationConfig.bookingUnit >= 1440 && endDate.getHours() == 0) {
      endDate.setHours(23);
      endDate.setMinutes(59);
      endDate.setSeconds(59);
      endDate.setMilliseconds(999);
    }
    const quantity = getQuantityFromDates(
      productsRequest.startDate,
      endDate,
      durationConfig
    );

    const calculatedEndDate = getEndDateFromStartDateAndQuantity(
      productsRequest.startDate,
      quantity,
      durationConfig
    );

    setQuantity(quantity);
    setProductsRequest((current) => {
      return {
        ...current,
        endDate: calculatedEndDate
      };
    });
    setLastChanged('endDate');
  };

  const quantityChanged = (quantity: number) => {
    setProductsRequest((current) => {
      return {
        ...current,
        endDate: getEndDateFromStartDateAndQuantity(
          productsRequest.startDate,
          quantity,
          durationConfig
        )
      };
    });

    setQuantity(quantity);
    setLastChanged('period');
  };
  
  
  
  
   const handleSelectedEmail = (event) => {
    setProductsRequest((current) => {
      return {
        ...current,
        selectedEmail: event.target.value
      };
    });
  };
  

  return (
    <Grid container spacing={2}>
    
    
    {UserService.isLoggedIn() && isDM && organization.supportAdminReservations && (
            <Grid item xs={12} sm={6}>
           <TextField sx={{ width: '100%' }}
                  label={t('translation:reservationEmail')}
                  id="outlined-selectedEmail"
                  value={productsRequest.selectedEmail}
                  onChange={handleSelectedEmail}
                  margin="dense"
                  hidden={true}
                />
            </Grid>
          )}
          
          
     {UserService.isLoggedIn() && isDM && organization.supportAdminReservations && (
            <Grid item xs={12} sm={6}>
            </Grid>
          )}

    
      <Grid item xs={12} sm={6}>
        <PeriodStartDate
          value={productsRequest.startDate}
          onChange={startDateChanged}
          durationConfig={durationConfig}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <PeriodEndDate
          value={productsRequest.endDate}
          startDate={productsRequest.startDate}
          options={options}
          onChange={endDateChanged}
          durationConfig={durationConfig}
        />
      </Grid>
      <Grid item xs={12}>
        <PeriodDurationSlider
          quantity={quantity}
          options={options}
          onChange={quantityChanged}
        />
      </Grid>
    </Grid>
  );
}
