import LinearProgress from '@mui/material/LinearProgress';

export function PeriodProgress({
  startDate,
  endDate
}: {
  endDate: Date;
  startDate: Date;
}) {
  const now = new Date();
  if (!startDate || !endDate || startDate > now) {
    return null;
  }

  if (endDate < now) {
    return (
      <LinearProgress color={'success'} variant="determinate" value={100} />
    );
  }

  const fullPeriod = endDate.getTime() - startDate.getTime();
  const currentPeriod = now.getTime() - startDate.getTime();
  const progress = Math.round((100 * currentPeriod) / fullPeriod);

  return <LinearProgress variant="determinate" value={progress} />;
}
