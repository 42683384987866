import { createAsyncThunk } from '@reduxjs/toolkit';
import { BaseThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { AsyncThunkOptions } from '@reduxjs/toolkit/src/createAsyncThunk';
import { useMemo } from 'react';

import UserService from '../core/auth/userService';
import { AppThunkConfig, DefaultAppThunkConfig, useAppStore } from '../hooks';
import { AppDispatch, RootState, StateDispatch } from '../redux';
import { newShopizerApiClient, ShopizerApiClient } from './shopizer';

/**
 * Makes an api client
 * @param store
 */
export const makeShopizerApiClient = (store: StateDispatch) => {
  return {
    ...newShopizerApiClient({
      headers: async () => {
        const headers = {};
        await UserService.updateToken(() => {
          headers['Authorization'] = 'Bearer ' + UserService.getToken();
        });

        return headers;
      }
    })
  };
};

/**
 * Uses an api client
 */
export const useShopizerApiClient = () => {
  const store = useAppStore();
  return useMemo(() => {
    return makeShopizerApiClient(store);
  }, [store]);
};

export interface FetchApi<Config extends AppThunkConfig>
  extends BaseThunkAPI<
    RootState,
    DefaultAppThunkConfig<Config>['extra'],
    AppDispatch,
    DefaultAppThunkConfig<Config>['rejectValue'],
    DefaultAppThunkConfig<Config>['rejectedMeta']
  > {
  shopizerApi: ShopizerApiClient;
}

export interface FetchCreateArgs {
  allowAnonymous?: boolean; // default false
}

export interface DefaultApiThunkConfig<Config> {
  state: RootState;
  dispatch: AppDispatch;
  extra: Config extends { extra: infer T } ? T : unknown;
  rejectValue: Config extends { rejectValue: infer T } ? T : unknown;
  serializedErrorType: Config extends { serializedErrorType: infer T }
    ? T
    : unknown;
  pendingMeta: Config extends { pendingMeta: infer T } ? T : unknown;
  rejectedMeta: Config extends { rejectedMeta: infer T } ? T : unknown;
}

export function createApiThunk<
  Returned,
  ThunkArg = undefined,
  ThunkApiConfig extends AppThunkConfig = {}
>(
  type: string,
  fetcher: (
    arg: ThunkArg,
    fetchAPI: FetchApi<ThunkApiConfig>
  ) => Promise<Returned>,
  options?: AsyncThunkOptions<ThunkArg, DefaultApiThunkConfig<ThunkApiConfig>> &
    FetchCreateArgs
) {
  return createAsyncThunk<
    Returned,
    ThunkArg,
    DefaultApiThunkConfig<ThunkApiConfig>
  >(
    type,
    async (arg, thunkAPI) => {
      return await fetcher(arg, {
        ...thunkAPI,
        shopizerApi: makeShopizerApiClient(thunkAPI)
      });
    },
    options
  );
}
