import Paper from '@mui/material/Paper';
import { createColumnHelper } from '@tanstack/react-table';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShopizerApiClient } from '../../apis';
import { ReadableOnboardingHistory } from '../../apis/shopizer';
import { useDialog } from '../../components/dialog/useDialog';
import {
  CustomColumnDef,
  DataTable,
  RequestParams,
  ReturnData
} from '../../components/tanStackTable/DataTable';
import Wrapper from '../../components/wrapper/Wrapper';
import { useLanguage } from '../../store/LanguageProvider';
import { WhiteListForm } from './WhiteListForm';

export const UsersHistoryScreen = () => {
  const { t } = useTranslation('translation');
  const { language } = useLanguage();
  const shopizerApiClient = useShopizerApiClient();

  const { open, handleOpen, handleClose } = useDialog();

  const columnHelper = createColumnHelper<ReadableOnboardingHistory>();
  const [refreshListCount, setRefreshListCount] = useState(0);

  useEffect(() => {
    setRefreshListCount((c) => c + 1);
  }, [language]);

  const refreshList = () => {
    setRefreshListCount((current) => current + 1);
  };

  const columns: CustomColumnDef[] = [
    {
      ...columnHelper.accessor('onboardingEmail', {
        header: t('emailAddress')
      }),
      valueType: 'string'
    },
    {
      ...columnHelper.accessor('location', {
        header: t('location')
      }),
      valueType: 'string'
    },
    {
      ...columnHelper.accessor('status', {
        header: t('status')
      }),
      valueType: 'string'
    },
    {
      ...columnHelper.accessor('actionUserEmail', {
        header: t('field.modifiedBy')
      }),
      valueType: 'string'
    },
    {
      ...columnHelper.accessor('eventDate', {
        header: t('field.modifiedDate'),
        enableColumnFilter: false,
        cell: (accessor) => {
          const checkValue = moment(accessor.getValue()).format('L');
          return <div>{checkValue}</div>;
        }
      }),
      valueType: 'date'
    }
  ];

  const fetchData = async (params: RequestParams): Promise<ReturnData> => {
    const response =
      await shopizerApiClient.customerApi.getOnboardingHistoryListUsingGET({
        ...params,
        lang: language,
        page: params.page - 1,
        filter: JSON.stringify(params.filter)
      });

    return {
      recordsTotal: response.totalCount,
      items: response.onboardingHistories
    };
  };

  return (
    <Wrapper>
      <h1>{t('menu.History')}</h1>
      <Paper sx={{ height: '100%', marginBottom: 2, padding: 0, p: 0 }}>
        <WhiteListForm
          type={'add'}
          open={open}
          onClose={handleClose}
          onSave={refreshList}
        />
        <DataTable
          columns={columns}
          requestType={'fetch'}
          refreshListCount={refreshListCount}
          fetch={fetchData}
          tableActions={[]}
        />
      </Paper>
    </Wrapper>
  );
};
