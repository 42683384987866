/* tslint:disable */
/* eslint-disable */
/**
 * Shopizer REST API
 * API for Shopizer e-commerce. Contains public end points as well as private end points requiring basic authentication and remote authentication based on jwt bearer token. URL patterns containing /private/_** use bearer token; those are authorized customer and administrators administration actions.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
  DynamicFieldsContent,
  EntityExists,
  Language,
  PersistableBrand,
  PersistableMerchantStore,
  ReadableBrand,
  ReadableFeedbackList,
  ReadableInfoContent,
  ReadableMerchantStore,
  ReadableMerchantStoreList,
  ReadableRegisterTexts,
  ReadableWhiteListRecords
} from '../models';
import * as runtime from '../runtime';

export interface AddLogoUsingPOSTRequest {
  code: string;
}

export interface ChildrenUsingGETRequest {
  code: string;
  count?: number;
  lang?: string;
  page?: number;
}

export interface CreateUsingPOST3Request {
  store: PersistableMerchantStore;
}

export interface CreateWhiteListUsingPOSTRequest {
  matchPattern: string;
  needsApproval: boolean;
  endDate?: string;
  lang?: string;
  startDate?: string;
  store?: string;
}

export interface DeleteStoreLogoUsingDELETERequest {
  code: string;
}

export interface DeleteUsingDELETE3Request {
  code: string;
}

export interface DeleteWhiteListUsingDELETERequest {
  id: string;
  lang?: string;
  store?: string;
}

export interface ExistsUsingGETRequest {
  code: string;
}

export interface ExistsUsingGET1Request {
  code: string;
}

export interface GetDepotsUsingGETRequest {
  code: string;
  count?: number;
  lang?: string;
  page?: number;
}

export interface GetDynamicFieldsContentUsingGETRequest {
  code: string;
  key: string;
}

export interface GetFeedbackUsingGETRequest {
  count?: number;
  filter?: string;
  lang?: string;
  page?: number;
  sortColumn?: string;
  sortOrder?: string;
}

export interface GetInfoContentUsingGETRequest {
  code: string;
  key: string;
}

export interface GetRegisterTextsUsingGETRequest {
  code: string;
  key: string;
}

export interface GetStoreMarketingUsingGETRequest {
  code: string;
}

export interface GetUsingGET1Request {
  count?: number;
  lang?: string;
  page?: number;
}

export interface GetWhiteListRecordsUsingGETRequest {
  accountNonExpired?: boolean;
  accountNonLocked?: boolean;
  authorities0Authority?: string;
  count?: number;
  credentialsNonExpired?: boolean;
  email?: string;
  enabled?: boolean;
  filter?: string;
  firstName?: string;
  id?: number;
  lang?: string;
  lastName?: string;
  page?: number;
  password?: string;
  sortColumn?: string;
  sortOrder?: string;
  username?: string;
}

export interface ListUsingGET1Request {
  code: string;
  count?: number;
  lang?: string;
  page?: number;
}

export interface ListUsingGET2Request {
  count?: number;
  page?: number;
}

export interface PublicStoreUsingGETRequest {
  code: string;
  lang?: string;
}

export interface SaveStoreMarketingUsingPOSTRequest {
  code: string;
  brand: PersistableBrand;
}

export interface StoreFullUsingGETRequest {
  code: string;
  lang?: string;
}

export interface StoreUsingGETRequest {
  code: string;
  lang?: string;
}

export interface SyncDepotsFromNazzaUsingPUTRequest {
  code: string;
}

export interface SyncFromNazzaUsingPUTRequest {
  code: string;
}

export interface UpdateUsingPUT3Request {
  code: string;
  store: PersistableMerchantStore;
}

export interface UpdateWhiteListUsingPUTRequest {
  id: string;
  endDate?: string;
  matchPattern?: string;
  needsApproval?: boolean;
  startDate?: string;
}

/**
 *
 */
export class MerchantAndStoreManagementResourceMerchantStoreManagementApiApi extends runtime.BaseAPI {
  /**
   * Add store logo
   */
  async addLogoUsingPOSTRaw(
    requestParameters: AddLogoUsingPOSTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.code === null ||
      requestParameters.code === undefined
    ) {
      throw new runtime.RequiredError(
        'code',
        'Required parameter requestParameters.code was null or undefined when calling addLogoUsingPOST.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/private/store/{code}/marketing/logo`.replace(
          `{${'code'}}`,
          encodeURIComponent(String(requestParameters.code))
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Add store logo
   */
  async addLogoUsingPOST(
    requestParameters: AddLogoUsingPOSTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.addLogoUsingPOSTRaw(requestParameters, initOverrides);
  }

  /**
   * Get child stores
   */
  async childrenUsingGETRaw(
    requestParameters: ChildrenUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ReadableMerchantStoreList>> {
    if (
      requestParameters.code === null ||
      requestParameters.code === undefined
    ) {
      throw new runtime.RequiredError(
        'code',
        'Required parameter requestParameters.code was null or undefined when calling childrenUsingGET.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.count !== undefined) {
      queryParameters['count'] = requestParameters.count;
    }

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/private/merchant/{code}/children`.replace(
          `{${'code'}}`,
          encodeURIComponent(String(requestParameters.code))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Get child stores
   */
  async childrenUsingGET(
    requestParameters: ChildrenUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ReadableMerchantStoreList> {
    const response = await this.childrenUsingGETRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Creates a new store
   */
  async createUsingPOST3Raw(
    requestParameters: CreateUsingPOST3Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.store === null ||
      requestParameters.store === undefined
    ) {
      throw new runtime.RequiredError(
        'store',
        'Required parameter requestParameters.store was null or undefined when calling createUsingPOST3.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/private/store`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.store
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Creates a new store
   */
  async createUsingPOST3(
    requestParameters: CreateUsingPOST3Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.createUsingPOST3Raw(requestParameters, initOverrides);
  }

  /**
   * create initial whiteList for a given store
   */
  async createWhiteListUsingPOSTRaw(
    requestParameters: CreateWhiteListUsingPOSTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<string>> {
    if (
      requestParameters.matchPattern === null ||
      requestParameters.matchPattern === undefined
    ) {
      throw new runtime.RequiredError(
        'matchPattern',
        'Required parameter requestParameters.matchPattern was null or undefined when calling createWhiteListUsingPOST.'
      );
    }

    if (
      requestParameters.needsApproval === null ||
      requestParameters.needsApproval === undefined
    ) {
      throw new runtime.RequiredError(
        'needsApproval',
        'Required parameter requestParameters.needsApproval was null or undefined when calling createWhiteListUsingPOST.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.endDate !== undefined) {
      queryParameters['endDate'] = requestParameters.endDate;
    }

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    if (requestParameters.matchPattern !== undefined) {
      queryParameters['matchPattern'] = requestParameters.matchPattern;
    }

    if (requestParameters.needsApproval !== undefined) {
      queryParameters['needsApproval'] = requestParameters.needsApproval;
    }

    if (requestParameters.startDate !== undefined) {
      queryParameters['startDate'] = requestParameters.startDate;
    }

    if (requestParameters.store !== undefined) {
      queryParameters['store'] = requestParameters.store;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/store/whitelist`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.TextApiResponse(response) as any;
  }

  /**
   * create initial whiteList for a given store
   */
  async createWhiteListUsingPOST(
    requestParameters: CreateWhiteListUsingPOSTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<string> {
    const response = await this.createWhiteListUsingPOSTRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Delete store logo
   */
  async deleteStoreLogoUsingDELETERaw(
    requestParameters: DeleteStoreLogoUsingDELETERequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.code === null ||
      requestParameters.code === undefined
    ) {
      throw new runtime.RequiredError(
        'code',
        'Required parameter requestParameters.code was null or undefined when calling deleteStoreLogoUsingDELETE.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/private/store/{code}/marketing/logo`.replace(
          `{${'code'}}`,
          encodeURIComponent(String(requestParameters.code))
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete store logo
   */
  async deleteStoreLogoUsingDELETE(
    requestParameters: DeleteStoreLogoUsingDELETERequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.deleteStoreLogoUsingDELETERaw(requestParameters, initOverrides);
  }

  /**
   * Deletes a store
   */
  async deleteUsingDELETE3Raw(
    requestParameters: DeleteUsingDELETE3Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.code === null ||
      requestParameters.code === undefined
    ) {
      throw new runtime.RequiredError(
        'code',
        'Required parameter requestParameters.code was null or undefined when calling deleteUsingDELETE3.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/private/store/{code}`.replace(
          `{${'code'}}`,
          encodeURIComponent(String(requestParameters.code))
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Deletes a store
   */
  async deleteUsingDELETE3(
    requestParameters: DeleteUsingDELETE3Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.deleteUsingDELETE3Raw(requestParameters, initOverrides);
  }

  /**
   * Remove whiteList record
   */
  async deleteWhiteListUsingDELETERaw(
    requestParameters: DeleteWhiteListUsingDELETERequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<string>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling deleteWhiteListUsingDELETE.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    if (requestParameters.store !== undefined) {
      queryParameters['store'] = requestParameters.store;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/store/whitelist/{id}`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.TextApiResponse(response) as any;
  }

  /**
   * Remove whiteList record
   */
  async deleteWhiteListUsingDELETE(
    requestParameters: DeleteWhiteListUsingDELETERequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<string> {
    const response = await this.deleteWhiteListUsingDELETERaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Check if store code already exists
   */
  async existsUsingGETRaw(
    requestParameters: ExistsUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<EntityExists>> {
    if (
      requestParameters.code === null ||
      requestParameters.code === undefined
    ) {
      throw new runtime.RequiredError(
        'code',
        'Required parameter requestParameters.code was null or undefined when calling existsUsingGET.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.code !== undefined) {
      queryParameters['code'] = requestParameters.code;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/private/store/unique`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Check if store code already exists
   */
  async existsUsingGET(
    requestParameters: ExistsUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<EntityExists> {
    const response = await this.existsUsingGETRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Check if store code already exists
   */
  async existsUsingGET1Raw(
    requestParameters: ExistsUsingGET1Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<EntityExists>> {
    if (
      requestParameters.code === null ||
      requestParameters.code === undefined
    ) {
      throw new runtime.RequiredError(
        'code',
        'Required parameter requestParameters.code was null or undefined when calling existsUsingGET1.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.code !== undefined) {
      queryParameters['code'] = requestParameters.code;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/store/unique`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Check if store code already exists
   */
  async existsUsingGET1(
    requestParameters: ExistsUsingGET1Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<EntityExists> {
    const response = await this.existsUsingGET1Raw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Organisation can have multiple stores
   * Get organisation stores
   */
  async getDepotsUsingGETRaw(
    requestParameters: GetDepotsUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ReadableMerchantStoreList>> {
    if (
      requestParameters.code === null ||
      requestParameters.code === undefined
    ) {
      throw new runtime.RequiredError(
        'code',
        'Required parameter requestParameters.code was null or undefined when calling getDepotsUsingGET.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.count !== undefined) {
      queryParameters['count'] = requestParameters.count;
    }

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/nazza/{code}/stores`.replace(
          `{${'code'}}`,
          encodeURIComponent(String(requestParameters.code))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Organisation can have multiple stores
   * Get organisation stores
   */
  async getDepotsUsingGET(
    requestParameters: GetDepotsUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ReadableMerchantStoreList> {
    const response = await this.getDepotsUsingGETRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get dynamic fields content
   */
  async getDynamicFieldsContentUsingGETRaw(
    requestParameters: GetDynamicFieldsContentUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<DynamicFieldsContent>> {
    if (
      requestParameters.code === null ||
      requestParameters.code === undefined
    ) {
      throw new runtime.RequiredError(
        'code',
        'Required parameter requestParameters.code was null or undefined when calling getDynamicFieldsContentUsingGET.'
      );
    }

    if (requestParameters.key === null || requestParameters.key === undefined) {
      throw new runtime.RequiredError(
        'key',
        'Required parameter requestParameters.key was null or undefined when calling getDynamicFieldsContentUsingGET.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/public/store/{code}/dynamicfieldscontent/{key}`
          .replace(
            `{${'code'}}`,
            encodeURIComponent(String(requestParameters.code))
          )
          .replace(
            `{${'key'}}`,
            encodeURIComponent(String(requestParameters.key))
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Get dynamic fields content
   */
  async getDynamicFieldsContentUsingGET(
    requestParameters: GetDynamicFieldsContentUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<DynamicFieldsContent> {
    const response = await this.getDynamicFieldsContentUsingGETRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Only the feedback items accessible for the given account
   * Get feedback items
   */
  async getFeedbackUsingGETRaw(
    requestParameters: GetFeedbackUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ReadableFeedbackList>> {
    const queryParameters: any = {};

    if (requestParameters.count !== undefined) {
      queryParameters['count'] = requestParameters.count;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters['filter'] = requestParameters.filter;
    }

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page;
    }

    if (requestParameters.sortColumn !== undefined) {
      queryParameters['sortColumn'] = requestParameters.sortColumn;
    }

    if (requestParameters.sortOrder !== undefined) {
      queryParameters['sortOrder'] = requestParameters.sortOrder;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/nazza/feedback`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Only the feedback items accessible for the given account
   * Get feedback items
   */
  async getFeedbackUsingGET(
    requestParameters: GetFeedbackUsingGETRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ReadableFeedbackList> {
    const response = await this.getFeedbackUsingGETRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get info content
   */
  async getInfoContentUsingGETRaw(
    requestParameters: GetInfoContentUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ReadableInfoContent>> {
    if (
      requestParameters.code === null ||
      requestParameters.code === undefined
    ) {
      throw new runtime.RequiredError(
        'code',
        'Required parameter requestParameters.code was null or undefined when calling getInfoContentUsingGET.'
      );
    }

    if (requestParameters.key === null || requestParameters.key === undefined) {
      throw new runtime.RequiredError(
        'key',
        'Required parameter requestParameters.key was null or undefined when calling getInfoContentUsingGET.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/public/store/{code}/infocontent/{key}`
          .replace(
            `{${'code'}}`,
            encodeURIComponent(String(requestParameters.code))
          )
          .replace(
            `{${'key'}}`,
            encodeURIComponent(String(requestParameters.key))
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Get info content
   */
  async getInfoContentUsingGET(
    requestParameters: GetInfoContentUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ReadableInfoContent> {
    const response = await this.getInfoContentUsingGETRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get register texts
   */
  async getRegisterTextsUsingGETRaw(
    requestParameters: GetRegisterTextsUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ReadableRegisterTexts>> {
    if (
      requestParameters.code === null ||
      requestParameters.code === undefined
    ) {
      throw new runtime.RequiredError(
        'code',
        'Required parameter requestParameters.code was null or undefined when calling getRegisterTextsUsingGET.'
      );
    }

    if (requestParameters.key === null || requestParameters.key === undefined) {
      throw new runtime.RequiredError(
        'key',
        'Required parameter requestParameters.key was null or undefined when calling getRegisterTextsUsingGET.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/public/store/{code}/registerTexts/{key}`
          .replace(
            `{${'code'}}`,
            encodeURIComponent(String(requestParameters.code))
          )
          .replace(
            `{${'key'}}`,
            encodeURIComponent(String(requestParameters.key))
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Get register texts
   */
  async getRegisterTextsUsingGET(
    requestParameters: GetRegisterTextsUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ReadableRegisterTexts> {
    const response = await this.getRegisterTextsUsingGETRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get store branding and marketing details
   */
  async getStoreMarketingUsingGETRaw(
    requestParameters: GetStoreMarketingUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ReadableBrand>> {
    if (
      requestParameters.code === null ||
      requestParameters.code === undefined
    ) {
      throw new runtime.RequiredError(
        'code',
        'Required parameter requestParameters.code was null or undefined when calling getStoreMarketingUsingGET.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/private/store/{code}/marketing`.replace(
          `{${'code'}}`,
          encodeURIComponent(String(requestParameters.code))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Get store branding and marketing details
   */
  async getStoreMarketingUsingGET(
    requestParameters: GetStoreMarketingUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ReadableBrand> {
    const response = await this.getStoreMarketingUsingGETRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get list of stores. Returns all retailers and stores. If superadmin everything is returned, else only retailer and child stores.
   */
  async getUsingGET1Raw(
    requestParameters: GetUsingGET1Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ReadableMerchantStoreList>> {
    const queryParameters: any = {};

    if (requestParameters.count !== undefined) {
      queryParameters['count'] = requestParameters.count;
    }

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/private/stores`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Get list of stores. Returns all retailers and stores. If superadmin everything is returned, else only retailer and child stores.
   */
  async getUsingGET1(
    requestParameters: GetUsingGET1Request = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ReadableMerchantStoreList> {
    const response = await this.getUsingGET1Raw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * getWhiteListRecords
   */
  async getWhiteListRecordsUsingGETRaw(
    requestParameters: GetWhiteListRecordsUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ReadableWhiteListRecords>> {
    const queryParameters: any = {};

    if (requestParameters.accountNonExpired !== undefined) {
      queryParameters['accountNonExpired'] =
        requestParameters.accountNonExpired;
    }

    if (requestParameters.accountNonLocked !== undefined) {
      queryParameters['accountNonLocked'] = requestParameters.accountNonLocked;
    }

    if (requestParameters.authorities0Authority !== undefined) {
      queryParameters['authorities[0].authority'] =
        requestParameters.authorities0Authority;
    }

    if (requestParameters.count !== undefined) {
      queryParameters['count'] = requestParameters.count;
    }

    if (requestParameters.credentialsNonExpired !== undefined) {
      queryParameters['credentialsNonExpired'] =
        requestParameters.credentialsNonExpired;
    }

    if (requestParameters.email !== undefined) {
      queryParameters['email'] = requestParameters.email;
    }

    if (requestParameters.enabled !== undefined) {
      queryParameters['enabled'] = requestParameters.enabled;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters['filter'] = requestParameters.filter;
    }

    if (requestParameters.firstName !== undefined) {
      queryParameters['firstName'] = requestParameters.firstName;
    }

    if (requestParameters.id !== undefined) {
      queryParameters['id'] = requestParameters.id;
    }

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    if (requestParameters.lastName !== undefined) {
      queryParameters['lastName'] = requestParameters.lastName;
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page;
    }

    if (requestParameters.password !== undefined) {
      queryParameters['password'] = requestParameters.password;
    }

    if (requestParameters.sortColumn !== undefined) {
      queryParameters['sortColumn'] = requestParameters.sortColumn;
    }

    if (requestParameters.sortOrder !== undefined) {
      queryParameters['sortOrder'] = requestParameters.sortOrder;
    }

    if (requestParameters.username !== undefined) {
      queryParameters['username'] = requestParameters.username;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/store/whitelist`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * getWhiteListRecords
   */
  async getWhiteListRecordsUsingGET(
    requestParameters: GetWhiteListRecordsUsingGETRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ReadableWhiteListRecords> {
    const response = await this.getWhiteListRecordsUsingGETRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Merchant (retailer) can have multiple stores
   * Get retailer child stores
   */
  async listUsingGET1Raw(
    requestParameters: ListUsingGET1Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ReadableMerchantStoreList>> {
    if (
      requestParameters.code === null ||
      requestParameters.code === undefined
    ) {
      throw new runtime.RequiredError(
        'code',
        'Required parameter requestParameters.code was null or undefined when calling listUsingGET1.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.count !== undefined) {
      queryParameters['count'] = requestParameters.count;
    }

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/private/merchant/{code}/stores`.replace(
          `{${'code'}}`,
          encodeURIComponent(String(requestParameters.code))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Merchant (retailer) can have multiple stores
   * Get retailer child stores
   */
  async listUsingGET1(
    requestParameters: ListUsingGET1Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ReadableMerchantStoreList> {
    const response = await this.listUsingGET1Raw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get list of store names. Returns all retailers and stores
   */
  async listUsingGET2Raw(
    requestParameters: ListUsingGET2Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<ReadableMerchantStore>>> {
    const queryParameters: any = {};

    if (requestParameters.count !== undefined) {
      queryParameters['count'] = requestParameters.count;
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/private/stores/names`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Get list of store names. Returns all retailers and stores
   */
  async listUsingGET2(
    requestParameters: ListUsingGET2Request = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<ReadableMerchantStore>> {
    const response = await this.listUsingGET2Raw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get merchant store
   */
  async publicStoreUsingGETRaw(
    requestParameters: PublicStoreUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ReadableMerchantStore>> {
    if (
      requestParameters.code === null ||
      requestParameters.code === undefined
    ) {
      throw new runtime.RequiredError(
        'code',
        'Required parameter requestParameters.code was null or undefined when calling publicStoreUsingGET.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/public/store/{code}`.replace(
          `{${'code'}}`,
          encodeURIComponent(String(requestParameters.code))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Get merchant store
   */
  async publicStoreUsingGET(
    requestParameters: PublicStoreUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ReadableMerchantStore> {
    const response = await this.publicStoreUsingGETRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Create or save store branding and marketing details
   */
  async saveStoreMarketingUsingPOSTRaw(
    requestParameters: SaveStoreMarketingUsingPOSTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.code === null ||
      requestParameters.code === undefined
    ) {
      throw new runtime.RequiredError(
        'code',
        'Required parameter requestParameters.code was null or undefined when calling saveStoreMarketingUsingPOST.'
      );
    }

    if (
      requestParameters.brand === null ||
      requestParameters.brand === undefined
    ) {
      throw new runtime.RequiredError(
        'brand',
        'Required parameter requestParameters.brand was null or undefined when calling saveStoreMarketingUsingPOST.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/private/store/{code}/marketing`.replace(
          `{${'code'}}`,
          encodeURIComponent(String(requestParameters.code))
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.brand
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Create or save store branding and marketing details
   */
  async saveStoreMarketingUsingPOST(
    requestParameters: SaveStoreMarketingUsingPOSTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.saveStoreMarketingUsingPOSTRaw(requestParameters, initOverrides);
  }

  /**
   * Get merchant store full details
   */
  async storeFullUsingGETRaw(
    requestParameters: StoreFullUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ReadableMerchantStore>> {
    if (
      requestParameters.code === null ||
      requestParameters.code === undefined
    ) {
      throw new runtime.RequiredError(
        'code',
        'Required parameter requestParameters.code was null or undefined when calling storeFullUsingGET.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/private/store/{code}`.replace(
          `{${'code'}}`,
          encodeURIComponent(String(requestParameters.code))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Get merchant store full details
   */
  async storeFullUsingGET(
    requestParameters: StoreFullUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ReadableMerchantStore> {
    const response = await this.storeFullUsingGETRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get merchant store
   */
  async storeUsingGETRaw(
    requestParameters: StoreUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ReadableMerchantStore>> {
    if (
      requestParameters.code === null ||
      requestParameters.code === undefined
    ) {
      throw new runtime.RequiredError(
        'code',
        'Required parameter requestParameters.code was null or undefined when calling storeUsingGET.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/store/{code}`.replace(
          `{${'code'}}`,
          encodeURIComponent(String(requestParameters.code))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Get merchant store
   */
  async storeUsingGET(
    requestParameters: StoreUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ReadableMerchantStore> {
    const response = await this.storeUsingGETRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get list of store supported languages.
   */
  async supportedLanguagesUsingGETRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<Language>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/store/languages`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Get list of store supported languages.
   */
  async supportedLanguagesUsingGET(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<Language>> {
    const response = await this.supportedLanguagesUsingGETRaw(initOverrides);
    return await response.value();
  }

  /**
   * Add missing depot from Nazza as child stores
   */
  async syncDepotsFromNazzaUsingPUTRaw(
    requestParameters: SyncDepotsFromNazzaUsingPUTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<boolean>> {
    if (
      requestParameters.code === null ||
      requestParameters.code === undefined
    ) {
      throw new runtime.RequiredError(
        'code',
        'Required parameter requestParameters.code was null or undefined when calling syncDepotsFromNazzaUsingPUT.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/nazza/depot/{code}/addmissing`.replace(
          `{${'code'}}`,
          encodeURIComponent(String(requestParameters.code))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.TextApiResponse(response) as any;
  }

  /**
   * Add missing depot from Nazza as child stores
   */
  async syncDepotsFromNazzaUsingPUT(
    requestParameters: SyncDepotsFromNazzaUsingPUTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<boolean> {
    const response = await this.syncDepotsFromNazzaUsingPUTRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Sync a child store from nazza depot
   */
  async syncFromNazzaUsingPUTRaw(
    requestParameters: SyncFromNazzaUsingPUTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<boolean>> {
    if (
      requestParameters.code === null ||
      requestParameters.code === undefined
    ) {
      throw new runtime.RequiredError(
        'code',
        'Required parameter requestParameters.code was null or undefined when calling syncFromNazzaUsingPUT.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/nazza/depot/{code}/sync`.replace(
          `{${'code'}}`,
          encodeURIComponent(String(requestParameters.code))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.TextApiResponse(response) as any;
  }

  /**
   * Sync a child store from nazza depot
   */
  async syncFromNazzaUsingPUT(
    requestParameters: SyncFromNazzaUsingPUTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<boolean> {
    const response = await this.syncFromNazzaUsingPUTRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Updates a store
   */
  async updateUsingPUT3Raw(
    requestParameters: UpdateUsingPUT3Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.code === null ||
      requestParameters.code === undefined
    ) {
      throw new runtime.RequiredError(
        'code',
        'Required parameter requestParameters.code was null or undefined when calling updateUsingPUT3.'
      );
    }

    if (
      requestParameters.store === null ||
      requestParameters.store === undefined
    ) {
      throw new runtime.RequiredError(
        'store',
        'Required parameter requestParameters.store was null or undefined when calling updateUsingPUT3.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/private/store/{code}`.replace(
          `{${'code'}}`,
          encodeURIComponent(String(requestParameters.code))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.store
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Updates a store
   */
  async updateUsingPUT3(
    requestParameters: UpdateUsingPUT3Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.updateUsingPUT3Raw(requestParameters, initOverrides);
  }

  /**
   * update an exising whitelist record
   */
  async updateWhiteListUsingPUTRaw(
    requestParameters: UpdateWhiteListUsingPUTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<string>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling updateWhiteListUsingPUT.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.endDate !== undefined) {
      queryParameters['endDate'] = requestParameters.endDate;
    }

    if (requestParameters.matchPattern !== undefined) {
      queryParameters['matchPattern'] = requestParameters.matchPattern;
    }

    if (requestParameters.needsApproval !== undefined) {
      queryParameters['needsApproval'] = requestParameters.needsApproval;
    }

    if (requestParameters.startDate !== undefined) {
      queryParameters['startDate'] = requestParameters.startDate;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/store/whitelist/{id}`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.TextApiResponse(response) as any;
  }

  /**
   * update an exising whitelist record
   */
  async updateWhiteListUsingPUT(
    requestParameters: UpdateWhiteListUsingPUTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<string> {
    const response = await this.updateWhiteListUsingPUTRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
