import { UnauthorizedError } from '../../components/errors/UnauthorizedError';
import { useAppSelector } from '../../hooks';
import { isDepotManager } from '../account/customerSlice';
import { selectOrganization } from '../organization/organizationSlice';
import { Users } from './Users';

export const UsersScreen = () => {
  const organisation = useAppSelector(selectOrganization);
  if (!useAppSelector(isDepotManager) || (!organisation.selfRegister && !organisation.adminRegister))
    return <UnauthorizedError />;

  return (
    <>
      <Users />
    </>
  );
};
