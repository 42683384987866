import ElectricBikeIcon from '@mui/icons-material/ElectricBike';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications';
import { useNavigate } from 'react-router-dom';
import { CustomLoadingButton } from '../../components/custom-loading-button/CustomLoadingButton';
import { TooltipClick } from '../../components/tooltip-click/TooltipClick';
import { isLoadedInMoovlr } from '../../components/UtilityService';
import UserService from '../../core/auth/userService';
import { formatPrice } from '../../core/Helpers/helper';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useLanguage } from '../../store/LanguageProvider';
import { ProductType } from '../../types/product';
import { navigateToBackendCheckout } from '../../webServices/cartService';
import {
  addToCart,
  addToCartUser,
  resetCart,
  selectCartProductsCount
} from '../cart/cartSlice';
import { ProductImage } from './ProductImage';

export const showExtraInfoIcon = (product: ProductType) => {
  if (!product.descriptionName && !product.description) return;

  return (
    <TooltipClick
      content={
        <>
          {product.descriptionName && (
            <Typography variant={'h6'}>{product.descriptionName}</Typography>
          )}
          {product.description && (
            <Typography>{product.description}</Typography>
          )}
        </>
      }
    />
  );
};

const ProductCard = ({ product }: { product: ProductType }) => {
  const { t } = useTranslation('translation');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const componentRef = useRef();
  const [buttonSize, setButtonSize] = useState<'small' | 'large'>('small');
  const [isBusy, setIsBusy] = useState(false);
  const { language } = useLanguage();
  const cartIsEmpty = useAppSelector(selectCartProductsCount) === 0;

  const classes = useStyles();

  useEffect(() => {
    const handleResize = () => {
      // @ts-ignore
      const width = componentRef?.current?.offsetWidth;
      if (!width) return;
      // setButtonSize(width > 350 ? 'large' : 'small');
    };

    if (componentRef.current) {
      handleResize();
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [componentRef]);

  const mustBeLoggedIn = () => {
    NotificationManager.warning(t('message.mustBeLoggedInToAddToCart'), 'Cart');
  };

  const addProductToCart = async () => {
    if (isBusy) return;
    setIsBusy(true);
    try {
      await dispatch(addToCart({ product, language })).unwrap();
      NotificationManager.success(t('translation:addToCartSuccess'), 'Cart');
    } catch (e) {
      console.log('error: ', e);
      setIsBusy(false);
    }
  };

  const reserveNow = async () => {
    if (!UserService.isLoggedIn()) {
      mustBeLoggedIn();
      return;
    }

    if (isBusy) return;
    setIsBusy(true);

    try {
      if (!cartIsEmpty) {
        await dispatch(resetCart());
      }

      const serverCartData = await dispatch(
        addToCartUser({
          sku: product.sku,
          externalId: product.externalId,
          language,
          selectedEmail: product.selectedEmail
        })
      ).unwrap();

      if (!serverCartData.total) {
        navigateToBackendCheckout(serverCartData);
      } else {
        navigate('/checkout');
      }
    } catch (e) {
      setIsBusy(false);
    }
  };

  return (
    <Card className={classes.card} sx={{ height: '100%' }} ref={componentRef}>
      <div className={classes.mediaGrid}>
        <figure className={classes.figure}>
          <ProductImage product={product} className={classes.figureImg} />
          {product.assetType === 'ebike' && (
            <ElectricBikeIcon className={classes.figureIcon} />
          )}
        </figure>
      </div>
      <CardContent className={classes.cardContent + ' pa-1'}>
        <Grid container spacing={1} alignItems={'center'}>
          <Grid item>
            <Typography variant="overline" className={classes.productName}>
              {product.name}
            </Typography>
          </Grid>

          <Grid item>{showExtraInfoIcon(product)}</Grid>
        </Grid>
        {!!product.subTotal && (
          <Grid container spacing={1} alignItems={'center'}>
            <Grid item>
              <Typography>
                <Typography variant="h5">
                  {formatPrice(product.subTotal)}
                </Typography>
              </Typography>
            </Grid>

            <Grid item>
              <TooltipClick
                content={t('priceInfo', { price: formatPrice(product.price) })}
              />
            </Grid>
          </Grid>
        )}

        <Grid container justifyContent="center" mt={2} spacing={2}>
          {!isLoadedInMoovlr() && (
            <Grid item>
              <CustomLoadingButton
                icon="shopping_cart"
                label={t(
                  !!product.subTotal ? 'addToCart' : 'claimTheReservation'
                )}
                onClick={addProductToCart}
                variant={buttonSize}
              />
            </Grid>
          )}

          {UserService.isLoggedIn() && (
            <Grid item>
              <CustomLoadingButton
                icon="arrow_forward_ios"
                label={t('reserveNow')}
                onClick={reserveNow}
                variant={buttonSize}
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

ProductCard.propTypes = {
  product: PropTypes.object
};

export default ProductCard;

const useStyles = makeStyles((theme) => ({
  card: {
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    fontWeight: 'bold',
    '&:hover': {
      // background: theme.palette.secondary.dark,
      // transform: 'scale(.99)'
    }
  },
  mediaGrid: {
    textAlign: 'center',
    overflow: 'hidden'
  },
  figure: {
    position: 'relative',
    // cursor: 'pointer',
    // background: theme.palette.secondary.main,
    background: '#fff',
    borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
    textAlign: 'center',
    '&:hover $captionAnchor': {
      // transform: "translate3d(0,0,0)",
      opacity: 1
    },
    '&:hover $caption::before': {
      // transform: "translate3d(0,0,0)"
    }
  },
  figureImg: {
    position: 'relative',
    display: 'block',
    width: '75%',
    height: '200px',
    objectFit: 'contain',
    margin: '0 auto',
    borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`
    // transition: 'opacity 300ms, transform 300ms',
    // opacity: 0.85
  },
  figureIcon: {
    color: '#000000',
    backgroundColor: '#fff',
    padding: 1,
    border: '1px solid #000',
    borderRadius: '50%',
    position: 'absolute',
    top: 5,
    right: 5
  },
  cardContent: {
    // background: theme.palette.primary.main,
    // color: theme.palette.primary.contrastText,
    // fontWeight: 'bold'
  },
  productName: {
    fontWeight: 'bold',
    fontSize: 'medium'
  }
}));
