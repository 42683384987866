import { ReadableMerchantStore } from '../../apis/shopizer';
import { Organization } from './organizationSlice';

export function getEnhancedOrganization(
  organization: Organization
): Organization {
  const response: Organization = { ...organization };

  switch (organization.code) {
    case 'CycleShare':
      break;
    case 'SalesDemo':
      // @ts-ignore
      // response.styling = {
      //   ...response.styling,
      //   palette: {
      //     ...response.styling.palette,
      //     secondary: {
      // @ts-ignore
      // ...response.styling.palette.secondary,
      // contrastText: '#000000',
      // dark: '#ff0',
      // light: '#f00',
      // main: '#fff'
      // }
      // }
      // };

      break;

    case 'SQM':
      break;
  }

  if (!response.productIconEBike) {
    response.productIconEBike = `${process.env.PUBLIC_URL}/static/images/e-bike.png`;
  }

  if (!response.productIconStandardBike) {
    response.productIconStandardBike = `${process.env.PUBLIC_URL}/static/images/standard-bike.png`;
  }

  return response;
}

export const isBookingUnitDays = (
  organization: Organization | null,
  activeDepot: ReadableMerchantStore | null
) => {
  const bookingUnit = activeDepot?.bookingUnit ?? organization?.bookingUnit;

  if (!bookingUnit) return false;

  return Number(bookingUnit) % (60 * 24) === 0;
};
