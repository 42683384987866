import {
  AsyncThunk,
  AsyncThunkPayloadCreator,
  createAsyncThunk
} from '@reduxjs/toolkit';
import { AsyncThunkOptions } from '@reduxjs/toolkit/src/createAsyncThunk';
import { useEffect, useState } from 'react';
import {
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
  useStore
} from 'react-redux';
import type { AppDispatch, AppStore, RootState } from './redux';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useAppStore = () => useStore() as AppStore;

export function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

export interface AppThunkConfig {
  extra?: unknown;
  rejectValue?: unknown;
  serializedErrorType?: unknown;
  pendingMeta?: unknown;
  fulfilledMeta?: unknown;
  rejectedMeta?: unknown;
}

// This ungodly mess is copied from redux-thunk's typescript typing
// All this is done so we can default state & dispatch to the appriate
// types but still allow overrides for the other values from caller side
// is there a cleaner way?!
export interface DefaultAppThunkConfig<Config> {
  state: RootState;
  dispatch: AppDispatch;
  extra: Config extends { extra: infer T } ? T : unknown;
  rejectValue: Config extends { rejectValue: infer T } ? T : unknown;
  serializedErrorType: Config extends { serializedErrorType: infer T }
    ? T
    : unknown;
  pendingMeta: Config extends { pendingMeta: infer T } ? T : unknown;
  fulfilledMeta: Config extends { fulfilledMeta: infer T } ? T : unknown;
  rejectedMeta: Config extends { rejectedMeta: infer T } ? T : unknown;
}

export const createAppThunk = <
  Returned,
  ThunkArg,
  ThunkApiConfig extends AppThunkConfig = {}
>(
  type: string,
  thunk: AsyncThunkPayloadCreator<
    Returned,
    ThunkArg,
    DefaultAppThunkConfig<ThunkApiConfig>
  >,
  options?: AsyncThunkOptions<ThunkArg, DefaultAppThunkConfig<ThunkApiConfig>>
): AsyncThunk<Returned, ThunkArg, DefaultAppThunkConfig<ThunkApiConfig>> => {
  return createAsyncThunk<
    Returned,
    ThunkArg,
    DefaultAppThunkConfig<ThunkApiConfig>
  >(type, thunk, options);
};
