import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  useMediaQuery
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/styles';
import moment from 'moment';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useShopizerApiClient } from '../../apis';
import {
  CreateWhiteListUsingPOSTRequest,
  ReadableWhiteList
} from '../../apis/shopizer';
import FormProvider from '../../components/hook-form/FormProvider';
import RHFDate from '../../components/hook-form/RHFDate';
import { RHFSelect } from '../../components/hook-form/RHFSelect';
import RHFSwitch from '../../components/hook-form/RHFSwitch';
import RHFTextField from '../../components/hook-form/RHFTextField';
import { useAppSelector } from '../../hooks';
import { selectActiveCustomer } from '../account/customerSlice';

type WhiteListFormProp = {
  open: boolean;
  onClose: () => void;
  onSave?: () => void;
} & (
  | {
      type: 'add';
    }
  | {
      type: 'update';
      originalValues: ReadableWhiteList;
    }
);

type FormValuesProps = CreateWhiteListUsingPOSTRequest & {
  afterSubmit?: string;
};

export const WhiteListForm = (props: WhiteListFormProp) => {
  const { open, onClose, onSave, type } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const shopizerApiClient = useShopizerApiClient();
  const customer = useAppSelector(selectActiveCustomer);
  const managerDepots = customer.managerDepots;
  const merchantStore = customer.merchantStore;

  useEffect(() => {
    if (!open) reset();
  }, [open]);

  const RegisterSchema = Yup.object().shape({
    endDate: Yup.string().required(t('validation.endDate.required')),
    startDate: Yup.string().required(t('validation.startDate.required')),
    store: Yup.string().required(t('validation.store.required')),
    matchPattern: Yup.string().required(t('validation.matchPattern.required'))
  });

  const defaultValues: FormValuesProps =
    type === 'update'
      ? {
          endDate: props.originalValues?.endDate ?? '',
          startDate: props.originalValues?.startDate ?? '',
          matchPattern: props.originalValues?.matchPattern ?? '',
          store: props.originalValues?.location ?? '',
          needsApproval: props.originalValues?.needsApprovalBoolean
        }
      : {
          endDate: '',
          startDate: '',
          matchPattern: '',
          store: '',
          needsApproval: false
        };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(RegisterSchema),
    defaultValues
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful }
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      if (type === 'add') {
        await shopizerApiClient.storeApi.createWhiteListUsingPOST({
          store: data.store,
          matchPattern: data.matchPattern,
          startDate: moment(data.startDate).toISOString(true),
          endDate: moment(data.endDate).toISOString(true),
          needsApproval: data.needsApproval
        });
      } else {
        await shopizerApiClient.storeApi.updateWhiteListUsingPUT({
          id: props.originalValues.id,
          matchPattern: data.matchPattern,
          startDate: moment(data.startDate).toISOString(true),
          endDate: moment(data.endDate).toISOString(true),
          needsApproval: data.needsApproval
        });
      }

      if (onSave) onSave();
      onClose();
    } catch (error) {
      console.error(error);
      setError('afterSubmit', {
        ...error,
        message: error.message || error
      });
    }
  };

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      onClose={onClose}
      fullWidth={true}
      aria-labelledby="responsive-dialog-title"
      sx={{
        '& .MuiPaper-root': { m: 0 },
        '& .MuiBackdrop-root': {
          // backgroundColor: 'rgba(0, 0, 0, 0.9)'
        }
      }}
    >
      <DialogTitle
        id="responsive-dialog-title"
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1
        }}
      >
        {t(`button.${type}`)}
      </DialogTitle>
      <DialogContent
        dividers
        sx={{ borderTop: `1px dashed ${theme.palette.divider}` }}
      >
        <FormProvider methods={methods}>
          <Stack spacing={2.5} pt={1}>
            {!!errors.afterSubmit && (
              <Alert severity="error">{errors.afterSubmit.message}</Alert>
            )}

            {/*<pre>{JSON.stringify(errors, null, 2)}</pre>*/}

            {type === 'add' && (
              <RHFSelect name={'store'} label={t('location')}>
                <MenuItem value={merchantStore.code}>{merchantStore.storename}</MenuItem>
                {managerDepots.map((d) => (
                  <MenuItem value={d.storeCode}>{d.storeName}</MenuItem>
                ))}
              </RHFSelect>
            )}

            {type === 'update' && (
              <RHFTextField
                disabled={true}
                name="store"
                label={t('location')}
              />
            )}

            <RHFTextField name="matchPattern" label={t('field.matchPattern')} inputProps={{ style: { textTransform: "lowercase" } }} />
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <RHFDate name="startDate" label={t('fromDate')} />
              <RHFDate name="endDate" label={t('toDate')} />
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <RHFSwitch
                name="needsApproval"
                label={t('field.needsApproval')}
              />
            </Stack>
          </Stack>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          variant={'contained'}
          formTarget={'test-id'}
          onClick={handleSubmit(onSubmit)}
        >
          {t(`button.${type}`)}
        </Button>
        <Button variant={'contained'} color={'error'} onClick={onClose}>
          {t('translation:button.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
