import AppBar from '@mui/material/AppBar';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { CustomLoadingButton } from '../custom-loading-button/CustomLoadingButton';

export const WrapperWithToolbar = ({
  children,
  onSave,
  headerLeft
}: {
  children: JSX.Element;
  headerLeft: JSX.Element;
  onSave?: () => void;
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation('translation');

  return (
    <div style={{ marginBottom: 40 }}>
      <AppBar position="static">
        <Toolbar sx={{ backgroundColor: theme.palette.secondary.main }} />
        <Toolbar sx={{ backgroundColor: theme.palette.secondary.main }} />
      </AppBar>

      <Grid
        container
        spacing={0}
        justifyContent={'center'}
        className={classes.header}
      >
        <Grid item xs={12} sm={12} md={10} lg={9}>
          <Card>
            <div className={classes.root}>
              <AppBar
                position="absolute"
                className={classes.appBar}
                color="default"
              >
                <Toolbar>
                  <div className={classes.headerLeft}>{headerLeft}</div>

                  <span className="flexSpacer" />
                  {/*<IconButton color="inherit" size="large">*/}
                  {/*  <MoreVertIcon />*/}
                  {/*</IconButton>*/}
                </Toolbar>
              </AppBar>
              <div className={classes.wrapper}>
                <main className={classes.main}>
                  <div className={classes.content}>{children}</div>

                  {!!onSave && (
                    <>
                      <Divider />
                      <div className="py-1 px-1" style={{ textAlign: 'end' }}>
                        <CustomLoadingButton
                          icon={'save'}
                          label={t('button.save')}
                          onClick={onSave}
                        />
                      </div>
                    </>
                  )}
                </main>
              </div>
            </div>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative'
  },
  header: {
    marginTop: '-64px'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    position: 'relative',
    boxShadow: '0 1px 8px rgba(0,0,0,.3)'
  },
  headerLeft: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    overflow: 'auto',
    height: '100%'
  },
  wrapper: {
    width: '100%',
    // height: 'calc(100vh - 200px)',
    minHeight: 200,
    zIndex: 1,
    display: 'flex',
    position: 'relative',
    overflow: 'hidden',
    maxWidth: '100%',
    flexDirection: 'row'
  },
  main: {
    width: '100%'
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1),
    minWidth: 0,
    height: 'calc(100% - 75px)',
    boxSizing: 'border-box',
    flex: 1,
    position: 'relative',
    overflowX: 'hidden',
    overflowY: 'auto'
  },

  input: {
    flex: '1 1 0%',
    boxSizing: 'border-box'
  }
}));
