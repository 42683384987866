import { Close, Email, PhoneIphone } from '@mui/icons-material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import { isLoadedInMoovlr } from '../../components/UtilityService';
import Wrapper from '../../components/wrapper/Wrapper';
import { buildRandomKey, formatPrice } from '../../core/Helpers/helper';
import { useAppDispatch } from '../../hooks';
import { useLanguage } from '../../store/LanguageProvider';
import { OrderConfirmationResponse } from '../../types/order';
import { getOrderConfirmation } from '../../webServices/orderService';
import { resetCartInFrontend } from '../cart/cartSlice';
import { ProductInfo } from '../products/ProductInfo';

export const CheckoutPaymentSuccess = () => {
  const { t } = useTranslation('translation');
  const dispatch = useAppDispatch();

  const { language } = useLanguage();

  const [orderConfirmation, setOrderConfirmation] =
    useState<OrderConfirmationResponse | null>();

  const search = useLocation().search;
  const sessionId = new URLSearchParams(search).get('orderId');

  useEffect(() => {
    if (language) {
      getOrderSummary();
    }
  }, [language]);

  async function getOrderSummary() {
    try {
      const orderConfirmation = await getOrderConfirmation(sessionId, language);
      setOrderConfirmation(orderConfirmation);

      if (orderConfirmation.orderStatus === 'confirmed') {
        await dispatch(resetCartInFrontend());
      }
    } catch (e) {
      console.log('Fail sending order: ', e);
    }
  }

  if (!orderConfirmation) {
    return (
      <Wrapper>
        <Card style={{ padding: 20, textAlign: 'center' }}>
          <CircularProgress />
        </Card>
      </Wrapper>
    );
  }

  function displayProducts() {
    const totalPrice = orderConfirmation.total.totals.find(
      (t) => t.module == 'total'
    );

    return (
      <div style={{ marginTop: 40 }}>
        {orderConfirmation.products.map((product) => {
          return (
            <>
              <Grid item xs={7} sm={7} md={7}>
                <ProductInfo product={product} />
              </Grid>
              <hr />
            </>
          );
        })}

        {!!totalPrice?.value && (
          <Grid
            item
            key={buildRandomKey()}
            sx={{ textAlign: 'right', m: 3, fontWeight: 700, fontSize: 20 }}
          >
            {t('translation:total')} {formatPrice(totalPrice.value)}
          </Grid>
        )}
      </div>
    );
  }

  const returnToLink = isLoadedInMoovlr() ? '/products/travel' : '/';

  return (
    <Wrapper maxWidth={800}>
      <Card className={'pa-2'}>
        <Hidden smUp>
          <NavLink to={returnToLink}>
            <IconButton sx={{ position: 'absolute', top: 20, right: 20 }}>
              <Close />
            </IconButton>
          </NavLink>
        </Hidden>
        <div style={{ minWidth: 250 }}>
          <Typography
            variant="h4"
            color="inherit"
            textAlign={'center'}
            noWrap
            key={buildRandomKey()}
          >
            {t('translation:order')}
          </Typography>
          <Grid container>
            <Grid item marginTop={3} xs={12} sm={6}>
              <Typography>
                {t('translation:orderNo')}: {orderConfirmation.id}
              </Typography>
              <Typography>
                {t('translation:orderStatus')}:{' '}
                <Typography
                  sx={{
                    display: 'inline',
                    color:
                      orderConfirmation.orderStatus === 'canceled'
                        ? 'red'
                        : orderConfirmation.orderStatus === 'pending'
                        ? 'gray'
                        : 'green',
                    fontWeight: '700',
                    textTransform: 'uppercase'
                  }}
                >
                  {t(
                    'translation:orderStatus.' + orderConfirmation.orderStatus
                  )}
                </Typography>
              </Typography>

              <div>
                <Typography
                  mt={2}
                  sx={{ display: 'inline-flex', alignItems: 'center' }}
                >
                  <AccountCircle fontSize={'small'} sx={{ pr: 1 }} />
                  {orderConfirmation.billing.firstName}{' '}
                  {orderConfirmation.billing.lastName}
                </Typography>
              </div>

              {!!orderConfirmation.billing.phone && (
                <Typography
                  sx={{ display: 'inline-flex', alignItems: 'center' }}
                >
                  <PhoneIphone fontSize={'small'} sx={{ pr: 1 }} />
                  {orderConfirmation.billing.phone}
                </Typography>
              )}
            </Grid>

            <Grid
              item
              sx={{
                textAlign: { xs: 'left', sm: 'right' },
                paddingLeft: { xs: 0, sm: 2 }
              }}
              flex={1}
              marginTop={3}
              xs={12}
              sm={6}
            >
              <Typography sx={{ fontWeight: 500 }} noWrap={true}>
                {orderConfirmation.merchantStore.storename}
              </Typography>

              <Typography mt={2}>
                {orderConfirmation.merchantStore.storeaddress}
              </Typography>
              <Typography>
                {orderConfirmation.merchantStore.storepostalcode}{' '}
                {orderConfirmation.merchantStore.storecity}
              </Typography>

              <Typography mt={2} />
              {!!orderConfirmation.merchantStore.storephone && (
                <div>
                  <Typography
                    sx={{ display: 'inline-flex', alignItems: 'center' }}
                  >
                    <PhoneIphone fontSize={'small'} sx={{ pr: 1 }} />
                    {orderConfirmation.merchantStore.storephone}
                  </Typography>
                </div>
              )}

              {!!orderConfirmation.merchantStore.storeEmailAddress && (
                <div>
                  <Typography
                    sx={{ display: 'inline-flex', alignItems: 'center' }}
                  >
                    <Email fontSize={'small'} sx={{ pr: 1 }} />
                    {orderConfirmation.merchantStore.storeEmailAddress}
                  </Typography>
                </div>
              )}
            </Grid>
          </Grid>

          {orderConfirmation.orderStatus !== 'canceled' && displayProducts()}

          {!isLoadedInMoovlr() && (
            <Typography
              sx={(theme) => ({
                '& a': {
                  color: theme.palette.primary.main,
                  fontWeight: 700
                }
              })}
            >
              <Trans i18nKey="translation:goToApp">
                Please go to the <a href="https://moovlr.nl/">Moovlr app</a>{' '}
                when you want to use your booked assets/vehicles.
              </Trans>
            </Typography>
          )}

          <div style={{ textAlign: 'center', margin: 20 }}>
            {!isLoadedInMoovlr() && (
              <a href={'https://moovlr.nl'} target={'_blank'}>
                <Button
                  variant={'contained'}
                  color={'secondary'}
                  sx={{ mr: 2 }}
                >
                  Moovlr
                </Button>
              </a>
            )}

            <NavLink to={returnToLink}>
              <Button variant={'contained'} color={'primary'}>
                Close
              </Button>
            </NavLink>
          </div>
        </div>
      </Card>
    </Wrapper>
  );
};
