import { LockPerson, PersonAdd } from '@mui/icons-material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { selectActiveCustomer } from '../../../../feature/account/customerSlice';
import { selectActiveDepot } from '../../../../feature/depot/depotSlice';
import { selectOrganization } from '../../../../feature/organization/organizationSlice';
import { useAppSelector } from '../../../../hooks';
import keycloak from '../../../auth/Keycloak';
import UserService from '../../../auth/userService';
import { PATH_AUTH } from '../../../routes/paths';

export const Account = () => {
  const { t } = useTranslation('translation');
  const [anchor, setAnchor] = useState(null);

  const customer = useAppSelector(selectActiveCustomer);
  const organization = useAppSelector(selectOrganization);
  const activeDepot = useAppSelector(selectActiveDepot);

  const handleToggle = (event) => setAnchor(event.currentTarget);
  const handleCloseMenu = () => setAnchor(null);

  const handleSignOut = () => {
    const logoutOptions = { redirectUri: '/' };
    const logoutUrl = keycloak.createLogoutUrl(logoutOptions);
    // @ts-ignore
    keycloak.logout({ logoutUrl });
    localStorage.clear();
  };

  return (
    <>
      <IconButton
        aria-owns={'user-menu'}
        aria-haspopup="true"
        onClick={handleToggle}
        color="inherit"
        size="large"
      >
        <AccountCircle />
      </IconButton>

      <Menu
        id="user-menu"
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClose={handleCloseMenu}
      >
        {!!customer && (
            <NavLink to={'/account'} onClick={handleCloseMenu}>
              <MenuItem>
                <ListItemText secondary={customer?.userName} />
              </MenuItem>
              <MenuItem>
                <ListItemIcon>
                  <AccountCircle />
                </ListItemIcon>
                <ListItemText primary={t('menu.account')} />
              </MenuItem>
            </NavLink>
        )}
        {!!customer && (
            <MenuItem onClick={handleSignOut}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Sign out" />
            </MenuItem>
        )}
        {!customer && (
          <MenuItem
            onClick={() => {
              UserService.doLogin();
            }}
          >
            <ListItemIcon>
              <LockPerson />
            </ListItemIcon>
            <ListItemText primary={t('register.signIn')} />
          </MenuItem>
        )}

        {!customer && organization && !!organization.selfRegister && (
          <NavLink
            to={PATH_AUTH.register.replace(
              ':organizationCode',
              activeDepot?.code ?? organization?.code
            )}
            onClick={handleCloseMenu}
          >
            <MenuItem>
              <ListItemIcon>
                <PersonAdd />
              </ListItemIcon>
              <ListItemText primary={t('register.register')} />
            </MenuItem>
          </NavLink>
        )}
      </Menu>
    </>
  );
};
