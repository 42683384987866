import { Add } from '@mui/icons-material';
import Paper from '@mui/material/Paper';
import { createColumnHelper } from '@tanstack/react-table';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useShopizerApiClient } from '../../apis';
import { ReadableOnboarding } from '../../apis/shopizer';
import { useDialog } from '../../components/dialog/useDialog';
import {
  CustomColumnDef,
  DataTable,
  RequestParams,
  ReturnData
} from '../../components/tanStackTable/DataTable';
import Wrapper from '../../components/wrapper/Wrapper';
import { useLanguage } from '../../store/LanguageProvider';
import { useDynamicFields } from '../dynamic-forms/UseDynamicFields';
import { UserActions } from './UserActions';
import { UserForm } from './UserForm';
import Button from '@mui/material/Button';
import { selectOrganization } from '../organization/organizationSlice';
import { useAppSelector } from '../../hooks';

export const Users = () => {
  const { t } = useTranslation('translation');
  const { language } = useLanguage();
  const shopizerApiClient = useShopizerApiClient();
  const navigate = useNavigate();
  const { open, handleOpen, handleClose } = useDialog();

  const columnHelper = createColumnHelper<ReadableOnboarding>();
  const [refreshListCount, setRefreshListCount] = useState(0);

  useEffect(() => {
    setRefreshListCount((c) => c + 1);
  }, [language]);

  const refreshList = () => {
    setRefreshListCount((current) => current + 1);
  };

  const organization = useAppSelector(selectOrganization);
  
  const { fields } = useDynamicFields('MANAGE_USERS_FIELDS');

  const columns: CustomColumnDef[] = [
    {
      ...columnHelper.accessor('location', { header: t('location') }),
      valueType: 'string'
    },
    {
      ...columnHelper.accessor('firstName', { header: t('firstName') }),
      valueType: 'string'
    },
    {
      ...columnHelper.accessor('lastName', { header: t('lastName') }),
      valueType: 'string'
    },
    {
      ...columnHelper.accessor('email', { header: t('emailAddress') }),
      valueType: 'string'
    },
    {
      ...columnHelper.accessor('status', { header: t('status') }),
      valueType: 'string'
    },
    {
      ...columnHelper.accessor('onboardingDate', {
        header: t('field.date'),
        enableColumnFilter: false,
        cell: (accessor) => {
          const checkValue = moment(accessor.getValue()).format('L');
          return <div>{checkValue}</div>;
        }
      }),
      valueType: 'date'
    }
  ];

  if (fields) {
    fields.map((field) => {
      columns.push(
        columnHelper.accessor((row) => field.name + 'field', {
          header: field.label[language],
          enableColumnFilter: false,
          enableSorting: false,
          cell: (accessor) => {
            const values =
              accessor?.row?.original?.dynamicFieldValues?.values ?? null;

            const value =
              values && values[field.name] ? values[field.name] : null;

            if (value && field.fieldType === 'DATE') {
              return moment(value).format('L');
            }

            if (value && field.fieldType === 'DATETIME') {
              return moment(value).format('L LT');
            }

            return value;
          }
        })
      );
    });
  }

  columns.push(
    columnHelper.accessor('recordId', {
      header: '',
      enableColumnFilter: false,
      enableSorting: false,
      cell: (accessor) => {
        return (
          <div>
            <UserActions
              user={accessor.row.original}
              refreshList={refreshList}
            />
          </div>
        );
      }
    })
  );

  const fetchData = async (params: RequestParams): Promise<ReturnData> => {
    const response =
      await shopizerApiClient.customerApi.getOnboardingListUsingGET({
        ...params,
        lang: language,
        page: params.page - 1,
        filter: JSON.stringify(params.filter)
      });

    return {
      recordsTotal: response.totalCount,
      items: response.onboardings
    };
  };

  const uploadData = async (): Promise<string> => {
    const response =
      await shopizerApiClient.customerApi.uploadCustomersUsingPOST({
        lang: language,
        store: organization.code,
        file: selectedCsv
      });

    return response;
  };

  const [selectedCsv, setSelectedCsv] = useState(null);

  useEffect(() => {
    if (selectedCsv) {
      uploadData();
    }
  }, [selectedCsv]);

  return (
    <Wrapper>
      <h1>{t('menu.users')}</h1>

      <Paper sx={{ height: '100%', marginBottom: 2, padding: 0 }}>
          <UserForm
            key={'add-user-form'}
            open={open}
            onClose={handleClose}
            type={'add'}
            onSave={refreshList}
          />
          <input id="csvFile" type="file" accept="text/csv" style={{ display: 'none' }} 
            onChange={e => setSelectedCsv(e.target.files[0])}/>
          <label htmlFor="csvFile">
            <Button variant="contained" color="primary" startIcon={<Add/>} component="span">{t('button.addMultiple')}</Button>
          </label>
        <DataTable
          columns={columns}
          requestType={'fetch'}
          refreshListCount={refreshListCount}
          fetch={fetchData}
          tableActions={[
            {
              type: 'action',
              variant: 'contained',
              action: handleOpen,
              icon: <Add />,
              label: t('button.add')
            }
          ]}
        />
      </Paper>
    </Wrapper>
  );
};
