import differenceInDays from 'date-fns/differenceInDays';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import i18n from '../../core/i18n';

export function setLocalData(key, value) {
  try {
    localStorage.setItem(key, value);
  } catch (error) {
    // console.log("error", error)
  }
}

export function removeLocalData(key) {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    // console.log("error", error)
  }
}
export function getLocalData(key) {
  try {
    return localStorage.getItem(key);
  } catch (error) {
    // console.log("error", error)
  }
}

export function setLocalDataParsed(key, value) {
  if (value === null) {
    localStorage.removeItem(key);
    return;
  }

  localStorage.setItem(key, JSON.stringify(value));
}

export function getLocalDataParsed(key, defaultValue) {
  const data = getLocalData(key);
  if (!data) return defaultValue;

  const parsedData = JSON.parse(data);
  if (!parsedData) return defaultValue;

  return parsedData;
}

export function isValidValue(value) {
  if (typeof value === 'undefined' || value === null || value === '') {
    return false;
  }
  return true;
}
export function isCheckValueAndSetParams(params, value) {
  if (typeof value === 'undefined' || value === null || value === '') {
    return '';
  }
  return params + value;
}
export function hasProperty(object, key) {
  if (Array.isArray(object) || typeof key != 'string' || !object) {
    return false;
  } else {
    return object.hasOwnProperty(key);
  }
}
export function isValidObject(items) {
  if (Object.keys(items).length > 0) {
    return true;
  } else {
    return false;
  }
}
export function getValueFromObject(object, key) {
  if (hasProperty(object, key)) {
    if (isValidValue(object[key])) {
      return object[key];
    }
  }
  return '';
}
// export function getFormDataObj(obj) {
//   let formData = new FormData()
//   for (let i in obj) {
//     if (obj.hasOwnProperty(i)) {
//       let element = obj[i];
//       formData.append(i, element)
//     }
//   }
//   return formData
// }

// Format price
export function formatPrice(number) {
  const fnumber = parseFloat(number);
  return new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR'
  }).format(fnumber);
}

export function formatDate(date: Date, displayTime: boolean = false) {
  return moment(date)
    .locale(i18n.language)
    .format(displayTime ? 'L LT' : 'L');
}

export const diffInDays = (startDate: Date, endDate: Date) => {
  return differenceInDays(endDate, startDate) + 1;
};

export function buildRandomKey() {
  return uuidv4();
}

// Capitalize
export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const displayNoDigits = (value: string | number, length: number = 2) => {
  return value.toLocaleString('en', {
    minimumIntegerDigits: length,
    useGrouping: false
  });
};

const parseDateValue = (
  originalValue: string,
  defaultValue: number,
  length: number = 2
): string => {
  if (!isNaN(Number(originalValue))) {
    return originalValue;
  }

  return displayNoDigits(defaultValue, length);
};

// yyyyMMddT00:00:00.000
export function getDateTimeFromBackendFormat(format: string): Date {
  const now = new Date();

  const match = format.match(
    /(\d{4}|[a-z]{4})(\d{2}|[A-Z]{2})(\d{2}|[a-z]{2})T(\d{2}|[a-z]{2}):(\d{2}|[a-z]{2}):(\d{2}|[a-z]{2}).(\d{3}|[a-z]{3})/
  );

  if (format.length !== 21 || !match) {
    console.log(
      `Date format error: ${format}. Accepted format is yyyyMMddThh:ii:ss.fff`
    );

    return now;
  }

  const year = parseDateValue(match[1], now.getFullYear(), 4);
  const month = parseDateValue(match[2], now.getMonth() + 1);
  const day = parseDateValue(match[3], now.getDate());
  const hour = parseDateValue(match[4], now.getHours());
  const minute = parseDateValue(match[5], now.getMinutes());
  const second = parseDateValue(match[6], now.getSeconds());
  const millisecond = parseDateValue(match[7], now.getMilliseconds(), 3);

  const from = `${year}-${month}-${day}T${hour}:${minute}:${second}.${millisecond}`;
  return new Date(from);
}

// 00y00M07d23h59m59s999S
export function getDateTimeFromBackendPeriod(
  newStartDate: Date,
  format: string
) {
  const match = format.match(
    /(\d{2})y(\d{2})M(\d{2})d(\d{2})h(\d{2})m(\d{2})s(\d{3})S/
  );
  if (format.length !== 22 || !match)
    console.log(
      `Period format error: ${format}. Accepted format is 00y00M07d23h59m59s999S`
    );

  const period = {
    year: Number(match[1]),
    month: Number(match[2]),
    day: Number(match[3]),
    hour: Number(match[4]),
    minute: Number(match[5]),
    second: Number(match[6]),
    millisecond: Number(match[7])
  };

  return moment(newStartDate).add(period).toDate();
}
