import Grid from '@mui/material/Grid';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { selectActiveCustomer } from '../../account/customerSlice';
import {
  fetchKpis,
  resetFilters,
  selectFilterAssetType,
  selectFilterDepotCode,
  selectFilterPeriod,
  selectHasFilters,
  selectIsLoadingKpis,
  setFilterAssetType,
  setFilterDepot,
  setFilterPeriod
} from '../kpiSlice';
import { AssetType } from './AssetType';
import { Depot } from './Depot';
import {
  generatePeriodLast30Days,
  generatePeriodLast3Months,
  generatePeriodLast7Days,
  generatePeriodLastMonth,
  generatePeriodThisMonth,
  generatePeriodThisYear,
  generatePeriodToday,
  generatePeriodYesterday,
  Period
} from './Period';
import { Refresh } from './Refresh';
import { Reset } from './Reset';

export const KpiFilters = () => {
  const dispatch = useAppDispatch();
  const managerDepots = useAppSelector(selectActiveCustomer).managerDepots;

  const isLoading = useAppSelector(selectIsLoadingKpis);
  const hasFilters = useAppSelector(selectHasFilters);

  const selectedAssetType = useAppSelector(selectFilterAssetType);
  const selectedDepotCode = useAppSelector(selectFilterDepotCode);
  const selectedPeriod = useAppSelector(selectFilterPeriod);
  const selectedDepot = (managerDepots ?? []).find(
    (d) => d.storeCode === selectedDepotCode
  );

  const periods = [
    generatePeriodLast3Months(),
    generatePeriodLastMonth(),
    generatePeriodLast30Days(),
    generatePeriodLast7Days(),
    generatePeriodYesterday(),
    generatePeriodToday(),
    generatePeriodThisMonth(),
    generatePeriodThisYear()
  ];

  return (
    <Grid
      container
      mb={1}
      spacing={1}
      justifyContent="flex-end"
      alignItems="center"
    >
      <Refresh isLoading={isLoading} onClick={() => dispatch(fetchKpis())} />

      <AssetType
        selectedDepot={selectedDepot}
        selectedAssetType={selectedAssetType}
        setAssetType={(assetType) => {
          dispatch(setFilterAssetType(assetType));
        }}
      />

      <Depot
        selectedDepot={selectedDepot}
        setDepot={(depotCode) => {
          dispatch(setFilterDepot(depotCode));
        }}
      />

      <Period
        setPeriod={(period) => {
          dispatch(setFilterPeriod(period));
        }}
        selectedPeriod={selectedPeriod}
        periods={periods}
      />

      {hasFilters && <Reset onClick={() => dispatch(resetFilters())} />}
    </Grid>
  );
};
