import config from '../../config';
import {
  Configuration,
  ConfigurationParameters,
  CustomerManagementResourceCustomerManagementApiApi,
  FetchAPI,
  HTTPHeaders,
  MerchantAndStoreManagementResourceMerchantStoreManagementApiApi,
  OrderingApiOrderFlowApiApi,
  ShoppingCartApiApi
} from './generated';

export * from './generated';

export type ApiArgs = {
  fetchApi?: FetchAPI;
  headers?: () => Promise<HTTPHeaders>;
};

/**
 * Configures the fetch parameters so that it checks the contents of the store for header values
 * as each request is sent.
 */
function makeConfiguration(args: ApiArgs): ConfigurationParameters {
  return {
    basePath: config.shopizerApiUrl.replace('/api/v1', ''),
    fetchApi: args.fetchApi,
    headers: args.headers
  };
}

export type ShopizerApiClient = ReturnType<typeof newShopizerApiClient>;

export function newShopizerApiClient(store: ApiArgs) {
  const configuration = new Configuration(makeConfiguration(store));

  const make = <T>(t: new (configuration: Configuration) => T): T => {
    return new t(configuration);
  };

  return {
    orderApi: make(OrderingApiOrderFlowApiApi),
    shoppingCartApi: make(ShoppingCartApiApi),
    storeApi: make(
      MerchantAndStoreManagementResourceMerchantStoreManagementApiApi
    ),
    customerApi: make(CustomerManagementResourceCustomerManagementApiApi)
  };
}
