import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
  customerReducer,
  CustomerState
} from '../feature/account/customerSlice';
import { cartReducer, CartState } from '../feature/cart/cartSlice';
import { depotReducer, DepotState } from '../feature/depot/depotSlice';
import { kpiReducer, KpiState } from '../feature/home/kpiSlice';
import {
  organizationReducer,
  OrganizationState
} from '../feature/organization/organizationSlice';
import {
  reservationReducer,
  ReservationState
} from '../feature/reservations/reservationSlice';

const persistConfig = {
  key: 'root',
  storage
};

const appReducer = combineReducers({
  customer: customerReducer,
  organization: organizationReducer,
  reservation: reservationReducer,
  cart: cartReducer,
  kpi: kpiReducer,
  depot: depotReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET') {
    storage.removeItem('persist:root');
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production'
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<typeof store.getState>;
export type RootState = {
  customer: CustomerState;
  organization: OrganizationState;
  reservation: ReservationState;
  cart: CartState;
  kpi: KpiState;
  depot: DepotState;
};
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;
export type AppGetState = () => RootState;

export type StateDispatch = {
  getState: AppGetState;
  dispatch: AppDispatch;
};
