import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  useMediaQuery
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/styles';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useShopizerApiClient } from '../../apis';
import {
  OnboardingCheckUsingPOSTRequest,
  ReadableOnboarding
} from '../../apis/shopizer';
import { DisplayDynamicFields } from '../../components/dynamic-forms/DisplayDynamicFields';
import FormProvider from '../../components/hook-form/FormProvider';
import { RHFSelect } from '../../components/hook-form/RHFSelect';
import RHFTextField from '../../components/hook-form/RHFTextField';
import { useAppSelector } from '../../hooks';
import {
  Language,
  supportedLanguages,
  useLanguage
} from '../../store/LanguageProvider';
import { selectActiveCustomer } from '../account/customerSlice';
import { useDynamicFields } from '../dynamic-forms/UseDynamicFields';
import { selectOrganization } from '../organization/organizationSlice';

type UserFormProp = {
  open: boolean;
  onClose: () => void;
  onSave?: () => void;
} & (
  | {
      type: 'add';
    }
  | {
      type: 'update';
      originalValues: ReadableOnboarding;
    }
);

type FormValuesProps = OnboardingCheckUsingPOSTRequest & {
  afterSubmit?: string;
};

export const UserForm = (props: UserFormProp) => {
  const { open, onClose, onSave, type } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const shopizerApiClient = useShopizerApiClient();
  const customer = useAppSelector(selectActiveCustomer);
  const managerDepots = customer.managerDepots;
  const organization = useAppSelector(selectOrganization);
  const { language } = useLanguage();
  const { fields, processData, generateConditions } = useDynamicFields(
    'MANAGE_USERS_FIELDS'
  );

  useEffect(() => {
    if (!open) reset();
  }, [open]);

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().required(t('validation.firstName.required')),
    lastName: Yup.string().required(t('validation.lastName.required')),
    store: Yup.string(),
    lang: Yup.string(),
    email: Yup.string()
      .required(t('validation.email.required'))
      .email(t('validation.email.valid')),
    ...generateConditions()
  });

  const defaultValues: FormValuesProps = {
    organization: organization.code,
    store: undefined,
    email: '',
    lastName: '',
    firstName: '',
    lang: language
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(RegisterSchema),
    defaultValues
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful }
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      if (type === 'add') {
        await shopizerApiClient.customerApi.onboardingCheckUsingPOST({
          email: data.email,
          lastName: data.lastName,
          firstName: data.firstName,
          lang: data.lang,
          store: data.store,
          organization: organization.code,
          nrOfPeriods: data.nrOfPeriods,
          dynamicFieldValues: {
            values: processData(data)
          }
        });
      }

      if (onSave) onSave();
      onClose();
    } catch (error) {
      console.error(error);
      setError('afterSubmit', {
        ...error,
        message: error.message || error
      });
    }
  };

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      onClose={onClose}
      fullWidth={true}
      aria-labelledby="responsive-dialog-title"
      sx={{
        '& .MuiPaper-root': { m: 0 },
        '& .MuiBackdrop-root': {
          // backgroundColor: 'rgba(0, 0, 0, 0.9)'
        }
      }}
    >
      <DialogTitle
        id="responsive-dialog-title"
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1
        }}
      >
        {t(`button.${type}`)}
      </DialogTitle>
      <DialogContent
        dividers
        sx={{ borderTop: `1px dashed ${theme.palette.divider}` }}
      >
        <FormProvider methods={methods}>
          <Stack spacing={2.5} pt={1}>
            {!!errors.afterSubmit && (
              <Alert severity="error">{errors.afterSubmit.message}</Alert>
            )}

            {/*<pre>{JSON.stringify(errors, null, 2)}</pre>*/}

            <RHFTextField name="email" label={t('emailAddress')} />

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <RHFTextField name="firstName" label={t('firstName')} />
              <RHFTextField name="lastName" label={t('lastName')} />
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <RHFSelect name={'lang'} label={t('language')}>
                {Object.keys(supportedLanguages).map((lngCode: Language) => {
                  return (
                    <MenuItem value={lngCode}>
                      {supportedLanguages[lngCode]}
                    </MenuItem>
                  );
                })}
              </RHFSelect>
              <RHFSelect name={'store'} label={t('location')}>
                {managerDepots.map((d) => (
                  <MenuItem value={d.storeCode}>{d.storeName}</MenuItem>
                ))}
              </RHFSelect>
            </Stack>
            <DisplayDynamicFields fields={fields} />
          </Stack>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          variant={'contained'}
          formTarget={'test-id'}
          onClick={handleSubmit(onSubmit)}
        >
          {t(`button.${type}`)}
        </Button>
        <Button variant={'contained'} color={'error'} onClick={onClose}>
          {t('translation:button.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
