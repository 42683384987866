import InfoIcon from '@mui/icons-material/Info';
import { ClickAwayListener } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';

import { useState } from 'react';

export const TooltipClick = ({
  content
}: {
  content: JSX.Element | string;
}) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 300,
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #999'
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: '#f5f5f9',
      '&::before': {
        backgroundColor: '#f5f5f9',
        border: '1px solid #999'
      }
    }
  }));

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <HtmlTooltip
          PopperProps={{
            disablePortal: true
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={content}
          arrow
          placement={'bottom'}
        >
          <InfoIcon sx={{ cursor: 'pointer' }} onClick={handleTooltipOpen} />
        </HtmlTooltip>
      </div>
    </ClickAwayListener>
  );
};
