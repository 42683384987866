import { useAppSelector } from '../../hooks';
import { isDepotManager } from '../account/customerSlice';
import { HomeDepotManager } from './HomeDepotManager';
import { HomeUser } from './HomeUser';

export const HomeScreen = () => {
  if (useAppSelector(isDepotManager)) return <HomeDepotManager />;

  return <HomeUser />;
};
