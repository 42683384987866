import { Navigate } from 'react-router-dom';
import { PATH_DASHBOARD } from '../routes/paths';
import UserService from './userService';

type GuestGuardProps = {
  children: React.ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  if (UserService.isLoggedIn()) {
    return <Navigate to={PATH_DASHBOARD.root} replace />;
  }

  return <> {children} </>;
}
