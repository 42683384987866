import { TextField } from '@mui/material';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { DurationConfigType } from './SelectPeriod';
import { DateValidationError } from '@mui/x-date-pickers/internals/hooks/validation/useDateValidation';
import { Language, useLanguage } from '../../../store/LanguageProvider';

export function PeriodStartDate(props: {
  onChange: (fromDate: Date) => void;
  value: Date;
  durationConfig: DurationConfigType;
}) {
  const { t } = useTranslation('translation');
  const { language } = useLanguage();

  if (!props.value) return <></>;

  if (props.durationConfig.startTimeGranularity) {
    const minutes =
      Math.ceil(
        props.value.getMinutes() / props.durationConfig.startTimeGranularity
      ) * props.durationConfig.startTimeGranularity;

    if (minutes !== props.value.getMinutes()) {
      console.log('min diff: ', minutes, props.value.getMinutes());
      if (minutes > 59) {
        props.value.setHours(props.value.getHours() + 1);
        props.value.setMinutes(minutes - 60);
      } else {
        props.value.setMinutes(minutes);
      }
      props.value.setSeconds(0);
      props.value.setMilliseconds(0);

      props.onChange(props.value);
    }
  }

  const startTimeGranularity =
    props.durationConfig.startTimeGranularity !== 0
      ? props.durationConfig.startTimeGranularity
      : 1;

  const startDateHelper = props.durationConfig.startDateHelperText ? props.durationConfig.startDateHelperText[language] : undefined;      
  if (props.durationConfig.bookingUnit >= 1440) {

    return (
      <DatePicker
        label={t('translation:fromDate')}
        value={props.value}
        disabled={props.durationConfig.fixedStartPeriod}
        disablePast={true}
        maxDate={ props.durationConfig.startDatePeriodMax ? moment(new Date()).add((props.durationConfig.startDatePeriodMax * props.durationConfig.bookingUnit), 'minutes') : undefined }
        shouldDisableDate={ (date) => props.durationConfig.disableDays ? props.durationConfig.disableDays.includes(date.day()) : false }
        onChange={(date: Moment) => {
          if (!date || !date.isValid()) return;
          props.onChange(date.toDate());
        }}
        renderInput={(params) => {
          return <TextField {...params} sx={{ width: '100%' }} helperText={startDateHelper} />;
        }}
      />
    );
  }
  var nowDate = new Date();
  nowDate.setSeconds(0);
  nowDate.setMilliseconds(0);
  return (
    <DateTimePicker
      label={t('translation:fromDate')}
      disablePast={true}
      maxDateTime={ props.durationConfig.startDatePeriodMax ? moment(nowDate).add((props.durationConfig.startDatePeriodMax * props.durationConfig.bookingUnit), 'minutes') : undefined }
      minDateTime={ moment(nowDate) }
      shouldDisableDate={ (date) => props.durationConfig.disableDays ? props.durationConfig.disableDays.includes(date.day()) : false }
      shouldDisableTime={ (value, view) => props.durationConfig.disableStartHours ? view === 'hours' && props.durationConfig.disableStartHours.includes(value) : false }
      disabled={props.durationConfig.fixedStartPeriod}
      minutesStep={startTimeGranularity}
      renderInput={(params) => {
        return (
          <TextField
            // disabled={true} aria-readonly={true}
            {...params}
            sx={{ width: '100%' }}
            helperText={startDateHelper}
          />
        );
      }}
      value={props.value}
      onChange={(date: Moment) => {
        if (!date || !date.isValid()) return;
        props.onChange(date.toDate());
      }}
    />
  );
}
