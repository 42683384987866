import config from '../../config';
import keycloak from './Keycloak';

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
  keycloak
    .init({
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri:
        window.location.origin + '/silent-check-sso.html',
      pkceMethod: 'S256',
      /* "Starting with Chrome version 80 (released on February 2020), status iframe
          will only be able to see the special cookie over the SSL / TLS connection
          configured on the Keycloak side. Using an insecure connection may lead to
          redirecting to Keycloak every time iframe checks the status. You can avoid
          this behavior by disabling iframe or configuring the SSL / TLS on the Keycloak side."

          So checkLoginIframe = false should be used only for non-SSL/TLS environments as local dev.

          https://github.com/mauriciovigolo/keycloak-angular/issues/39
          https://www.keycloak.org/docs/latest/securing_apps/#session-status-iframe
      */
      checkLoginIframe: config.keycloakCheckLoginIframe
    })
    .then((authenticated) => {
      if (!authenticated) {
        console.log('user is not authenticated!');
        // doLogin();
      }
      onAuthenticatedCallback();
    })
    .catch(console.error);
};

const doLogin = keycloak.login;

const doLogout = keycloak.logout;

const getToken = () => keycloak.token;

const getRefreshToken = () => keycloak.refreshToken;

const isLoggedIn = () => !!keycloak.token;

const updateToken = (successCallback) => {
  if (!isLoggedIn()) return;

  return keycloak.updateToken(5).then(successCallback).catch(doLogin);
};

const getUsername = () => keycloak.tokenParsed?.preferred_username;

const hasRole = (roles) => roles.some((role) => keycloak.hasRealmRole(role));

const throwErrorNotLoggedIn = () => {
  if (isLoggedIn()) return;
  throw new Error('user must be logged in to access this endpoint');
};

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  getRefreshToken,
  hasRole,
  throwErrorNotLoggedIn
};

export default UserService;
