import MenuItem from '@mui/material/MenuItem';
import { DynamicFieldContent } from '../../apis/shopizer';
import { useLanguage } from '../../store/LanguageProvider';
import RHFAutocomplete from '../hook-form/RHFAutocomplete';
import RHFDate from '../hook-form/RHFDate';
import RHFDateTime from '../hook-form/RHFDateTime';
import RHFRadioGroup from '../hook-form/RHFRadioGroup';
import { RHFSelect } from '../hook-form/RHFSelect';
import RHFSwitch from '../hook-form/RHFSwitch';
import RHFTextField from '../hook-form/RHFTextField';

export type IDynamicField = DynamicFieldContent;

interface DisplayDynamicFieldProps {
  field: IDynamicField;
}

export const DisplayDynamicField = ({ field }: DisplayDynamicFieldProps) => {
  const { language } = useLanguage();
  const label = field.label[language] ?? field.label[0] ?? 'no label';
  const enums: { value: string; label: string }[] = field.possibleValues
    ? Object.keys(field.possibleValues).map((key) => {
        return { value: key, label: field.possibleValues[key] };
      })
    : [];

  if (field.displayType === 'DATE') {
    return <RHFDate name={field.name} label={label} />;
  }

  if (field.displayType === 'DATETIME') {
    return <RHFDateTime name={field.name} label={label} />;
  }

  if (field.displayType === 'SELECT') {
    return (
      <RHFSelect name={field.name} label={label}>
        {enums.map(({ value, label }) => {
          return <MenuItem value={value}>{label}</MenuItem>;
        })}
      </RHFSelect>
    );
  }

  if (field.displayType === 'RADIO') {
    return <RHFRadioGroup name={field.name} label={label} options={enums} />;
  }

  // if (field.displayType === 'slider') {
  //   return (
  //     <RHFSlider
  //       name={field.name}
  //       // helperText={label}
  //     />
  //   );
  // }

  if (field.displayType === 'AUTOCOMPLETE') {
    return <RHFAutocomplete name={field.name} options={enums} />;
  }

  if (field.displayType === 'SWITCH') {
    return <RHFSwitch name={field.name} label={label} />;
  }

  return <RHFTextField name={field.name} label={label} />;
};
