import UserService from './userService';

type AuthGuardProps = {
  children: React.ReactNode;
};
export default function AuthGuard({ children }: AuthGuardProps) {
  if (!UserService.isLoggedIn()) {
    // localStorage.clear();
    UserService.doLogin();
    return <></>;
  }

  return <> {children} </>;
}
