/* tslint:disable */
/* eslint-disable */
/**
 * Shopizer REST API
 * API for Shopizer e-commerce. Contains public end points as well as private end points requiring basic authentication and remote authentication based on jwt bearer token. URL patterns containing /private/_** use bearer token; those are authorized customer and administrators administration actions.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
  CustomerReq,
  CustomerRes,
  DynamicFieldValues,
  OnboardingUpdateResponse,
  ReadableCustomer,
  ReadableCustomerList,
  ReadableOnboardingHistoryList,
  ReadableOnboardingList,
  ReadableValidationResponse
} from '../models';
import * as runtime from '../runtime';

export interface CreateUsingPOSTRequest {
  customer: CustomerReq;
  store?: string;
}

export interface DeleteUsingDELETERequest {
  store?: string;
}

export interface DeleteUsingDELETE1Request {
  id: number;
  store?: string;
}

export interface GetAuthUserUsingGETRequest {
  lang?: string;
  store?: string;
}

export interface GetAuthUserUsingGET1Request {
  lang?: string;
  store?: string;
}

export interface GetOnboardingHistoryListUsingGETRequest {
  accountNonExpired?: boolean;
  accountNonLocked?: boolean;
  authorities0Authority?: string;
  count?: number;
  credentialsNonExpired?: boolean;
  email?: string;
  enabled?: boolean;
  filter?: string;
  firstName?: string;
  id?: number;
  lang?: string;
  lastName?: string;
  page?: number;
  password?: string;
  sortColumn?: string;
  sortOrder?: string;
  username?: string;
}

export interface GetOnboardingListUsingGETRequest {
  accountNonExpired?: boolean;
  accountNonLocked?: boolean;
  authorities0Authority?: string;
  count?: number;
  credentialsNonExpired?: boolean;
  email?: string;
  enabled?: boolean;
  filter?: string;
  firstName?: string;
  id?: number;
  lang?: string;
  lastName?: string;
  page?: number;
  password?: string;
  sortColumn?: string;
  sortOrder?: string;
  username?: string;
}

export interface GetUsingGETRequest {
  id: number;
  lang?: string;
  store?: string;
}

export interface ListUsingGETRequest {
  count?: number;
  lang?: string;
  page?: number;
  store?: string;
}

export interface OnboardingCheckUsingPOSTRequest {
  lang: string;
  organization: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  nrOfPeriods?: number;
  store?: string;
  dynamicFieldValues?: DynamicFieldValues;
}

export interface OnboardingDescUsingPOSTRequest {
  description: string;
  lang: string;
  onboardingStatus?: OnboardingDescUsingPOSTOnboardingStatusEnum;
  status?: OnboardingDescUsingPOSTStatusEnum;
  store?: string;
}

export interface OnboardingStatusUpdateUsingPOSTRequest {
  enabled: boolean;
  lang: string;
  recordId: number;
  accountNonExpired?: boolean;
  accountNonLocked?: boolean;
  authorities0Authority?: string;
  credentialsNonExpired?: boolean;
  email?: string;
  firstName?: string;
  id?: number;
  lastName?: string;
  password?: string;
  username?: string;
}

export interface UpdateAddressUsingPATCHRequest {
  id: number;
  customer: CustomerReq;
  store?: string;
}

export interface UpdateAuthUserAddressUsingPATCHRequest {
  customer: CustomerReq;
  store?: string;
}

export interface UpdateUsingPATCHRequest {
  customer: CustomerReq;
  store?: string;
}

export interface UpdateUsingPUTRequest {
  customer: CustomerReq;
  accountNonExpired?: boolean;
  accountNonLocked?: boolean;
  authorities0Authority?: string;
  credentialsNonExpired?: boolean;
  email?: string;
  enabled?: boolean;
  firstName?: string;
  id?: number;
  lastName?: string;
  password?: string;
  store?: string;
  username?: string;
}

export interface UpdateUsingPUT1Request {
  id: number;
  customer: CustomerReq;
  store?: string;
}

export interface UploadCustomersUsingPOSTRequest {
  lang?: string;
  store?: string;
  file?: Blob;
}

/**
 *
 */
export class CustomerManagementResourceCustomerManagementApiApi extends runtime.BaseAPI {
  /**
   * Requires administration access
   * Creates a customer
   */
  async createUsingPOSTRaw(
    requestParameters: CreateUsingPOSTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ReadableCustomer>> {
    if (
      requestParameters.customer === null ||
      requestParameters.customer === undefined
    ) {
      throw new runtime.RequiredError(
        'customer',
        'Required parameter requestParameters.customer was null or undefined when calling createUsingPOST.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.store !== undefined) {
      queryParameters['store'] = requestParameters.store;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/private/customer`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.customer
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Requires administration access
   * Creates a customer
   */
  async createUsingPOST(
    requestParameters: CreateUsingPOSTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ReadableCustomer> {
    const response = await this.createUsingPOSTRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Requires authentication
   * Deletes a loged in customer profile
   */
  async deleteUsingDELETERaw(
    requestParameters: DeleteUsingDELETERequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    if (requestParameters.store !== undefined) {
      queryParameters['store'] = requestParameters.store;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/auth/customer/`,
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Requires authentication
   * Deletes a loged in customer profile
   */
  async deleteUsingDELETE(
    requestParameters: DeleteUsingDELETERequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.deleteUsingDELETERaw(requestParameters, initOverrides);
  }

  /**
   * Requires administration access
   * Deletes a customer
   */
  async deleteUsingDELETE1Raw(
    requestParameters: DeleteUsingDELETE1Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling deleteUsingDELETE1.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.store !== undefined) {
      queryParameters['store'] = requestParameters.store;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/private/customer/{id}`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Requires administration access
   * Deletes a customer
   */
  async deleteUsingDELETE1(
    requestParameters: DeleteUsingDELETE1Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.deleteUsingDELETE1Raw(requestParameters, initOverrides);
  }

  /**
   * getAuthUser
   */
  async getAuthUserUsingGETRaw(
    requestParameters: GetAuthUserUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ReadableCustomer>> {
    const queryParameters: any = {};

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    if (requestParameters.store !== undefined) {
      queryParameters['store'] = requestParameters.store;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/auth/customer/profile`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * getAuthUser
   */
  async getAuthUserUsingGET(
    requestParameters: GetAuthUserUsingGETRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ReadableCustomer> {
    const response = await this.getAuthUserUsingGETRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * getAuthUser
   */
  async getAuthUserUsingGET1Raw(
    requestParameters: GetAuthUserUsingGET1Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ReadableCustomer>> {
    const queryParameters: any = {};

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    if (requestParameters.store !== undefined) {
      queryParameters['store'] = requestParameters.store;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/private/customer/profile`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * getAuthUser
   */
  async getAuthUserUsingGET1(
    requestParameters: GetAuthUserUsingGET1Request = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ReadableCustomer> {
    const response = await this.getAuthUserUsingGET1Raw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * getOnboardingHistoryList
   */
  async getOnboardingHistoryListUsingGETRaw(
    requestParameters: GetOnboardingHistoryListUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ReadableOnboardingHistoryList>> {
    const queryParameters: any = {};

    if (requestParameters.accountNonExpired !== undefined) {
      queryParameters['accountNonExpired'] =
        requestParameters.accountNonExpired;
    }

    if (requestParameters.accountNonLocked !== undefined) {
      queryParameters['accountNonLocked'] = requestParameters.accountNonLocked;
    }

    if (requestParameters.authorities0Authority !== undefined) {
      queryParameters['authorities[0].authority'] =
        requestParameters.authorities0Authority;
    }

    if (requestParameters.count !== undefined) {
      queryParameters['count'] = requestParameters.count;
    }

    if (requestParameters.credentialsNonExpired !== undefined) {
      queryParameters['credentialsNonExpired'] =
        requestParameters.credentialsNonExpired;
    }

    if (requestParameters.email !== undefined) {
      queryParameters['email'] = requestParameters.email;
    }

    if (requestParameters.enabled !== undefined) {
      queryParameters['enabled'] = requestParameters.enabled;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters['filter'] = requestParameters.filter;
    }

    if (requestParameters.firstName !== undefined) {
      queryParameters['firstName'] = requestParameters.firstName;
    }

    if (requestParameters.id !== undefined) {
      queryParameters['id'] = requestParameters.id;
    }

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    if (requestParameters.lastName !== undefined) {
      queryParameters['lastName'] = requestParameters.lastName;
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page;
    }

    if (requestParameters.password !== undefined) {
      queryParameters['password'] = requestParameters.password;
    }

    if (requestParameters.sortColumn !== undefined) {
      queryParameters['sortColumn'] = requestParameters.sortColumn;
    }

    if (requestParameters.sortOrder !== undefined) {
      queryParameters['sortOrder'] = requestParameters.sortOrder;
    }

    if (requestParameters.username !== undefined) {
      queryParameters['username'] = requestParameters.username;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/private/customer/onboardinghistory`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * getOnboardingHistoryList
   */
  async getOnboardingHistoryListUsingGET(
    requestParameters: GetOnboardingHistoryListUsingGETRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ReadableOnboardingHistoryList> {
    const response = await this.getOnboardingHistoryListUsingGETRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * getOnboardingList
   */
  async getOnboardingListUsingGETRaw(
    requestParameters: GetOnboardingListUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ReadableOnboardingList>> {
    const queryParameters: any = {};

    if (requestParameters.accountNonExpired !== undefined) {
      queryParameters['accountNonExpired'] =
        requestParameters.accountNonExpired;
    }

    if (requestParameters.accountNonLocked !== undefined) {
      queryParameters['accountNonLocked'] = requestParameters.accountNonLocked;
    }

    if (requestParameters.authorities0Authority !== undefined) {
      queryParameters['authorities[0].authority'] =
        requestParameters.authorities0Authority;
    }

    if (requestParameters.count !== undefined) {
      queryParameters['count'] = requestParameters.count;
    }

    if (requestParameters.credentialsNonExpired !== undefined) {
      queryParameters['credentialsNonExpired'] =
        requestParameters.credentialsNonExpired;
    }

    if (requestParameters.email !== undefined) {
      queryParameters['email'] = requestParameters.email;
    }

    if (requestParameters.enabled !== undefined) {
      queryParameters['enabled'] = requestParameters.enabled;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters['filter'] = requestParameters.filter;
    }

    if (requestParameters.firstName !== undefined) {
      queryParameters['firstName'] = requestParameters.firstName;
    }

    if (requestParameters.id !== undefined) {
      queryParameters['id'] = requestParameters.id;
    }

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    if (requestParameters.lastName !== undefined) {
      queryParameters['lastName'] = requestParameters.lastName;
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page;
    }

    if (requestParameters.password !== undefined) {
      queryParameters['password'] = requestParameters.password;
    }

    if (requestParameters.sortColumn !== undefined) {
      queryParameters['sortColumn'] = requestParameters.sortColumn;
    }

    if (requestParameters.sortOrder !== undefined) {
      queryParameters['sortOrder'] = requestParameters.sortOrder;
    }

    if (requestParameters.username !== undefined) {
      queryParameters['username'] = requestParameters.username;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/private/customer/onboarding`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * getOnboardingList
   */
  async getOnboardingListUsingGET(
    requestParameters: GetOnboardingListUsingGETRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ReadableOnboardingList> {
    const response = await this.getOnboardingListUsingGETRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * get
   */
  async getUsingGETRaw(
    requestParameters: GetUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ReadableCustomer>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getUsingGET.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    if (requestParameters.store !== undefined) {
      queryParameters['store'] = requestParameters.store;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/private/customer/{id}`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * get
   */
  async getUsingGET(
    requestParameters: GetUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ReadableCustomer> {
    const response = await this.getUsingGETRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * list
   */
  async listUsingGETRaw(
    requestParameters: ListUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ReadableCustomerList>> {
    const queryParameters: any = {};

    if (requestParameters.count !== undefined) {
      queryParameters['count'] = requestParameters.count;
    }

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page;
    }

    if (requestParameters.store !== undefined) {
      queryParameters['store'] = requestParameters.store;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/private/customers`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * list
   */
  async listUsingGET(
    requestParameters: ListUsingGETRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ReadableCustomerList> {
    const response = await this.listUsingGETRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Onboarding checks
   */
  async onboardingCheckUsingPOSTRaw(
    requestParameters: OnboardingCheckUsingPOSTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ReadableValidationResponse>> {
    if (
      requestParameters.lang === null ||
      requestParameters.lang === undefined
    ) {
      throw new runtime.RequiredError(
        'lang',
        'Required parameter requestParameters.lang was null or undefined when calling onboardingCheckUsingPOST.'
      );
    }

    if (
      requestParameters.organization === null ||
      requestParameters.organization === undefined
    ) {
      throw new runtime.RequiredError(
        'organization',
        'Required parameter requestParameters.organization was null or undefined when calling onboardingCheckUsingPOST.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.email !== undefined) {
      queryParameters['email'] = requestParameters.email;
    }

    if (requestParameters.firstName !== undefined) {
      queryParameters['firstName'] = requestParameters.firstName;
    }

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    if (requestParameters.lastName !== undefined) {
      queryParameters['lastName'] = requestParameters.lastName;
    }

    if (requestParameters.nrOfPeriods !== undefined) {
      queryParameters['nrOfPeriods'] = requestParameters.nrOfPeriods;
    }

    if (requestParameters.organization !== undefined) {
      queryParameters['organization'] = requestParameters.organization;
    }

    if (requestParameters.store !== undefined) {
      queryParameters['store'] = requestParameters.store;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/public/customer/validate`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.dynamicFieldValues
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Onboarding checks
   */
  async onboardingCheckUsingPOST(
    requestParameters: OnboardingCheckUsingPOSTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ReadableValidationResponse> {
    const response = await this.onboardingCheckUsingPOSTRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Onboarding description insert
   */
  async onboardingDescUsingPOSTRaw(
    requestParameters: OnboardingDescUsingPOSTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.description === null ||
      requestParameters.description === undefined
    ) {
      throw new runtime.RequiredError(
        'description',
        'Required parameter requestParameters.description was null or undefined when calling onboardingDescUsingPOST.'
      );
    }

    if (
      requestParameters.lang === null ||
      requestParameters.lang === undefined
    ) {
      throw new runtime.RequiredError(
        'lang',
        'Required parameter requestParameters.lang was null or undefined when calling onboardingDescUsingPOST.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.description !== undefined) {
      queryParameters['description'] = requestParameters.description;
    }

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    if (requestParameters.onboardingStatus !== undefined) {
      queryParameters['onboardingStatus'] = requestParameters.onboardingStatus;
    }

    if (requestParameters.status !== undefined) {
      queryParameters['status'] = requestParameters.status;
    }

    if (requestParameters.store !== undefined) {
      queryParameters['store'] = requestParameters.store;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/private/customer/onboardingdescription`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Onboarding description insert
   */
  async onboardingDescUsingPOST(
    requestParameters: OnboardingDescUsingPOSTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.onboardingDescUsingPOSTRaw(requestParameters, initOverrides);
  }

  /**
   * Onboarding status update
   */
  async onboardingStatusUpdateUsingPOSTRaw(
    requestParameters: OnboardingStatusUpdateUsingPOSTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<OnboardingUpdateResponse>> {
    if (
      requestParameters.enabled === null ||
      requestParameters.enabled === undefined
    ) {
      throw new runtime.RequiredError(
        'enabled',
        'Required parameter requestParameters.enabled was null or undefined when calling onboardingStatusUpdateUsingPOST.'
      );
    }

    if (
      requestParameters.lang === null ||
      requestParameters.lang === undefined
    ) {
      throw new runtime.RequiredError(
        'lang',
        'Required parameter requestParameters.lang was null or undefined when calling onboardingStatusUpdateUsingPOST.'
      );
    }

    if (
      requestParameters.recordId === null ||
      requestParameters.recordId === undefined
    ) {
      throw new runtime.RequiredError(
        'recordId',
        'Required parameter requestParameters.recordId was null or undefined when calling onboardingStatusUpdateUsingPOST.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.accountNonExpired !== undefined) {
      queryParameters['accountNonExpired'] =
        requestParameters.accountNonExpired;
    }

    if (requestParameters.accountNonLocked !== undefined) {
      queryParameters['accountNonLocked'] = requestParameters.accountNonLocked;
    }

    if (requestParameters.authorities0Authority !== undefined) {
      queryParameters['authorities[0].authority'] =
        requestParameters.authorities0Authority;
    }

    if (requestParameters.credentialsNonExpired !== undefined) {
      queryParameters['credentialsNonExpired'] =
        requestParameters.credentialsNonExpired;
    }

    if (requestParameters.email !== undefined) {
      queryParameters['email'] = requestParameters.email;
    }

    if (requestParameters.enabled !== undefined) {
      queryParameters['enabled'] = requestParameters.enabled;
    }

    if (requestParameters.firstName !== undefined) {
      queryParameters['firstName'] = requestParameters.firstName;
    }

    if (requestParameters.id !== undefined) {
      queryParameters['id'] = requestParameters.id;
    }

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    if (requestParameters.lastName !== undefined) {
      queryParameters['lastName'] = requestParameters.lastName;
    }

    if (requestParameters.password !== undefined) {
      queryParameters['password'] = requestParameters.password;
    }

    if (requestParameters.recordId !== undefined) {
      queryParameters['recordId'] = requestParameters.recordId;
    }

    if (requestParameters.username !== undefined) {
      queryParameters['username'] = requestParameters.username;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/private/account/update`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Onboarding status update
   */
  async onboardingStatusUpdateUsingPOST(
    requestParameters: OnboardingStatusUpdateUsingPOSTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<OnboardingUpdateResponse> {
    const response = await this.onboardingStatusUpdateUsingPOSTRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Requires administration access
   * Updates a customer
   */
  async updateAddressUsingPATCHRaw(
    requestParameters: UpdateAddressUsingPATCHRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling updateAddressUsingPATCH.'
      );
    }

    if (
      requestParameters.customer === null ||
      requestParameters.customer === undefined
    ) {
      throw new runtime.RequiredError(
        'customer',
        'Required parameter requestParameters.customer was null or undefined when calling updateAddressUsingPATCH.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.store !== undefined) {
      queryParameters['store'] = requestParameters.store;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/private/customer/{id}/address`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.customer
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Requires administration access
   * Updates a customer
   */
  async updateAddressUsingPATCH(
    requestParameters: UpdateAddressUsingPATCHRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.updateAddressUsingPATCHRaw(requestParameters, initOverrides);
  }

  /**
   * Requires authentication
   * Updates a loged in customer address
   */
  async updateAuthUserAddressUsingPATCHRaw(
    requestParameters: UpdateAuthUserAddressUsingPATCHRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.customer === null ||
      requestParameters.customer === undefined
    ) {
      throw new runtime.RequiredError(
        'customer',
        'Required parameter requestParameters.customer was null or undefined when calling updateAuthUserAddressUsingPATCH.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.store !== undefined) {
      queryParameters['store'] = requestParameters.store;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/auth/customer/address`,
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.customer
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Requires authentication
   * Updates a loged in customer address
   */
  async updateAuthUserAddressUsingPATCH(
    requestParameters: UpdateAuthUserAddressUsingPATCHRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.updateAuthUserAddressUsingPATCHRaw(
      requestParameters,
      initOverrides
    );
  }

  /**
   * Requires authentication
   * Updates a loged in customer profile
   */
  async updateUsingPATCHRaw(
    requestParameters: UpdateUsingPATCHRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<CustomerRes>> {
    if (
      requestParameters.customer === null ||
      requestParameters.customer === undefined
    ) {
      throw new runtime.RequiredError(
        'customer',
        'Required parameter requestParameters.customer was null or undefined when calling updateUsingPATCH.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.store !== undefined) {
      queryParameters['store'] = requestParameters.store;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/auth/customer/`,
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.customer
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Requires authentication
   * Updates a loged in customer profile
   */
  async updateUsingPATCH(
    requestParameters: UpdateUsingPATCHRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<CustomerRes> {
    const response = await this.updateUsingPATCHRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Requires administration access
   * Updates a customer
   */
  async updateUsingPUTRaw(
    requestParameters: UpdateUsingPUTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<CustomerRes>> {
    if (
      requestParameters.customer === null ||
      requestParameters.customer === undefined
    ) {
      throw new runtime.RequiredError(
        'customer',
        'Required parameter requestParameters.customer was null or undefined when calling updateUsingPUT.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.accountNonExpired !== undefined) {
      queryParameters['accountNonExpired'] =
        requestParameters.accountNonExpired;
    }

    if (requestParameters.accountNonLocked !== undefined) {
      queryParameters['accountNonLocked'] = requestParameters.accountNonLocked;
    }

    if (requestParameters.authorities0Authority !== undefined) {
      queryParameters['authorities[0].authority'] =
        requestParameters.authorities0Authority;
    }

    if (requestParameters.credentialsNonExpired !== undefined) {
      queryParameters['credentialsNonExpired'] =
        requestParameters.credentialsNonExpired;
    }

    if (requestParameters.email !== undefined) {
      queryParameters['email'] = requestParameters.email;
    }

    if (requestParameters.enabled !== undefined) {
      queryParameters['enabled'] = requestParameters.enabled;
    }

    if (requestParameters.firstName !== undefined) {
      queryParameters['firstName'] = requestParameters.firstName;
    }

    if (requestParameters.id !== undefined) {
      queryParameters['id'] = requestParameters.id;
    }

    if (requestParameters.lastName !== undefined) {
      queryParameters['lastName'] = requestParameters.lastName;
    }

    if (requestParameters.password !== undefined) {
      queryParameters['password'] = requestParameters.password;
    }

    if (requestParameters.store !== undefined) {
      queryParameters['store'] = requestParameters.store;
    }

    if (requestParameters.username !== undefined) {
      queryParameters['username'] = requestParameters.username;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/private/customer/update`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.customer
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Requires administration access
   * Updates a customer
   */
  async updateUsingPUT(
    requestParameters: UpdateUsingPUTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<CustomerRes> {
    const response = await this.updateUsingPUTRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Requires administration access
   * Updates a customer
   */
  async updateUsingPUT1Raw(
    requestParameters: UpdateUsingPUT1Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<CustomerRes>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling updateUsingPUT1.'
      );
    }

    if (
      requestParameters.customer === null ||
      requestParameters.customer === undefined
    ) {
      throw new runtime.RequiredError(
        'customer',
        'Required parameter requestParameters.customer was null or undefined when calling updateUsingPUT1.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.store !== undefined) {
      queryParameters['store'] = requestParameters.store;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/private/customer/{id}`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.customer
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Requires administration access
   * Updates a customer
   */
  async updateUsingPUT1(
    requestParameters: UpdateUsingPUT1Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<CustomerRes> {
    const response = await this.updateUsingPUT1Raw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * uploadCustomers
   */
  async uploadCustomersUsingPOSTRaw(
    requestParameters: UploadCustomersUsingPOSTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<string>> {
    const queryParameters: any = {};

    if (requestParameters.lang !== undefined) {
      queryParameters['lang'] = requestParameters.lang;
    }

    if (requestParameters.store !== undefined) {
      queryParameters['store'] = requestParameters.store;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization'); // JWT authentication
    }

    const consumes: runtime.Consume[] = [
      { contentType: 'multipart/form-data' }
    ];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.file !== undefined) {
      formParams.append('file', requestParameters.file as any);
    }

    const response = await this.request(
      {
        path: `/api/v1/private/customers/upload`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams
      },
      initOverrides
    );

    return new runtime.TextApiResponse(response) as any;
  }

  /**
   * uploadCustomers
   */
  async uploadCustomersUsingPOST(
    requestParameters: UploadCustomersUsingPOSTRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<string> {
    const response = await this.uploadCustomersUsingPOSTRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}

/**
 * @export
 */
export const OnboardingDescUsingPOSTOnboardingStatusEnum = {
  Activated: 'ACTIVATED',
  Deactivated: 'DEACTIVATED',
  Denied: 'DENIED',
  Needsapproval: 'NEEDSAPPROVAL',
  Registered: 'REGISTERED'
} as const;
export type OnboardingDescUsingPOSTOnboardingStatusEnum =
  typeof OnboardingDescUsingPOSTOnboardingStatusEnum[keyof typeof OnboardingDescUsingPOSTOnboardingStatusEnum];
/**
 * @export
 */
export const OnboardingDescUsingPOSTStatusEnum = {
  Activated: 'ACTIVATED',
  Deactivated: 'DEACTIVATED',
  Denied: 'DENIED',
  Exception: 'EXCEPTION',
  Nazzafail: 'NAZZAFAIL',
  Needsapproval: 'NEEDSAPPROVAL',
  PreferredassetNotavailable: 'PREFERREDASSET_NOTAVAILABLE',
  Recordexists: 'RECORDEXISTS',
  Registered: 'REGISTERED',
  Registrydenied: 'REGISTRYDENIED',
  Validationfail: 'VALIDATIONFAIL',
  Whitelistfail: 'WHITELISTFAIL'
} as const;
export type OnboardingDescUsingPOSTStatusEnum =
  typeof OnboardingDescUsingPOSTStatusEnum[keyof typeof OnboardingDescUsingPOSTStatusEnum];
