import { CalendarMonth } from '@mui/icons-material';
import { Box, ListItemText, Menu, MenuItem } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import i18n from 'i18next';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import { IKpiPeriod } from '../kpiSlice';

export const Period = ({
  selectedPeriod,
  setPeriod,
  periods
}: {
  selectedPeriod: IKpiPeriod;
  setPeriod: (period: IKpiPeriod | null) => void;
  periods: IKpiPeriod[];
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleCloseMenu = () => setAnchorEl(null);

  const setSelectedPeriod = (period: IKpiPeriod | null) => {
    setPeriod(period);
    handleCloseMenu();
  };

  const selectedPeriodCalc = periods.find((p) => p.key === selectedPeriod?.key);

  return (
    <Grid item>
      <Button
        sx={{ px: 1, minWidth: 1, textTransform: 'none', fontWeight: 300 }}
        onClick={(event) => setAnchorEl(event.currentTarget)}
        variant={selectedPeriodCalc ? 'contained' : 'text'}
      >
        <CalendarMonth />
        {selectedPeriodCalc ? (
          <Box ml={1}>{selectedPeriodCalc.buttonLabel}</Box>
        ) : (
          ''
        )}
      </Button>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {periods.map((period: IKpiPeriod) => {
          return (
            <MenuItem
              key={period.label}
              sx={(theme) => {
                if (period.key !== selectedPeriodCalc?.key) return {};

                return {
                  backgroundColor: (theme) => theme.palette.primary.light
                };
              }}
              onClick={() => {
                setSelectedPeriod(period);
              }}
            >
              {/*<ListItemText primary={_.toLower(period.label)} />*/}
              <ListItemText key={period.label} primary={period.label} />
            </MenuItem>
          );
        })}
      </Menu>
    </Grid>
  );
};

export const generatePeriodToday = (): IKpiPeriod => {
  const startDate = moment().set({ h: 0, m: 0 });
  const endDate = moment().set({ h: 23, m: 59 });
  return createPeriod(
    'Today',
    startDate,
    endDate,
    i18n.t('period.today'),
    i18n.t('period.today') + ': ' + startDate.format('MMMM Do')
  );
};

export const generatePeriodYesterday = (): IKpiPeriod => {
  const startDate = moment().subtract(1, 'days').set({ h: 0, m: 0 });
  const endDate = moment().subtract(1, 'days').set({ h: 23, m: 59 });
  return createPeriod(
    'Yesterday',
    startDate,
    endDate,
    i18n.t('period.yesterday'),
    i18n.t('period.yesterday') + ': ' + startDate.format('MMMM Do')
  );
};

export const generatePeriodTomorrow = (): IKpiPeriod => {
  const startDate = moment().add(1, 'days').set({ h: 0, m: 0 });
  const endDate = moment().add(1, 'days').set({ h: 23, m: 59 });
  return createPeriod(
    'Tomorrow',
    startDate,
    endDate,
    i18n.t('period.tomorrow'),
    i18n.t('period.tomorrow') + ': ' + startDate.format('MMMM Do')
  );
};

export const generatePeriodLast7Days = (): IKpiPeriod => {
  const startDate = moment().subtract(7, 'days').set({ h: 0, m: 0 });
  const endDate = moment().set({ h: 23, m: 59 });
  return createPeriod(
    'Last7Days',
    startDate,
    endDate,
    i18n.t('period.last7days')
  );
};

export const generatePeriodLast30Days = (): IKpiPeriod => {
  const startDate = moment().subtract(30, 'days').set({ h: 0, m: 0 });
  const endDate = moment().set({ h: 23, m: 59 });
  return createPeriod(
    'Last30Days',
    startDate,
    endDate,
    i18n.t('period.last30days')
  );
};

export const generatePeriodThisMonth = (): IKpiPeriod => {
  const startDate = moment().startOf('month').set({ h: 0, m: 0 });
  const endDate = moment().endOf('month').set({ h: 23, m: 59 });
  return createPeriod(
    'ThisMonth',
    startDate,
    endDate,
    i18n.t('period.thisMonth')
  );
};

export const generatePeriodLastMonth = (): IKpiPeriod => {
  const startDate = moment()
    .subtract(1, 'months')
    .startOf('month')
    .set({ h: 0, m: 0 });
  const endDate = moment()
    .subtract(1, 'months')
    .endOf('month')
    .set({ h: 23, m: 59 });

  return createPeriod(
    'LastMonth',
    startDate,
    endDate,
    i18n.t('period.lastMonth')
  );
};

export const generatePeriodLastWeek = (): IKpiPeriod => {
  const { t } = i18n;

  const startDate = moment()
    .subtract(1, 'week')
    .startOf('week')
    .set({ h: 0, m: 0 });
  const endDate = moment()
    .subtract(1, 'week')
    .endOf('week')
    .set({ h: 23, m: 59 });

  return createPeriod(
    'LastWeek',
    startDate,
    endDate,
    i18n.t('period.lastWeek')
  );
};

export const generatePeriodThisWeek = (): IKpiPeriod => {
  const { t } = i18n;
  const startDate = moment().startOf('week').set({ h: 0, m: 0 });
  const endDate = moment().endOf('week').set({ h: 23, m: 59 });
  return createPeriod(
    'ThisWeek',
    startDate,
    endDate,
    i18n.t('period.thisWeek')
  );
};

export const generatePeriodNextWeek = (): IKpiPeriod => {
  const startDate = moment().add(7, 'days').startOf('week').set({ h: 0, m: 0 });
  const endDate = moment().add(7, 'days').endOf('week').set({ h: 23, m: 59 });
  return createPeriod(
    'NextWeek',
    startDate,
    endDate,
    i18n.t('period.nextWeek')
  );
};

export const generatePeriodLast3Months = (): IKpiPeriod => {
  const startDate = moment()
    .subtract(3, 'months')
    .startOf('month')
    .set({ h: 0, m: 0 });
  const endDate = moment()
    .subtract(1, 'months')
    .endOf('month')
    .set({ h: 23, m: 59 });

  return createPeriod(
    'Last3Months',
    startDate,
    endDate,
    i18n.t('period.last3Months')
  );
};

export const generatePeriodThisYear = (): IKpiPeriod => {
  const startDate = moment().startOf('year').set({ h: 0, m: 0 });
  const endDate = moment().endOf('year').set({ h: 23, m: 59 });

  return createPeriod(
    'ThisYear',
    startDate,
    endDate,
    i18n.t('period.thisYear')
  );
};

export const generatePeriodNextMonth = (): IKpiPeriod => {
  const startDate = moment()
    .add(1, 'months')
    .startOf('month')
    .set({ h: 0, m: 0 });
  const endDate = moment()
    .add(1, 'months')
    .endOf('month')
    .set({ h: 23, m: 59 });

  return createPeriod(
    'NextMonth',
    startDate,
    endDate,
    i18n.t('period.nextMonth')
  );
};

export const generatePeriodNext3Months = (): IKpiPeriod => {
  const startDate = moment()
    .add(1, 'months')
    .startOf('month')
    .set({ h: 0, m: 0 });
  const endDate = moment()
    .add(3, 'months')
    .endOf('month')
    .set({ h: 23, m: 59 });

  return createPeriod(
    'Next3Months',
    startDate,
    endDate,
    i18n.t('period.next3Months')
  );
};

const createPeriod = (
  key: string,
  startDate: Moment,
  endDate: Moment,
  label: string,
  buttonLabel?: string
): IKpiPeriod => {
  return {
    key,
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    label,
    buttonLabel:
      buttonLabel ??
      startDate.format('MMM Do') + ' - ' + endDate.format('MMM Do')
  };
};
