import WebService from './webService';

export const updateCurrentCustomer = async (customer) => {
  let serverCustomerData;

  try {
    serverCustomerData = await WebService.put(
      `/private/customer/update`,
      customer
    );
  } catch (e) {
    console.log('Error on update customer: ', e);
  }

  return serverCustomerData;
};
