import { Box, Button, DialogActions, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function ConfirmModal(props: {
  children: JSX.Element;
  message: string;
  messageTitle?: string;
  callback: () => void;
  cancelCallback?: () => void;
}) {
  const { t } = useTranslation('translation');
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(false);
  const [isBusy, setIsBusy] = useState(false);

  const handleClose = async () => {
    if (props.cancelCallback) await props.cancelCallback();
    setOpen(false);
  };

  const handleProceed = async () => {
    setIsBusy(true);
    try {
      await props.callback();
    } finally {
      setIsBusy(false);
    }

    setOpen(false);
  };

  return (
    <>
      <Box onClick={() => setOpen(true)}>{props.children}</Box>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        {!!props.messageTitle && (
          <DialogTitle id="responsive-dialog-title">
            {props.messageTitle}
          </DialogTitle>
        )}

        <DialogContent>
          <DialogContentText>{props.message}</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button disabled={isBusy} onClick={handleClose}>
            {t('button.cancel')}
          </Button>
          <Button disabled={isBusy} onClick={handleProceed} autoFocus>
            {t('button.yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
