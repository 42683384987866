import { MyLocation } from '@mui/icons-material';
import {
  Badge,
  Button,
  DialogActions,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  useTheme
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReadableMerchantStore } from '../../../../apis/shopizer';
import {
  resetCart,
  selectCartProductsCount
} from '../../../../feature/cart/cartSlice';
import {
  selectActiveDepot,
  selectAllDepots,
  setActiveDepot
} from '../../../../feature/depot/depotSlice';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { selectOrganization } from '../../../../feature/organization/organizationSlice';
import { isDepotManager } from '../../../../feature/account/customerSlice';

export const DepotSelector = () => {
  const { t } = useTranslation('translation');
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [anchor, setAnchor] = useState(null);
  const handleToggle = (event) => setAnchor(event.currentTarget);
  const handleCloseMenu = () => setAnchor(null);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [open, setOpen] = useState(false);
  const [tmpDepot, setTmpDepot] = useState<ReadableMerchantStore | null>(null);

  const depots = useAppSelector(selectAllDepots);
  const activeDepot = useAppSelector(selectActiveDepot);
  const organization = useAppSelector(selectOrganization);
  const cartIsEmpty = useAppSelector(selectCartProductsCount) === 0;
  const isDM = useAppSelector(isDepotManager);
  
  const handleProceed = () => {
    dispatch(setActiveDepot(tmpDepot.id));
    setTmpDepot(null);
    dispatch(resetCart());
    setOpen(false);
  };

  const handleClose = () => {
    setTmpDepot(null);
    setOpen(false);
  };

  if (depots.length < 2 || (!organization.supportReservations) && !(isDM && organization.supportAdminReservations)) {
    return <></>;
  }

  const handleChange = (depot) => {
    if (cartIsEmpty) {
      dispatch(setActiveDepot(depot.id));

      return;
    }

    setTmpDepot(depot);
    setOpen(true);
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {t('translation:locationSelection')} - {tmpDepot?.name}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('translation:locationChangeNotification')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            {t('translation:button.cancel')}
          </Button>
          <Button onClick={handleProceed} autoFocus>
            {t('translation:button.yes')}
          </Button>
        </DialogActions>
      </Dialog>

      <IconButton
        aria-owns={'language-menu'}
        aria-haspopup="true"
        onClick={handleToggle}
        color="inherit"
        size="large"
      >
        <Badge color="secondary">
          <MyLocation />
        </Badge>
      </IconButton>

      <Menu
        id="language-menu"
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClose={handleCloseMenu}
      >
        {depots.map((depot) => {
          return (
            <MenuItem
              key={depot.code}
              style={
                depot.id === activeDepot?.id
                  ? { backgroundColor: 'lightgrey' }
                  : {}
              }
              onClick={() => {
                handleChange(depot);
                handleCloseMenu();
              }}
            >
              <ListItemText primary={depot.name} />
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
