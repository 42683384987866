import LanguageIcon from '@mui/icons-material/Language';
import { Badge, IconButton, ListItemText, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import {
  Language,
  supportedLanguages,
  useLanguage
} from '../../../../store/LanguageProvider';

export const LanguageSelector = () => {
  const [anchor, setAnchor] = useState(null);
  const { language, handleLanguage } = useLanguage();
  const handleToggle = (event) => setAnchor(event.currentTarget);
  const handleCloseMenu = () => setAnchor(null);

  return (
    <>
      <IconButton
        aria-owns={'language-menu'}
        aria-haspopup="true"
        onClick={handleToggle}
        color="inherit"
        size="large"
      >
        <Badge color="secondary">
          <LanguageIcon />
        </Badge>
      </IconButton>

      <Menu
        id="language-menu"
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClose={handleCloseMenu}
      >
        {Object.keys(supportedLanguages).map((lngCode: Language) => {
          return (
            <MenuItem
              key={lngCode}
              style={
                lngCode === language ? { backgroundColor: 'lightgrey' } : {}
              }
              onClick={() => {
                handleLanguage(lngCode);
                handleCloseMenu();
              }}
            >
              <ListItemText primary={supportedLanguages[lngCode]} />
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
