import { createContext, useContext, useEffect, useState } from 'react';
import i18n from '../core/i18n';
import { selectActiveCustomer } from '../feature/account/customerSlice';
import { useAppSelector } from '../hooks';
import { selectOrganization } from '../feature/organization/organizationSlice';

type SupportedLanguagesType = {
  en: string;
  nl: string;
  de: string;
};
export type Language = 'en' | 'nl' | 'de';
// export type Language = keyof SupportedLanguagesType;

export const supportedLanguages: SupportedLanguagesType = {
  en: 'English',
  nl: 'Nederlands',
  de: 'Deutsch'
};

type HandleLanguageType = (newLanguage: Language) => void;
type LanguageContextType = {
  language: Language;
  handleLanguage: HandleLanguageType;
};
const LanguageContext = createContext<LanguageContextType>(null);

export function useLanguage() {
  return useContext(LanguageContext);
}

export function LanguageProvider({ children }) {
  const localStorageKey = 'language';
  const sessionLanguage = localStorage.getItem(localStorageKey);
  const defaultLanguage = !!supportedLanguages[sessionLanguage]
    ? sessionLanguage
    : 'en';

  const [language, setLanguage] = useState<Language>(
    defaultLanguage as Language
  );

  const organization = useAppSelector(selectOrganization);    

  const customerLanguage =
    useAppSelector(selectActiveCustomer)?.language ?? (organization?.defaultLanguage ?? null);

  useEffect(() => {
    let language: Language = 'en';

    const sessionLanguage = localStorage.getItem(localStorageKey);
    if (!!supportedLanguages[sessionLanguage]) {
      // @ts-ignore
      language = sessionLanguage;
    } else if (customerLanguage) {
      // @ts-ignore
      language = customerLanguage;
    } else if (organization) {
      // @ts-ignore
      language = organization.defaultLanguage;
    }

    setLanguage(language);
    i18n.changeLanguage(language);
  }, [customerLanguage]);

  function handleLanguage(newLanguage: Language) {
    localStorage.setItem(localStorageKey, newLanguage);
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
  }

  return (
    <LanguageContext.Provider value={{ language, handleLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
}
