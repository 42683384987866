import { parseOrderProductsForFrontend } from '../feature/products/productService';
import { Language } from '../store/LanguageProvider';
import {
  ApiOrderConfirmationResponse,
  OrderConfirmationResponse
} from '../types/order';
import WebService from './webService';

export const getOrderConfirmation = async (
  sessionId,
  language
): Promise<OrderConfirmationResponse> => {
  const path = `/order/${sessionId}?lang=${language}`;
  const response: ApiOrderConfirmationResponse = await WebService.get(path);
  return {
    ...response,
    products: parseOrderProductsForFrontend(response.products)
  };
};

export const getOrders = async () => {
  const path = `/auth/orders?page=0&count=100`;
  return await WebService.get(path);
};

export const cancelOrder = async (
  orderId: number,
  language: Language = null
) => {
  const path =
    `/orderproduct/${orderId}/cancel` + (language ? `?lang=${language}` : '');
  return await WebService.delete(path);
};

export const finishOrder = async (
  orderId: number,
  language: Language = null
) => {
  const path =
    `/orderproduct/${orderId}/finish` + (language ? `?lang=${language}` : '');
  return await WebService.post(path, {});
};
