import { selectActiveCustomer } from '../../feature/account/customerSlice';
import { selectActiveDepot } from '../../feature/depot/depotSlice';
import { selectOrganization } from '../../feature/organization/organizationSlice';
import { useAppSelector } from '../../hooks';

export const useBackendStyling = () => {
  const organization = useAppSelector(selectOrganization);
  const activeDepot = useAppSelector(selectActiveDepot);
  const customer = useAppSelector(selectActiveCustomer);

  const styling = {
    ...(organization?.styling ?? {}),
    logo: organization?.logo
  };

  if (!activeDepot?.styling || (customer && customer.useOrganizationStyling)) {
    return styling;
  }

  return {
    ...styling,
    ...activeDepot.styling,
    logo: activeDepot.logo ?? styling.logo
  };
};
