import List from '@mui/material/List';
import { useState } from 'react';
import { ISidebarItem, SidebarItem } from './SidebarItem';

interface SidebarItemsProps {
  items: ISidebarItem[];
  closeDrawer: () => void;
}

export const SidebarItems = ({ items, closeDrawer }: SidebarItemsProps) => {
  const [activeRoute, setActiveRoute] = useState(undefined);
  const toggleMenu = (index) =>
    setActiveRoute(activeRoute === index ? undefined : index);

  return (
    <List component="div">
      {items.map((item, index) => {
        return (
          <SidebarItem
            key={index}
            item={item}
            toggleMenu={() => {
              toggleMenu(index);
            }}
            closeDrawer={closeDrawer}
            openedMenu={index === activeRoute}
          />
        );
      })}
    </List>
  );
};
