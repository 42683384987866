import React, { useEffect, useState } from 'react';

import { Pagination } from '@mui/lab';
import { Box, CircularProgress, Grid } from '@mui/material';
import { useTheme } from '@mui/styles';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable
} from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import WebService from '../../webServices/webService';
import '../dataTable/DataTable.css';
import { fuzzyFilter } from '../dataTable/util';
import { Filter } from './Filter';
import { TableAction, TableActions } from './TableActions';

export type CustomColumnDef = ColumnDef<any, any> & {
  valueType?: 'number' | 'date' | 'string' | 'boolean' | 'datetime';
};

export type ReturnData = {
  items: any[];
  recordsTotal: number;
};

type DataTableProps = {
  columns: CustomColumnDef[];
  tableActions?: TableAction[];
  defaultFilters?: {};
  refreshListCount?: number;
} & (
  | {
      requestType: 'fetch';
      fetch: any;
    }
  | {
      requestType: 'apiPath';
      apiPath: string;
    }
);

export type RequestParams = {
  page: number;
  count: number;
  sortColumn?: string;
  sortOrder?: string;
  filter?: {};
};

export function DataTable(props: DataTableProps) {
  const { t } = useTranslation('translation');
  const theme = useTheme();

  const [isBusy, setIsBusy] = useState(false);
  const [loadedOnce, setLoadedOnce] = useState(false);
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [recordsTotal, setRecordsTotal] = useState(0);
  const [displayResponsive, setDisplayResponsive] = useState(false);
  const [requestParams, setRequestParams] = useState<RequestParams>({
    page: 1,
    count: 20,
    filter: props.defaultFilters ?? {}
  });

  useEffect(() => {
    if (props.defaultFilters) {
      setRequestParams((c) => {
        return {
          ...c,
          filter: props.defaultFilters
        };
      });
    }
  }, [props.defaultFilters]);

  const setPage = (page: number) => {
    setRequestParams((c) => ({ ...c, page }));
  };

  const mediaMatcher = matchMedia(`(max-width: 640px)`);
  useEffect(() => {
    setDisplayResponsive(mediaMatcher.matches);

    const listenerCallback = (match) => {
      setTimeout(() => {
        setDisplayResponsive(mediaMatcher.matches);
      }, 300);
    };

    mediaMatcher.addEventListener('change', listenerCallback);

    return () => {
      mediaMatcher.removeEventListener('change', listenerCallback);
    };
  }, []);

  const processData = (data: ReturnData) => {
    setData(data.items);
    setRecordsTotal(data.recordsTotal);
    setTotalPages(Math.ceil(data.recordsTotal / requestParams.count));
    setLoadedOnce(true);
  };

  useEffect(() => {
    setIsBusy(true);

    if (props.requestType === 'apiPath') {
      WebService.get(props.apiPath, { params: requestParams })
        .then(processData)
        .catch((err) => {
          console.error(err);
          setData([]);
        })

        .finally(() => {
          setIsBusy(false);
        });
    }

    if (props.requestType === 'fetch') {
      props
        .fetch(requestParams)
        .then(processData)
        .catch((err) => {
          console.error(err);
          setData([]);
        })
        .finally(() => {
          setIsBusy(false);
        });
    }
  }, [
    requestParams.page,
    requestParams.count,
    requestParams.sortColumn,
    requestParams.sortOrder,
    requestParams.filter,
    props.requestType,
    props.refreshListCount //this is used to trigger refresh list from actions
  ]);

  const handleSort = (column) => {
    const change = { page: 1 };
    if (column === requestParams.sortColumn) {
      if (requestParams.sortOrder === 'asc') {
        change['sortOrder'] = 'desc';
      } else {
        change['sortOrder'] = null;
        change['sortColumn'] = null;
      }
    } else {
      change['sortColumn'] = column;
      change['sortOrder'] = 'asc';
    }

    setRequestParams((c) => ({
      ...c,
      ...change
    }));
  };

  const defaultData = React.useMemo(() => [], []);

  const table = useReactTable({
    data: data ?? defaultData,
    columns: props.columns,
    getCoreRowModel: getCoreRowModel(),
    filterFns: {
      fuzzy: fuzzyFilter
    }
  });

  return (
    <div className="dataTables_wrapper">
      <div className="h-2" />
      {!!props.tableActions && props.tableActions.length > 0 && (
        <div className="inline-table px-8" style={{ padding: 8 }}>
          <TableActions table={table} actions={props.tableActions} />
        </div>
      )}

      <table
        className={
          'dataTable table-bordered table-striped ' +
          (displayResponsive ? 'table-res' : '')
        }
        style={{ width: '100%' }}
      >
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    style={{
                      backgroundColor: theme.palette.secondary.light
                    }}
                    key={header.id}
                    colSpan={header.colSpan}
                    className={
                      (header.column.getCanSort()
                        ? 'sorting'
                        : 'sorting_disabled') +
                        '  sorting_' +
                        requestParams.sortColumn ===
                      header.column.id
                        ? requestParams.sortOrder
                        : ''
                    }
                  >
                    {!header.isPlaceholder && (
                      <>
                        <div
                          className={
                            'label ' +
                            (header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : '')
                          }
                          onClick={() =>
                            header.column.getCanSort() &&
                            handleSort(header.column.id)
                          }
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}

                          {requestParams.sortColumn === header.column.id
                            ? requestParams.sortOrder === 'asc'
                              ? ' ▲'
                              : ' ▼'
                            : ''}
                        </div>
                      </>
                    )}
                    {!header.isPlaceholder && (
                      <Filter
                        column={header.column}
                        requestParams={requestParams}
                        setRequestParams={setRequestParams}
                      />
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody style={{ minHeight: 200, position: 'relative' }}>
          {isBusy && (
            <Box
              alignItems={'center'}
              alignContent={'center'}
              textAlign={'center'}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
                flexDirection: 'column',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: data?.length > 0 ? '#efefef77' : '$fff',
                zIndex: 10
              }}
            >
              <CircularProgress size={'50px'} />
            </Box>
          )}
          {(!data || data.length === 0) && !isBusy && loadedOnce && (
            <div
              style={{
                // background: '#939fde',
                position: 'absolute',
                width: '100%',
                height: 'calc(100% - 50px)',
                opacity: 0.9,
                textAlign: 'center',
                paddingTop: 50
              }}
            >
              No records
            </div>
          )}
          {table.getRowModel().rows.map((row, index) => {
            return (
              <tr
                key={row.id}
                className={
                  (index % 2 == 0 ? 'odd' : '') +
                  ' ' +
                  (row.getIsSelected() ? 'selected' : '')
                }
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id}>
                      <span className={'label'}>
                        {cell.column.columnDef.header}
                      </span>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
          {table.getRowModel().rows.length < 5 && (
            <div style={{ minHeight: 200 }}></div>
          )}
        </tbody>
      </table>
      <div className="h-2" />

      {data && data.length > 0 && (
        <Grid container sx={{ borderTop: '1px solid #e3e3e3', p: 1 }}>
          <Grid item flex={1}>
            {recordsTotal} {t('total')}
          </Grid>
          <Grid item>
            <Pagination
              onChange={(c, page) => {
                setPage(page);
              }}
              defaultPage={requestParams.page}
              page={requestParams.page}
              count={totalPages}
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
}
