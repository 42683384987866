import { Box, Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type ICharConfig = {
  label: string;
  component: ({ title }: { title: any }) => JSX.Element;
};

export const Charts = ({ charts }: { charts: ICharConfig[] }) => {
  const { t } = useTranslation('translation');
  const [selectedChart, setSelectedChart] = useState(charts[0].label);

  const charTitle = (
    <Grid container>
      {charts.map((chart) => {
        return (
          <Button
            sx={{ marginRight: 1 }}
            variant={selectedChart === chart.label ? 'outlined' : 'text'}
            onClick={() => {
              setSelectedChart(chart.label);
            }}
          >
            {t(chart.label)}
          </Button>
        );
      })}
    </Grid>
  );

  return (
    <>
      {charts.map((chart) => {
        return (
          <Box
            sx={{
              display: selectedChart === chart.label ? 'inherit' : 'none',
              width: '100%'
            }}
          >
            <chart.component title={charTitle} />
          </Box>
        );
      })}
    </>
  );
};
