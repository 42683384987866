import jwt_decode from 'jwt-decode';
import moment from 'moment';
import { ReadableShoppingCartItem } from '../../apis/shopizer';
import { ApiOrderHistoryResponse } from '../../types/order';
import {
  ApiOrderProductType,
  ApiProductType,
  ProductType,
  ReservationProductType
} from '../../types/product';

type DecodedExternalIdType = {
  o: {
    a: number; //447
    e: number; //1670111999999;
    i: string; //'1ddb2782-7d76-4ea6-8310-f1f7b72273f3';
    f: number; //11087;
    n: number; //1;
    p: number; //0;
    s: number; //1670025600000;
    u: number; //1669464053269;
  };
};

export type PropsFromExternalId = {
  startDate: Date;
  endDate: Date;
  expireDate: Date;
};

function extractExternalIdInfo(externalId: string): PropsFromExternalId {
  const decoded: DecodedExternalIdType = jwt_decode(externalId);

  if (!decoded?.o) {
    throw new Error('Wrong format for external id JWT: ' + externalId);
  }

  return {
    startDate: new Date(decoded.o.s),
    endDate: new Date(decoded.o.e),
    expireDate: new Date(decoded.o.u)
  };
}

function shouldMerge(a: ProductType, b: ProductType) {
  return (
    a.sku === b.sku &&
    moment(a.startDate).format('L LT') == moment(b.startDate).format('L LT') &&
    moment(a.endDate).format('L LT') == moment(b.endDate).format('L LT')
  );
}

export function combineProducts(products: ProductType[]): ProductType[] {
  let newProducts: ProductType[] = [];

  products.forEach((p: ProductType) => {
    let founded = false;
    newProducts.forEach((addedProduct) => {
      if (shouldMerge(addedProduct, p)) {
        addedProduct.count = addedProduct.count + 1;
        founded = true;
      }
    });

    if (!founded) {
      newProducts.push(p);
    }
  });

  return newProducts;
}

function combineReservationProducts(
  products: ReservationProductType[]
): ReservationProductType[] {
  let newProducts: ReservationProductType[] = [];

  products.forEach((p: ReservationProductType) => {
    let founded = false;
    newProducts.forEach((addedProduct) => {
      if (
        shouldMerge(addedProduct, p) &&
        addedProduct.paymentStatus === p.paymentStatus &&
        addedProduct.depot.id === p.depot.id
      ) {
        addedProduct.count = addedProduct.count + 1;
        addedProduct.reservationIds = [
          ...addedProduct.reservationIds,
          ...p.reservationIds
        ];
        founded = true;
      }
    });

    if (!founded) {
      newProducts.push(p);
    }
  });

  return newProducts;
}

export const parseProductsForFrontend = (
  products: ApiProductType[],
  startDate: Date,
  endDate: Date
): ProductType[] => {
  return products.map((p) => {
    const flat: ProductType = {
      startDate,
      endDate,
      externalId: p.externalId,
      id: p.readableProduct.id,
      assetType: p.readableProduct.type?.code,
      name: p.readableProduct.type?.description?.name,
      descriptionName: p.readableProduct.description?.name,
      description: p.readableProduct.description?.description,
      price: p.readableProduct.price,
      subTotal: p.readableProduct.subTotal,
      imageUrl: p.readableProduct.image.imageUrl,
      manufacturer: p.readableProduct.manufacturer?.description?.name,
      sku: p.readableProduct.sku,
      selectedEmail: p.selectedEmail,
      count: 1
    };

    return flat;
  });
};

export const parseOrderProductsForFrontend = (
  products: ApiOrderProductType[]
): ProductType[] => {
  return combineProducts(
    products.map((p) => {
      const flat: ProductType = {
        startDate: new Date(p.startDate),
        endDate: new Date(p.endDate),
        externalId: p.externalId,
        id: p.product.id,
        assetType: p.product.type?.code,
        name: p.product.type?.description?.name,
        descriptionName: p.product.description?.name,
        description: p.product.description?.description,
        price: p.product.price,
        subTotal: p.product.subTotal,
        imageUrl: p.product.image.imageUrl,
        manufacturer: p.product.manufacturer?.description?.name,
        sku: p.product.sku,
        quantity: p.orderedQuantity,
        selectedEmail: p.selectedEmail,
        count: 1
      };

      return flat;
    })
  );
};

export const parseReservationsProductsForFrontend = (
  response: ApiOrderHistoryResponse
): ReservationProductType[] => {
  let newProducts: ReservationProductType[] = [];

  response.orderProductsListByMerchantStore.map((r) => {
    r.orderProducts.map((p) => {
      const flat: ReservationProductType = {
        startDate: new Date(p.startDate),
        endDate: new Date(p.endDate),
        externalId: p.externalId,
        selectedEmail: p.selectedEmail,
        id: p.id,
        assetType: p.product.type?.code,
        name: p.product.type?.description?.name,
        descriptionName: p.product.description?.name,
        description: p.product.description?.description,
        price: p.product.price,
        subTotal: p.product.subTotal,
        imageUrl: p.product.image.imageUrl,
        manufacturer: p.product.manufacturer?.description?.name,
        sku: p.product.sku,
        paymentStatus: p?.paymentStatus,
        reservationIds: [p.id],
        count: 1,
        depot: {
          id: r.readableMerchantStore.id,
          name: r.readableMerchantStore.name,
          latitude: r.readableMerchantStore.latitude,
          longitude: r.readableMerchantStore.longitude,
          code: r.readableMerchantStore.code
        },
        allowCancel: p.allowCancel,
        allowEnd: p.allowEnd
      };

      newProducts.push(flat);
    });
  });

  return newProducts;
};

export const parseCartProductsForFrontend = (
  products: ReadableShoppingCartItem[]
): ProductType[] => {
  return combineProducts(
    (products ?? []).map((p) => {
      const flat: ProductType = {
        startDate: new Date(p.startDate),
        endDate: new Date(p.endDate),
        externalId: p.externalId,
        id: p.id,
        name: p.description?.name,
        descriptionName: p.description?.name,
        description: p.description?.description,
        price: p.price,
        subTotal: p.subTotal,
        imageUrl: p.image.imageUrl,
        sku: p.sku,
        manufacturer: null,
        assetType: null,
        count: 1,
        selectedEmail: p.selectedEmail,
        quantity: p.quantity
      };

      return flat;
    })
  );
};
