import {
  DataUsage,
  LockClock,
  LockOpen,
  NotAccessible,
  Payments,
  Person
} from '@mui/icons-material';
import { Divider } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useShopizerApiClient } from '../../apis';
import { Charts } from '../../components/chart/Charts';
import Wrapper from '../../components/wrapper/Wrapper';
import { PATH_DASHBOARD } from '../../core/routes/paths';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useLanguage } from '../../store/LanguageProvider';
import { selectActiveCustomer } from '../account/customerSlice';
import { KpiFilters } from './kpiFilters/KpiFilters';
import {
  fetchKpis,
  selectIsLoadingKpis,
  selectOverdueCount,
  selectOverdueFinishedCount,
  selectRevenueAmount,
  selectUnusedCount,
  selectUsageRatio
} from './kpiSlice';
import { RevenueChar } from './RevenueChar';
import { StatCard } from './StatCard';
import { UsageChar } from './UsageChar';
import { selectOrganization } from '../organization/organizationSlice';

export const HomeDepotManager = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('translation');
  const theme = useTheme();
  const navigate = useNavigate();
  const shopizerApiClient = useShopizerApiClient();

  const [labelOverdue, setLabelOverdue] = useState<string | null>(null);
  const [labelOFinished, setLabelOFinished] = useState<string | null>(null);
  const [labelUnused, setLabelUnused] = useState<string | null>(null);

  const { language } = useLanguage();
  const customer = useAppSelector(selectActiveCustomer);
  const organization = useAppSelector(selectOrganization);
  const isLoading = useAppSelector(selectIsLoadingKpis);
  const overdueCount = useAppSelector(selectOverdueCount);
  const overdueFinishedCount = useAppSelector(selectOverdueFinishedCount);
  const unusedCount = useAppSelector(selectUnusedCount);
  const revenueAmount = useAppSelector(selectRevenueAmount);
  const usageRatio = useAppSelector(selectUsageRatio);

  useEffect(() => {
    dispatch(fetchKpis());
    fetchTileLabels();
  }, []);

  useEffect(() => {
    fetchTileLabels();
  }, [language]);

  const fetchTileLabels = () => {
    if (language === 'en') {
      setLabelOverdue('Overdue');
      setLabelOFinished('Overdue Finished');
      setLabelUnused('Unused');

      return;
    }

    getLabelFromBackend('Overdue').then(setLabelOverdue);
    getLabelFromBackend('Overdue Finished').then(setLabelOFinished);
    getLabelFromBackend('Unused').then(setLabelUnused);
  };

  const getLabelFromBackend = async (key: string) => {
    return shopizerApiClient.orderApi.statusTranslationUsingPOST({
      inputLanguage: 'en',
      targetLanguage: language,
      translationType: 'reservationDeducedStatus',
      text: key
    });
  };
  if (organization.supportReservations || organization.supportAdminReservations) {
    return (
      <Wrapper>
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <StatCard
                type="fill"
                title={t('welcome')}
                value={customer ? customer.emailAddress : ''}
                icon={<Person />}
                color={theme.palette.secondary.light}
              />
            </Grid>
            <Grid item xs={12}>
              <KpiFilters />
              <Divider />
            </Grid>

            <Grid item xs={12} sm={4}>
              <StatCard
                type="fill"
                title={labelOverdue}
                value={overdueCount}
                onClick={() => {
                  navigate(PATH_DASHBOARD.reservations + '?kpi=Overdue');
                }}
                icon={<LockOpen />}
                isLoading={isLoading}
                color="#9c27b0"
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <StatCard
                type="fill"
                title={labelOFinished}
                value={overdueFinishedCount}
                onClick={() => {
                  navigate(PATH_DASHBOARD.reservations + '?kpi=Overdue Finished');
                }}
                icon={<LockClock />}
                isLoading={isLoading}
                color="#9c27b0"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <StatCard
                type="fill"
                title={labelUnused}
                onClick={() => {
                  navigate(PATH_DASHBOARD.reservations + '?kpi=Unused');
                }}
                value={unusedCount}
                icon={<NotAccessible />}
                isLoading={isLoading}
                color="#f44336"
              />
            </Grid>
            {!!customer.readableMerchantStore.nonPaying === false && (
            <Grid item xs={12} sm={6}>
              <StatCard
                type={'fill'}
                title={t('kpi.revenue')}
                value={revenueAmount}
                icon={<Payments />}
                isLoading={isLoading}
                color="#009688"
              />
            </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <StatCard
                type="fill"
                title={t('kpi.usage')}
                onClick={() => {}}
                value={usageRatio + '%'}
                icon={<DataUsage />}
                isLoading={isLoading}
                color="#f436b9"
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12} sx={{ minHeight: 600 }}>
              <Charts
                charts={!!customer.readableMerchantStore.nonPaying === false ? 
                [
                  {
                    label: 'kpi.usage',
                    component: UsageChar
                  },
                  {
                    label: 'kpi.revenue',
                    component: RevenueChar
                  }
                ] : 
                [
                  {
                  label: 'kpi.usage',
                  component: UsageChar
                  }
                ]}
              />
            </Grid>
          </Grid>
        </>
      </Wrapper>
    );
  }
  else {
    return (
      <Wrapper>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <StatCard
                type="fill"
                title={t('welcome')}
                value={customer ? customer.emailAddress : ''}
                icon={<Person />}
                color={theme.palette.secondary.light}
              />
            </Grid>
          </Grid>
      </Wrapper>
    );
  }
};
