import { AddCircle, Explore } from '@mui/icons-material';
import {
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { RootState } from '../../redux';
import { Depot } from '../../types/product';
import { resetCart } from '../cart/cartSlice';
import { selectActiveDepot, setActiveDepot } from '../depot/depotSlice';
import { DisplayDates, useDisplayTime } from '../products/DisplayDates';
import { ChangeReservation } from '../reservations/ChangeReservation';
import { PendingBadge } from '../reservations/PendingBadge';
import { PeriodProgress } from '../reservations/PeriodProgress';
import { selectActiveReservationsForDepot } from '../reservations/reservationSlice';

export function openGoogleMaps(location: {
  latitude: string;
  longitude: string;
}) {
  const url = `https://maps.google.com/?q=${location.latitude},${location.longitude}`;
  window.open(url, '_blank', 'noopener,noreferrer');
}

function ShopReservationsListHeader({ depot }: { depot: Depot }) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const activeDepot = useAppSelector(selectActiveDepot);

  return (
    <div>
      <Card
        sx={{
          backgroundColor: 'primary.main',
          color: 'primary.contrastText',
          width: '100%',
          fontWeight: 700
        }}
      >
        <CardContent
          sx={{ padding: (theme) => theme.spacing(1) + ' !important' }}
        >
          <Grid
            container
            minHeight="80%"
            alignItems="center"
            direction={'row'}
            justifyContent="space-between"
            spacing={0}
          >
            <Grid item>
              <Typography variant="body1">{depot.name}</Typography>
            </Grid>
            <Grid item>
              {depot.latitude && depot.longitude && (
                <IconButton
                  onClick={() => {
                    openGoogleMaps(depot);
                  }}
                >
                  <Explore fontSize={'large'} />
                </IconButton>
              )}

              <IconButton
                onClick={async () => {
                  if (activeDepot.code !== depot.code) {
                    //TODO: add notification change depot reset cart.
                    // dispatch(setActiveDepot(99));
                    await dispatch(setActiveDepot(depot.id));
                    await dispatch(resetCart());
                  }

                  navigate('/products/travel');
                }}
              >
                <AddCircle fontSize={'large'} />
              </IconButton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

export const ShopReservationsList = ({ depot }: { depot: Depot }) => {
  const { t } = useTranslation('translation');

  const products = useAppSelector((state: RootState) =>
    selectActiveReservationsForDepot(state, depot)
  );

  const { allowToggle } = useDisplayTime();
  return (
    <Grid item xs={12} sm={12} md={6}>
      <Paper sx={{ height: '100%' }}>
        <ShopReservationsListHeader depot={depot} />
        <div className="table-responsive">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('asset')}</TableCell>
                <TableCell align={'center'}>{t('period')}</TableCell>
                <TableCell align={'center'}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products
                .sort((a, b) => (a.startDate < b.startDate ? -1 : 0))
                .map((p) => {
                  return (
                    <TableRow key={p.id}>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ whiteSpace: 'nowrap' }}
                      >
                        {p.name} <br />
                        <PendingBadge product={p} />
                      </TableCell>
                      <TableCell align={'center'}>
                        <DisplayDates product={p} displayTime={allowToggle} />
                        <PeriodProgress
                          startDate={p.startDate}
                          endDate={p.endDate}
                        />
                      </TableCell>
                      <TableCell align={'center'}>
                        <ChangeReservation
                          reservation={{
                            allowCancel: p.allowCancel,
                            allowEnd: p.allowEnd,
                            reservationId: p.id
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
      </Paper>
    </Grid>
  );
};
