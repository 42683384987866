import Paper from '@mui/material/Paper';
import { createColumnHelper } from '@tanstack/react-table';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShopizerApiClient } from '../../apis';
import { ReadableFeedback } from '../../apis/shopizer';
import {
  CustomColumnDef,
  DataTable,
  RequestParams,
  ReturnData
} from '../../components/tanStackTable/DataTable';
import Wrapper from '../../components/wrapper/Wrapper';
import { useLanguage } from '../../store/LanguageProvider';

export const Feedback = () => {
  const { t } = useTranslation('translation');
  const { language } = useLanguage();
  const shopizerApiClient = useShopizerApiClient();

  const columnHelper = createColumnHelper<ReadableFeedback>();
  const [refreshListCount, setRefreshListCount] = useState(0);

  const refreshList = () => {
    setRefreshListCount((current) => current + 1);
  };

  const columns: CustomColumnDef[] = [
    {
      ...columnHelper.accessor('depot', { header: t('location') }),
      valueType: 'string'
    },
    {
      ...columnHelper.accessor('account', { header: t('emailAddress') }),
      valueType: 'string'
    },
    {
      ...columnHelper.accessor('feedbackDate', {
        header: t('field.date'),
        enableColumnFilter: false,
        enableSorting: true,
        cell: (accessor) => {
          const checkValue = moment(accessor.getValue()).format('L LT');
          return <div>{checkValue}</div>;
        }
      }),
      valueType: 'date'
    },
    {
      ...columnHelper.accessor('description', {
        header: t('field.description')
      }),
      valueType: 'string'
    },
    {
      ...columnHelper.accessor('identification', {
        header: t('field.identification')
      }),
      valueType: 'string'
    },
    {
      ...columnHelper.accessor('notes', { header: t('field.notes') }),
      valueType: 'string'
    }
  ];

  const fetchData = async (params: RequestParams): Promise<ReturnData> => {
    const response = await shopizerApiClient.storeApi.getFeedbackUsingGET({
      ...params,
      lang: language,
      page: params.page - 1,
      filter: JSON.stringify(params.filter)
    });

    return {
      recordsTotal: response.recordsTotal,
      items: response.data
    };
  };

  return (
    <Wrapper>
      <Paper sx={{ height: '100%', marginBottom: 2, padding: 0 }}>
        <DataTable
          columns={columns}
          requestType={'fetch'}
          refreshListCount={refreshListCount}
          fetch={fetchData}
        />
      </Paper>
    </Wrapper>
  );
};
