import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice
} from '@reduxjs/toolkit';
import { RootState } from '../../redux';

import moment from 'moment';
import { Language } from '../../store/LanguageProvider';
import { ApiOrderHistoryResponse } from '../../types/order';
import { Depot, ReservationProductType } from '../../types/product';
import WebService from '../../webServices/webService';
import { parseReservationsProductsForFrontend } from '../products/productService';

export const reservationAdapter = createEntityAdapter<ReservationProductType>();

const initialState = reservationAdapter.getInitialState();

export type ReservationState = typeof initialState;
export const reservationSlice = createSlice({
  name: 'reservation',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchReservations.fulfilled, (state, action) => {
      reservationAdapter.setAll(state, action.payload);
    });
  }
});

const reservationSelectors = reservationAdapter.getSelectors<RootState>(
  (state) => state.reservation
);
export const {
  selectAll: selectAllReservations,
  selectById: selectReservationById
} = reservationSelectors;

export const selectAllReservationsWithDates = (state: RootState) => {
  return selectAllReservations(state).map((r) => {
    return {
      ...r,
      startDate: new Date(r.startDate),
      endDate: new Date(r.endDate)
    };
  });
};

export const selectActiveReservations = (state: RootState) => {
  return selectAllReservationsWithDates(state).filter(
    (r) => r.endDate >= moment().set({ h: 0, m: 0 }).toDate()
  );
};

export const selectActiveDepots = (state: RootState) => {
  return selectActiveReservations(state)
    .map((r) => r.depot)
    .filter(
      (value, index, self) => self.findIndex((s) => s.id === value.id) === index
    );
};

export const selectNextReservation = (state: RootState) => {
  let found: ReservationProductType | null = null;

  selectAllReservationsWithDates(state).forEach((r) => {
    if (r.startDate < new Date() || (found && found.startDate < r.startDate)) {
      return;
    }

    found = r;
  });

  return found;
};

export const selectActiveReservationsForDepot = (
  state: RootState,
  depot: Depot
) => {
  return selectActiveReservations(state).filter((r) => r.depot.id === depot.id);
};

export const selectReservationsCount = (state: RootState) => {
  return selectAllReservations(state).reduce((total, r) => total + r.count, 0);
};

export const selectActiveReservationsCount = (state: RootState) => {
  return selectActiveReservations(state)
    .filter((r) => r.endDate > new Date())
    .reduce((total, r) => total + r.count, 0);
};

export const fetchReservations = createAsyncThunk(
  'reservation/fetch',
  async ({ language }: { language: Language }) => {
    const path = `/order/history?lang=${language}`;
    const response: ApiOrderHistoryResponse = await WebService.get(path);

    return parseReservationsProductsForFrontend(response);
  }
);

export const reservationReducer = reservationSlice.reducer;
