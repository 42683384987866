import { Delete, Edit } from '@mui/icons-material';
import { Button, ButtonGroup, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications';
import { useShopizerApiClient } from '../../apis';
import { ReadableWhiteList } from '../../apis/shopizer';
import { useDialog } from '../../components/dialog/useDialog';
import { useLanguage } from '../../store/LanguageProvider';
import { WhiteListForm } from './WhiteListForm';
export function WhiteListActions({
  whiteList,
  refreshList
}: {
  whiteList: ReadableWhiteList;
  refreshList?: () => void;
}) {
  const { t } = useTranslation('translation');
  const { language } = useLanguage();
  const api = useShopizerApiClient();
  const { open, handleOpen, handleClose } = useDialog();

  const deleteWhiteList = async () => {
    try {
      await api.storeApi.deleteWhiteListUsingDELETE({
        id: whiteList.id
      });

      if (refreshList) refreshList();
      NotificationManager.success('Deleted');
    } catch (e) {
      NotificationManager.error('Error');
    }
  };

  const redButtonSx = {
    bgcolor: '#F97068',
    '&:hover': {
      bgcolor: '#e85d5a'
    },
    '&.Mui-disabled': {
      bgcolor: 'rgba(0, 0, 0, 0.12)'
    }
  };

  return (
    <>
      <ButtonGroup
        variant="contained"
        aria-label="outlined primary button group"
      >
        <>
          {open && (
            <WhiteListForm
              open={open}
              onClose={handleClose}
              onSave={refreshList}
              type={'update'}
              originalValues={whiteList}
            />
          )}

          <Tooltip placement="left" title={t('button.edit')}>
            <Button
              sx={{ minWidth: 1, px: 0 }}
              onClick={handleOpen}
              color={'info'}
            >
              <Edit />
            </Button>
          </Tooltip>
          <Tooltip placement="left" title={t('button.delete')}>
            <Button
              onClick={deleteWhiteList}
              sx={{ minWidth: 1, px: 0, ...redButtonSx }}
            >
              <Delete />
            </Button>
          </Tooltip>
        </>
      </ButtonGroup>
    </>
  );
}
