import { LocationOn } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useAppSelector } from '../../hooks';
import { openGoogleMaps } from '../home/ShopReservationsList';
import { StatCard } from '../home/StatCard';
import { selectActiveDepot } from './depotSlice';

export const DepotInfo = () => {
  const activeDepot = useAppSelector(selectActiveDepot);

  if (!activeDepot) return <></>;

  return (
    <Box mt={1}>
      <StatCard
        type="fill"
        title={[
          activeDepot.address.address,
          activeDepot.address.postalCode,
          activeDepot.address.city
        ]
          .filter((v) => !!v)
          .join(', ')}
        onClick={() => {
          openGoogleMaps({
            latitude: activeDepot.latitude,
            longitude: activeDepot.longitude
          });
        }}
        value={activeDepot.name}
        icon={<LocationOn />}
        color="#1976d2"
      />
    </Box>
  );
};
