import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Collapse from '@mui/material/Collapse';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { capitalize } from '../../../Helpers/helper';
import { SidebarItems } from './SidebarItems';

const useStyles = makeStyles((theme) => ({
  badge: {
    width: '20px',
    height: '20px',
    display: 'flex',
    zIndex: 1,
    flexWrap: 'wrap',
    fontSize: '0.75rem',
    alignItems: 'center',
    borderRadius: '50%',
    alignContent: 'center',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  menuLink: {
    position: 'relative',
    display: 'block'
  },
  menuItem: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5)
  },
  menuIcon: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  menuSubItem: {
    paddingLeft: '55px',
    paddingRight: '55px',
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5)
  },
  menuCollapsed: {
    backgroundColor: theme.palette.action.hover
  },
  menuActive: {
    backgroundColor: theme.palette.action.hover
  },
  menuClosed: {
    backgroundColor: 'transparent'
  },
  caret: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    minWidth: 0
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText
  }
}));

export type ISidebarItem = {
  path: string;
  name: string;
  icon?: any;
  badge?: number;
} & (
  | {
      type: 'link' | 'page' | 'external';
    }
  | {
      type: 'submenu';
      children: ISidebarItem[];
    }
);

interface SidebarItemProps {
  item: ISidebarItem;
  openedMenu: boolean;
  toggleMenu: () => void;
  closeDrawer: () => void;
}

export const SidebarItem = ({
  item,
  toggleMenu,
  openedMenu,
  closeDrawer
}: SidebarItemProps) => {
  const classes = useStyles();
  const location = useLocation();

  const isCurrentPath =
    location.pathname.indexOf(item.path) > -1 ? true : false;

  useEffect(() => {
    if (!isCurrentPath || openedMenu || item.path === '/') return;
    toggleMenu();
  }, []);

  const badge = (badge) => {
    if (!badge) return;
    const badgeClassName = classNames(classes.badge, {
      [classes[`${badge.type}`]]: badge.type !== 'default'
    });
    return (
      <Typography
        className={classNames(classes.badge, badgeClassName)}
        component="div"
      >
        {badge.value}
      </Typography>
    );
  };

  function isNavLinkActive(match, location) {
    // Your logic to determine if the NavLink is active goes here
    return match && match.isExact;
  }

  if (item.type === 'external') {
    return (
      <a
        href={item.path}
        target="_blank"
        rel="noopener noreferrer"
        key={'external'}
        className={classes.menuLink}
      >
        <ListItem className={classes.menuItem} button>
          <ListItemIcon>
            <item.icon className={classes.menuIcon} />
          </ListItemIcon>
          <Typography variant="body1" className="flexSpacer">
            {capitalize(item.name)}
          </Typography>
          {badge(item.badge)}
        </ListItem>
      </a>
    );
  }

  if (item.type === 'submenu') {
    return (
      <div className={openedMenu ? classes.menuCollapsed : classes.menuClosed}>
        <ListItem
          className={classes.menuItem}
          button
          key={'sub'}
          onClick={() => toggleMenu()}
        >
          <ListItemIcon>
            <item.icon className={classes.menuIcon} />
          </ListItemIcon>
          <Typography variant="body1" className="flexSpacer">
            {capitalize(item.name)}
          </Typography>
          {badge(item.badge)}
          <ListItemIcon className={classes.caret}>
            {openedMenu ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </ListItemIcon>
        </ListItem>
        <Collapse
          sx={{ pl: 2 }}
          in={openedMenu ? true : false}
          timeout="auto"
          unmountOnExit
        >
          <SidebarItems items={item.children} closeDrawer={closeDrawer} />
        </Collapse>
      </div>
    );
  }

  return (
    <NavLink
      to={item.path}
      className={classes.menuLink}
      // activeClassName={classes.menuActive}
      key={'bla'}
    >
      <ListItem
        className={classes.menuItem}
        button
        onClick={() => {
          toggleMenu();
          closeDrawer();
        }}
      >
        <ListItemIcon>
          <item.icon className={classes.menuIcon} />
        </ListItemIcon>
        <Typography variant="body1" className="flexSpacer">
          {capitalize(item.name)}
        </Typography>
        {badge(item.badge)}
      </ListItem>
    </NavLink>
  );
};
