import { Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { NotOrganisationFound } from '../../components/errors/NotOrganisationFound';
import {
  fetchOrganization,
  selectOrganization
} from '../../feature/organization/organizationSlice';
import { useAppDispatch, useAppSelector } from '../../hooks';

type GuestGuardProps = {
  children: React.ReactNode;
};

export default function OrganisationGuard({ children }: GuestGuardProps) {
  const dispatch = useAppDispatch();
  const { organizationCode } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const organisation = useAppSelector(selectOrganization);

  useEffect(() => {
    if (!organizationCode) return;

    (async () => {
      setIsLoading(true);
      await dispatch(fetchOrganization({ organizationCode }));
      setIsLoading(false);
    })();
  }, [dispatch, organizationCode]);

  if (isLoading)
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <CircularProgress />
      </Grid>
    );

  if (!organisation) {
    return <NotOrganisationFound />;
  }

  return <> {children} </>;
}
