import { Cancel, CheckCircle, HowToReg, PersonOff } from '@mui/icons-material';
import { Button, ButtonGroup, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications';
import { useShopizerApiClient } from '../../apis';
import { ReadableOnboarding } from '../../apis/shopizer';
import { useLanguage } from '../../store/LanguageProvider';

export function UserActions({
  user,
  refreshList
}: {
  user: ReadableOnboarding;
  refreshList?: () => void;
}) {
  const { t } = useTranslation('translation');
  const { language } = useLanguage();
  const api = useShopizerApiClient();

  const updateAccount = async (enabled: boolean) => {
    const response = await api.customerApi.onboardingStatusUpdateUsingPOST({
      recordId: Number(user.recordId),
      lang: language,
      enabled
    });

    if (response.success) {
      if (refreshList) refreshList();
      NotificationManager.success(response.message);
    } else {
      NotificationManager.error(response.message);
    }
  };

  const redButtonSx = {
    bgcolor: '#F97068',
    '&:hover': {
      bgcolor: '#e85d5a'
    },
    '&.Mui-disabled': {
      bgcolor: 'rgba(0, 0, 0, 0.12)'
    }
  };

  return (
    <>
      <ButtonGroup
        variant="contained"
        aria-label="outlined primary button group"
      >
        {(user.allowEnable || user.allowDisable) && (
          <>
            <Tooltip placement="left" title={t('button.enableAccount')}>
              <span>
                <Button
                  onClick={() => updateAccount(true)}
                  disabled={!user.allowEnable}
                  color={'success'}
                >
                  <CheckCircle />
                </Button>
              </span>
            </Tooltip>
            <Tooltip placement="left" title={t('button.disableAccount')}>
              <span>
                <Button
                  onClick={() => updateAccount(false)}
                  disabled={!user.allowDisable}
                  sx={redButtonSx}
                >
                  <Cancel />
                </Button>
              </span>
            </Tooltip>
          </>
        )}

        {(user.allowApprove || user.allowDeny) && (
          <>
            <Tooltip placement="left" title={t('button.approveAccount')}>
              <span>
                <Button
                  onClick={() => updateAccount(true)}
                  disabled={!user.allowApprove}
                  color={'success'}
                >
                  <HowToReg />
                </Button>
              </span>
            </Tooltip>
            <Tooltip placement="left" title={t('button.denyAccount')}>
              <span>
                <Button
                  onClick={() => updateAccount(false)}
                  disabled={!user.allowDeny}
                  sx={redButtonSx}
                >
                  <PersonOff />
                </Button>
              </span>
            </Tooltip>
          </>
        )}
      </ButtonGroup>
    </>
  );
}
