import { Add } from '@mui/icons-material';
import Paper from '@mui/material/Paper';
import { createColumnHelper } from '@tanstack/react-table';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useShopizerApiClient } from '../../apis';
import { ReadableWhiteList } from '../../apis/shopizer';
import { useDialog } from '../../components/dialog/useDialog';
import {
  CustomColumnDef,
  DataTable,
  RequestParams,
  ReturnData
} from '../../components/tanStackTable/DataTable';
import Wrapper from '../../components/wrapper/Wrapper';
import { useLanguage } from '../../store/LanguageProvider';
import { WhiteListActions } from './WhiteListActions';
import { WhiteListForm } from './WhiteListForm';

export const UserWhiteListsScreen = () => {
  const { t } = useTranslation('translation');
  const { language } = useLanguage();
  const shopizerApiClient = useShopizerApiClient();
  const navigate = useNavigate();
  const { open, handleOpen, handleClose } = useDialog();

  const columnHelper = createColumnHelper<ReadableWhiteList>();
  const [refreshListCount, setRefreshListCount] = useState(0);

  useEffect(() => {
    setRefreshListCount((c) => c + 1);
  }, [language]);

  const refreshList = () => {
    setRefreshListCount((current) => current + 1);
  };

  const columns: CustomColumnDef[] = [
    {
      ...columnHelper.accessor('location', { header: t('location') }),
      valueType: 'string'
    },
    {
      ...columnHelper.accessor('matchPattern', {
        header: t('field.matchPattern')
      }),
      valueType: 'string'
    },
    {
      ...columnHelper.accessor('needsApproval', {
        header: t('field.needsApproval')
      }),
      valueType: 'string'
    },
    {
      ...columnHelper.accessor('startDate', {
        header: t('fromDate'),
        enableColumnFilter: false,
        cell: (accessor) => {
          const checkValue = moment(accessor.getValue()).format('L');
          return <div>{checkValue}</div>;
        }
      }),
      valueType: 'date'
    },
    {
      ...columnHelper.accessor('endDate', {
        header: t('toDate'),
        enableColumnFilter: false,
        cell: (accessor) => {
          const checkValue = moment(accessor.getValue()).format('L');
          return <div>{checkValue}</div>;
        }
      }),
      valueType: 'date'
    },
    columnHelper.accessor('id', {
      header: '',
      enableColumnFilter: false,
      enableSorting: false,
      cell: (accessor) => {
        return (
          <div>
            <WhiteListActions
              whiteList={accessor.row.original}
              refreshList={refreshList}
            />
          </div>
        );
      }
    })
  ];

  const fetchData = async (params: RequestParams): Promise<ReturnData> => {
    const response =
      await shopizerApiClient.storeApi.getWhiteListRecordsUsingGET({
        ...params,
        lang: language,
        page: params.page - 1,
        filter: JSON.stringify(params.filter)
      });

    return {
      recordsTotal: response.totalCount,
      items: response.whiteLists
    };
  };

  return (
    <Wrapper>
      <h1>{t('menu.whitelist')}</h1>
      <Paper sx={{ height: '100%', marginBottom: 2, padding: 0 }}>
        <WhiteListForm
          type={'add'}
          open={open}
          onClose={handleClose}
          onSave={refreshList}
        />
        <DataTable
          columns={columns}
          requestType={'fetch'}
          refreshListCount={refreshListCount}
          fetch={fetchData}
          tableActions={[
            {
              type: 'action',
              variant: 'contained',
              action: handleOpen,
              icon: <Add />,
              label: t('button.add')
            }
          ]}
        />
      </Paper>
    </Wrapper>
  );
};
