import { TextField } from '@mui/material';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import { Column } from '@tanstack/react-table';
import { Moment } from 'moment';
import { DebouncedInput } from '../form/DebouncedInput';
import { CustomColumnDef, RequestParams } from './DataTable';

interface FilterPros {
  column: Column<any, unknown>;
  requestParams: RequestParams;
  setRequestParams: (params: RequestParams | any) => void;
}

export function Filter({
  column,
  requestParams,
  setRequestParams
}: FilterPros) {
  const columnDef: CustomColumnDef = column.columnDef;

  const handleFilter = (column: string, value) => {
    if (!value && (!requestParams?.filter || !requestParams.filter[column])) {
      return;
    }

    setRequestParams((c) => {
      const filter = { ...c.filter };

      if (value) {
        filter[column] = value;
      } else {
        delete filter[column];
      }

      return { ...c, page: 1, filter };
    });
  };

  const fieldGte = column.id + '_gte';
  const fieldLte = column.id + '_lte';

  if (columnDef.valueType === 'number') {
    return (
      <div>
        <div className="flex space-x-2">
          <DebouncedInput
            type="number"
            // min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
            // max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
            value={''}
            onChange={(value) =>
              handleFilter(
                column.id + '_gte',
                value && value !== '' ? value : null
              )
            }
            placeholder={`Min`}
            className="w-24 border shadow rounded"
          />
          <DebouncedInput
            type="number"
            // min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
            // max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
            value={''}
            onChange={(value) =>
              handleFilter(
                column.id + '_lte',
                value && value !== '' ? value : null
              )
            }
            placeholder={`Max `}
            className="w-24 border shadow rounded"
          />
        </div>
        <div className="h-1" />
      </div>
    );
  }

  if (columnDef.valueType === 'date') {
    return (
      <div className={'mr-0'}>
        <DatePicker
          value={
            requestParams &&
            requestParams.filter &&
            requestParams.filter[fieldGte]
              ? requestParams.filter[fieldGte]
              : null
          }
          onChange={(value: Moment) => {
            if (!value) {
              handleFilter(fieldGte, null);
              return;
            }

            if (!value.isValid()) {
              return;
            }

            value.startOf('day');
            handleFilter(fieldGte, value.toISOString());
          }}
          renderInput={(params) => {
            return (
              <TextField
                variant="standard"
                {...params}
                sx={{
                  backgroundColor: 'white',
                  width: '100%',
                  px: 1,
                  mb: 1,
                  mr: 1
                }}
              />
            );
          }}
        />

        <DatePicker
          value={
            requestParams &&
            requestParams.filter &&
            requestParams.filter[fieldLte]
              ? requestParams.filter[fieldLte]
              : null
          }
          onChange={(value: Moment) => {
            if (!value) {
              handleFilter(fieldLte, null);
              return;
            }

            if (!value.isValid()) {
              return;
            }

            value.endOf('day');
            handleFilter(fieldLte, value.toISOString());
          }}
          renderInput={(params) => {
            return (
              <TextField
                variant="standard"
                {...params}
                sx={{
                  backgroundColor: 'white',
                  width: '100%',
                  px: 1
                }}
              />
            );
          }}
        />
        <div className="h-1" />
      </div>
    );
  }

  if (columnDef.valueType === 'datetime') {
    return (
      <div className={'mr-0'}>
        <DateTimePicker
          value={
            requestParams &&
            requestParams.filter &&
            requestParams.filter[fieldGte]
              ? requestParams.filter[fieldGte]
              : null
          }
          onChange={(value: Moment) => {
            if (!value) {
              handleFilter(fieldGte, null);
              return;
            }

            if (!value.isValid()) {
              return;
            }

            handleFilter(fieldGte, value.toISOString());
          }}
          renderInput={(params) => {
            return (
              <TextField
                variant="standard"
                {...params}
                sx={{
                  backgroundColor: 'white',
                  width: '100%',
                  px: 1,
                  mb: 1,
                  mr: 1
                }}
              />
            );
          }}
        />

        <DateTimePicker
          value={
            requestParams &&
            requestParams.filter &&
            requestParams.filter[fieldLte]
              ? requestParams.filter[fieldLte]
              : null
          }
          onChange={(value: Moment) => {
            if (!value) {
              handleFilter(fieldLte, null);
              return;
            }

            if (!value.isValid()) {
              return;
            }

            handleFilter(fieldLte, value.toISOString());
          }}
          renderInput={(params) => {
            return (
              <TextField
                variant="standard"
                {...params}
                sx={{
                  backgroundColor: 'white',
                  width: '100%',
                  px: 1
                }}
              />
            );
          }}
        />

        <div className="h-1" />
      </div>
    );
  }

  if (columnDef.valueType === 'boolean') {
    return (
      <div>
        <select
          className=" border px-1"
          onChange={(event) =>
            handleFilter(
              column.id,
              event.target.value === 'true'
                ? true
                : event.target.value === 'false'
                ? false
                : null
            )
          }
        >
          <option></option>
          <option value={'true'}>Yes</option>
          <option value={'false'}>No</option>
        </select>
        <div className="h-1" />
      </div>
    );
  }

  if (columnDef.valueType === 'string') {
    return (
      <div>
        {/*<datalist id={column.id + 'list'}>*/}
        {/*{sortedUniqueValues.slice(0, 5000).map((value: any) => (*/}
        {/*<option value={1} key={1} />*/}
        {/*<option value={2} key={2} />*/}
        {/*))}*/}
        {/*</datalist>*/}
        <DebouncedInput
          style={{
            width: '100%',
            margin: 'auto',
            fontSize: 16,
            paddingTop: 4,
            paddingBottom: 5
          }}
          type="text"
          value={
            requestParams &&
            requestParams.filter &&
            requestParams.filter[column.id]
              ? requestParams.filter[column.id]
              : null
          }
          onChange={(value) =>
            handleFilter(column.id, value && value !== '' ? value : null)
          }
          placeholder={`Search...`}
          className=" border px-1"
          list={column.id + 'list'}
        />
        <div className="h-1" />
      </div>
    );
  }

  return <></>;
}
