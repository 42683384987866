import { GpsFixed } from '@mui/icons-material';
import { Box, ListItemText, Menu, MenuItem } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useState } from 'react';
import { ReadableStore } from '../../../apis/shopizer';
import { useAppSelector } from '../../../hooks';
import { selectActiveCustomer } from '../../account/customerSlice';

export const Depot = ({
  selectedDepot,
  setDepot
}: {
  selectedDepot: ReadableStore;
  setDepot: (depotCode: string | null) => void;
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleCloseMenu = () => setAnchorEl(null);

  const customer = useAppSelector(selectActiveCustomer);
  const managerDepots = customer.managerDepots;

  const setSelectedDepot = (depotCode: string | null) => {
    setDepot(depotCode);
    handleCloseMenu();
  };

  if (managerDepots.length === 0) return <></>;

  return (
    <Grid item>
      <Button
        sx={{ px: 1, minWidth: 1, textTransform: 'none', fontWeight: 300 }}
        onClick={(event) => setAnchorEl(event.currentTarget)}
        variant={selectedDepot ? 'contained' : 'text'}
      >
        <GpsFixed />
        {selectedDepot ? <Box ml={1}>{selectedDepot.storeName}</Box> : ''}
      </Button>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem
          onClick={() => {
            setSelectedDepot(null);
          }}
        >
          <ListItemText primary={'All'} />
        </MenuItem>
        {managerDepots.map((depot) => {
          return (
            <MenuItem
              key={depot.storeCode}
              onClick={() => {
                setSelectedDepot(depot.storeCode);
              }}
            >
              <ListItemText primary={depot.storeName} />
            </MenuItem>
          );
        })}
      </Menu>
    </Grid>
  );
};
