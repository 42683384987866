const Wrapper = ({
  children,
  maxWidth
}: {
  children: React.ReactNode;
  maxWidth?: number;
}) => {
  return (
    <div
      style={{
        minWidth: 300,
        maxWidth: maxWidth ?? 1200,
        padding: 20,
        marginBottom: 50,
        marginInline: 'auto'
      }}
    >
      {children}
    </div>
  );
};

export default Wrapper;
