import { Box } from '@mui/material';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { CartDrawer } from '../../../feature/cart/CartDrawer';
import { selectOrganization } from '../../../feature/organization/organizationSlice';
import { useAppSelector } from '../../../hooks';
import { HeaderGuest } from './header/HeaderGuest';
import Main from './Main';

export function DashboardLayoutGuest() {
  const [cartOpen, setCartOpen] = useState(false);
  const organization = useAppSelector(selectOrganization);
  const handleCartToggle = () => setCartOpen(!cartOpen);

  if (!organization) {
    return <></>;
  }

  return (
    <>
      <HeaderGuest toggleCart={handleCartToggle} />

      <Box sx={{ display: 'flex', height: '100%', pt: 8 }}>
        <Main opened={true}>
          <Outlet />
        </Main>
        <CartDrawer cartOpen={cartOpen} toggleCart={handleCartToggle} />
      </Box>
    </>
  );
}
